import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@mdi/react";
import { Link } from "react-router-dom";
import { Settings as SettingsIcon } from "@material-ui/icons";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import {
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Tooltip,
	Zoom,
} from "@material-ui/core";
import {
	mdiAlphaCBox,
	mdiAlphaMBox,
	mdiAlphaSBox,
	mdiAlphaGBox,
	mdiAlphaRBox,
	mdiAlphaEBox,
} from "@mdi/js";
import * as PERMISSIONS from "../../constants/permissions";
import { useSelector } from "react-redux";
import * as ROUTES from "../../constants/routes";
import * as URLS from "../../constants/urls";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DvrIcon from "@material-ui/icons/Dvr";
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: 26,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerMargin: {
		marginTop: "64px",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflow: "hidden",
		width: theme.spacing(0) + 1,
		// width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {},
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
}));

export default function MainDrawer(props) {
	const { open, handleClose } = props;
	const { usuarioSesion } = useSelector((state) => ({
		usuarioSesion: state.usuarioSesion,
	}));
	const classes = useStyles();

	useEffect(() => {
		// console.log(
		//   usuarioSesion.permisos.findIndex(
		//     (a) => a.nombre === PERMISSIONS.ENVIAR_ENCUESTA
		//   )
		// );
	}, []);

	return (
		<Fragment>
			<Drawer
				variant="permanent"
				anchor="left"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx(classes.drawerMargin, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
				onClose={handleClose}
				open={open}
			>
				<Divider />
				<List>
					{open && (
						//Texto que aparece cuando se activa el drawer.
						<ListItem>
							<Typography variant="subtitle2">Portafolio</Typography>
						</ListItem>
					)}

					{MenuItemInterno(
						"Contratos Vigentes",
						"/contratos",
						open,
						<Icon path={mdiAlphaCBox} size={1} />,
						handleClose,
					)}
					{/* {MenuItemInterno(
            "Contratos procesos de Cierre",
            ROUTES.CONTRATOS_PENDIENTE_FINALIZAR,
            open,
            <Icon path={mdiAlphaCBox} size={1} />
          )} */}
					{MenuItemInterno(
						"Contratos cerrados",
						"/contratos-finalizado",
						open,
						<Icon path={mdiAlphaCBox} size={1} />,
						handleClose,
					)}

					{MenuItemInterno(
						"Mandantes",
						"/mandantes",
						open,
						<Icon path={mdiAlphaMBox} size={1} />,
						handleClose,
					)}
					{MenuItemInterno(
						"Sociedades",
						"/sociedades",
						open,
						<Icon path={mdiAlphaSBox} size={1} />,
						handleClose,
					)}
					{MenuItemInterno(
						"Gerencias",
						"/gerencias",
						open,
						<Icon path={mdiAlphaGBox} size={1} />,
						handleClose,
					)}
					{MenuItemInterno(
						"Responsables",
						"/responsables",
						open,
						<Icon path={mdiAlphaRBox} size={1} />,
						handleClose,
					)}

					{usuarioSesion &&
						usuarioSesion.permisos &&
						usuarioSesion.permisos.findIndex(
							(a) => a.nombre === PERMISSIONS.ENVIAR_ENCUESTA
						) !== -1 &&
						MenuItemInterno(
							"Encuestas",
							`${ROUTES.REDIRECT}/?destiny=${URLS.ENCUESTAS}`,
							open,
							<Icon path={mdiAlphaEBox} size={1} />,
							handleClose,
						)}

					<Divider />

					{usuarioSesion &&
						usuarioSesion.permisos &&
						usuarioSesion.permisos.findIndex(
							(a) => a.nombre === PERMISSIONS.CONFIGURAR_PLATAFORMA
						) &&
						MenuItemInterno(
							"Configuración",
							"/configuracion",
							open,
							<SettingsIcon />,
							handleClose,
						)}

					{/* {usuarioSesion &&
            usuarioSesion.permisos &&
            usuarioSesion.permisos.findIndex(
              (a) => a.nombre === PERMISSIONS.VER_SOLICITUD_INGRESO
            ) &&
            MenuItemInterno(
              "Adm. Etapas de Cierre de Contrato",
              ROUTES.PROCESOS_ELIMINACION_STEP,
              open,
              <BlurLinearIcon />
            )} */}
					{usuarioSesion &&
						usuarioSesion.permisos &&
						usuarioSesion.permisos.findIndex(
							(a) => a.nombre === PERMISSIONS.VER_SOLICITUD_INGRESO
						) &&
						MenuItemInterno(
							"Aprobar Solicitud Nuevo Contrato",
							"/lista/solicitud_nuevo_contrato",
							open,
							<DoneAllIcon />,
							handleClose,
						)}
					{usuarioSesion &&
						usuarioSesion.permisos &&
						usuarioSesion.permisos.findIndex(
							(a) => a.nombre === PERMISSIONS.ENVIAR_SOLICITUD_INGRESO
						) &&
						MenuItemInterno(
							"Solicitud de Nuevo Contrato",
							"/solicitud_nuevo_contrato",
							open,
							<DvrIcon />,
							handleClose,
						)}
				</List>
			</Drawer>
		</Fragment>
	);
}

/**
 * Método encargado de generar un icono para LINKs internos.
 * @param {*} titulo Título del icono.
 * @param {*} ruta Ruta de referencia a página interna.
 * @param {*} openClose Estado del drawer (abierto o cerrado).
 * @param {*} icon Elemento icono para mostrar.
 * @param {*} handleClose Método para cerrar el Drawer.
 */
function MenuItemInterno(titulo, ruta, openClose, icon, handleClose) {
	return (
		<Tooltip
			title={titulo}
			disableHoverListener={openClose}
			TransitionComponent={Zoom}
			placement="right"
			onClick={handleClose}
		>
			<ListItem button component={Link} to={ruta}>
				<ListItemIcon>{icon}</ListItemIcon>
				{openClose && <ListItemText primary={titulo} />}
			</ListItem>
		</Tooltip>
	);
}
