import React, {  useEffect } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
const SelectorUsuarioForm = (props) => {
  const { data, loadingData, formik, label, nameField, valueInit ,onChange,index} = props;
  const [open, setOpen] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState(valueInit);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoadding] = React.useState(false);
  const [inputResponsableValue, setInputResponsableValue] = React.useState("");



  useEffect(() => {}, []);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
        setLoadding(true);
      }}
      onClose={() => {
        console.log("close");
        setOpen(false);
        setLoadding(false);
      }}
      value={selectValue}
      onChange={(event, value) => {
        setSelectValue(value)
        onChange(index,value)
      }}
      options={options}
      onInputChange={(event, newInputValue) => {
        setInputResponsableValue(newInputValue);
      }}
      getOptionSelected={(option, value) => {
         
        return value
          ? option.usuario_id === value.uid
          : null;
      }}
      //   value={
      //     !loadingData
      //       ? Array.from(options).find((r) => {
      //           //console.log(r?._id === value?.uid)
      //           //console.log(value?.uid)
      //           return r?._id === selectValue?.uid;
      //         })
      //       : null
      //   }
      getOptionLabel={(responsable) => {
        let email = responsable.contacto?.email
          ? responsable.contacto.email
          : responsable.email;
        return responsable.nombre_abreviado + " | " + email;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth={true}
          required
          
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingData ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectorUsuarioForm;
