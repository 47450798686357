import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";

const SelectorResponsable = (props) => {
  const { options } = props;

  const [optionsGerencias, setOptionsGerencias] = useState(options.datos);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(
    typeof props.value === "undefined" ? "" : props.value
  );

  const [valueSelected, setValueSelected] = useState(
    typeof props.value === "undefined" ? "" : props.value
  );

  useEffect(() => {
    // console.log(options.datos)
    props.onChange(value ?? "");
  }, []);


  useEffect(() => {
    // console.log(options.datos)
    setOptionsGerencias(options.datos)
    
    props.onChange(value ?? "");
    if(value?.gerencia){
      if (value.gerencia === options.gerencia.sigla) {
        console.log("misma gerencia ")
  
      } else {
        console.log("cambio gerencia misma gerencia ")
        props.onChange(value ?? "");
        setValueSelected(null)
      }
    }
   
  }, [options.datos]);


  return (
    <Autocomplete
      label={props.label}
      value={valueSelected}
      multiple={false}
      id={props.id}
      loading={loading}
      disabled={props.disabled}
      onChange={(_ev, newValue, reason) => {

        props.onChange(newValue ?? "");
        setValueSelected(newValue);
        setValue(newValue ?? "");
      }}
      getOptionSelected={(option, valueref) => {

        return option.persona.uid === valueref.persona.uid;
      }}
      options={optionsGerencias}
      getOptionLabel={(g) => {
        
        return g !== '' ? `${Object.byString(g, options.labelOption)} ( ${g.correlativo} )` : ""
      }}
      filterSelectedOptions={false}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Favorites"
            variant="outlined"
            label={props.label}
          />
        );
      }}
    />
  );
};

export default SelectorResponsable;