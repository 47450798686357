import React from "react";
import Page from "./page";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actionRefreshTablePermiso, actionOpenEditPermiso } from "../actionsPermisos";
import * as yup from 'yup'
import { updatePermiso } from "../requestPermisos";
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function EditarPermiso() {

  const dispatch = useDispatch();
  const notistack = useSnackbar();
  const { open, permiso } = useSelector((state) => ({
    open: state.reducerPermiso.openEditPermiso,
    permiso: state.reducerPermiso.permisoSeleccionado
  }));

  const validationSchema = yup.object({
    nombre: yup.string().required("Campo requerido"),
    descripcion: yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
      descripcion: ""
    },
    onSubmit: values => {
      const key = notistack.enqueueSnackbar("Guardando cambios...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      try {
        updatePermiso(permiso._id, values)
          .then((response) => {
            if (response.status === 200) {
              notistack.closeSnackbar(key);
              const successkey = notistack.enqueueSnackbar("Operanción exitosa: Cambios guardados", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
              });
              dispatch(actionOpenEditPermiso());
              dispatch(actionRefreshTablePermiso(true));
            } else {
              console.log(response);
              notistack.closeSnackbar(key);
              const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar el Permiso. Intente nuevamente o contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
              });
            }
          })
          .catch((e) => {
            console.log(e);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar el Permiso. Contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          });

      }
      catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible actualizar el Permiso Contacte con soporte el equipo de soporte", {
          variant: 'error',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
        });
      }
    },
    validationSchema: validationSchema,
  });

  const onClose = () => dispatch(actionOpenEditPermiso());

  const onEntered = () => {
    console.log(permiso);
    formik.resetForm({
      values: {
        nombre: permiso.nombre,
        descripcion: permiso.descripcion
      }
    });
  }

  return (
    <Page
      formik={formik}
      onClose={onClose}
      onEntered={onEntered}
      open={open}
    />
  );
}