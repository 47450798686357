import React, { useEffect } from "react";
import { utils } from "@rjsf/core";
import { WidgetProps } from "@rjsf/core";
import { DatePicker } from "@material-ui/pickers";
import Moment from "moment";

const { localToUTC, utcToLocal } = utils;

const DateTimeWidgetCyd = (props) => {
  const value = props.value ? utcToLocal(props.value) : null;
  const onChange = (value) => {
    props.onChange(localToUTC(value.toDate()));
  };

  useEffect(() => {}, []);

  return (
    <DatePicker
      clearable
      disabled={props.disabled}
      format="MM-DD-YYYY"
      inputVariant="outlined"
      fullWidth
      label={props.label}
      value={value}
      onChange={onChange}
      maxDate={Moment("31/12/2099", "DD/MM/YYYY")}
    />
  );
};

export default DateTimeWidgetCyd;
