import {
  Avatar,
  Container,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { obtenerItemsProcesos } from "../requestItem";
import { tienePermisosParaVerTodosContrato } from "../../../services/database/usuario";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ACTUALIZAR_CONTRATO,
  AGREGAR_CONTRATO,
  ELIMINAR_CONTRATO,
  ACTUALIZAR_CONTRATO_BASICO,
} from "../../../constants/permissions";
import HeaderBackTable from "../../../components/HeaderBackTable";
const localization = require("../../../data/location.json");

export default function ListadoProcesoEliminacion() {
  const [listado, setListado] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));
  const permisos = Array.from(usuarioSesion.permisos);
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO
  );
  const permisoAgregar = permisos.find((p) => p.nombre === AGREGAR_CONTRATO);

  const permisoActualizarBasico = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
  );
  let columns = [
    {
      title: "Grupo",
      field: "grupo",
      grouping: false,
      cellStyle: {
        fontSize: "0.9em",
        width: "15px",
      },
    },
    {
      title: "Àrea",
      field: "area",
      cellStyle: {
        width: "7px",
      },
    },
    {
      title: "Proceso",
      field: "proceso",
    },

    {
      title: "Detalle",
      field: "detalle",
      cellStyle: {
        fontSize: "0.9em",
        width: "15px",
      },
    },
    {
      title: "¿Es Obligatorio?",
      field: "is_obligatorio",
      render: (row) => {
        return row.is_obligatorio ? "Si" : "No";
      },
    },
    {
      title: "¿Envia Mensajes?",
      field: "can_notificar",
      render: (row) => {
        return row.can_notificar ? "Si" : "No";
      },
    },
    {
      title: "Responsables",
      render: (row) => {
        let tooltip = "";
        let avatar = row.responsables.map((item) => {
          tooltip = tooltip + " " + item.nombre;
          return <Avatar key={item.uid}  alt={item.nombre} src={item.photoUrl} />;
        });
        return (
          <Tooltip title={tooltip} key={row.tableData.id}>
            <AvatarGroup max={4}>{avatar}</AvatarGroup>
          </Tooltip>
        );
      },
    },
  ];

  let actions = [
    (rowData) => ({
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, row) =>
        history.push(`/eliminacion/pasos/edit/${row._id}`),
      iconProps: {
        style: {
          color:
            tienePermisosParaVerTodosContrato(usuarioSesion) ||
            permisoActualizar ||
            permisoActualizarBasico
              ? "#757575"
              : null,
        },
      },
      disabled:
        !(permisoActualizar || permisoActualizarBasico) &&
        rowData.responsable_ref.persona.usuario_id !== usuarioSesion.uid,
    }),
    {
      icon: "add",
      tooltip: "Agregar",
      onClick: () => history.push("/eliminacion/pasos/add"),
      isFreeAction: true,
      // hidden: !permisoAgregar,
      disabled: !permisoAgregar,
    },
  ];
  useEffect(() => {
    setLoading(true);
    obtenerItemsProcesos()
      .then((result) => {
        setListado(result);
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <Container style={{ paddingTop: "50px",paddingLeft:10,paddingRight:10 }} maxWidth={"xl"}>
      <Paper style={{ padding: 15, borderRadius: "15px",padding:10,margin:10 }} elevation={3}>
        <MaterialTable
          title={
            <HeaderBackTable titulo={"Configuración cierre de contrato"} />
          }

          is_loading={loading}
          data={listado}
          localization={localization}
          columns={columns}
          actions={actions}
          fixedColumns={{ right: 0 }}
          options={{
            pageSize: 20,
           
          }}
        />
      </Paper>
    </Container>
  );
}
