import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";
const HeaderBackTable = (props) => {
  let history = useHistory();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <IconButton
          aria-label="back"
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid>
        <Typography variant="h5" >
          {props.titulo}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default HeaderBackTable;
