import React from "react";


const SeccionView = ({
  children,
  borderRadius = 10,
  borderWidth = 1,
  borderStyle = "solid",
  borderColor = "#757575",
  nombreSeccion = "Búsqueda",
  marginBottom=30
}) => {
  return (
    <>
      <fieldset
        aria-hidden="true"
        className="jss232 MuiOutlinedInput-notchedOutline"
        style={{
          marginTop: 30,
          marginBottom: marginBottom,
          borderRadius: borderRadius,
          borderWidth: borderWidth,
          borderStyle: borderStyle,
          borderColor: borderColor,
          paddingInlineStart: "1.0em",
          paddingBottom:15,
          paddingTop:15
        }}
      >
        <legend className="jss234 jss235">
          <span>{nombreSeccion}</span>
        </legend>
        {children}
      </fieldset>
    </>
  );
};

export default SeccionView;