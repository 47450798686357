import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Icon from "@mdi/react";
import { mdiFilePdfBox } from "@mdi/js";
import { CardActionArea, Grid } from "@material-ui/core";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#ecf0f1",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function ItemArchivo(props) {
  const { nombre,tipo } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardActionArea>
          <CardContent className={classes.content}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <PictureAsPdfOutlinedIcon style={{ fontSize: 40 }} />
                {/* <Icon path={mdiFilePdfBox} size={2} /> */}
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {nombre}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {tipo}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </div>
    </Card>
  );
}
