import React from "react";
import { Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormHelperText } from "@material-ui/core";
import ReactSelect from 'react-select';


export default function Page(props) {
  const { formik, permisos, onClose, onEntered, open } = props;
  const { errors, touched } = formik;
  return (
    <Dialog
      open={open}
      onEntered={onEntered}
      maxWidth={"md"}
      fullWidth={true}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Agregar Perfil</DialogTitle>
        <DialogContent>

          <Grid container>
            <Grid item lg>
              <FormControl error={touched.permisos && errors.permisos && Boolean(errors.permisos)} fullWidth>
                <ReactSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  searchable={true}
                  value={formik.values.permisos}
                  onChange={(e)=>{
                    formik.setFieldValue("permisos", e);
                    formik.setFieldTouched("permisos", true, false);
                  }}
                  name="permisos"
                  placeholder="Elegir a un Perfil"
                  options={permisos}
                  noOptionsMessage={() => "No hay resultados"}
                  closeMenuOnSelect={false}
                  isMulti
                />
                <FormHelperText>
                  {touched.permisos && errors.permisos ? errors.permisos : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
          // disabled={!isValid || !values.excel}
          >
            Aceptar
          </Button>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}