export const ACCESS_ERROR = "/accessError";
export const LANDING = "/";
export const LOGIN = "/login";
export const REDIRECT = "/redireccionar";

export const HOME = "/home"

export const CONTRATOS = "/contratos"
export const CONTRATOS_FINALIZADO = "/contratos-finalizado"
export const CONTRATOS_PENDIENTE_FINALIZAR = "/contratos-por-cerrar"
export const CONTRATO = "/contrato"
export const CONTRATO_LEGASY = "/legacy/contrato"
export const VER_CONTRATO = "/contrato/:id"

export const MANDANTES = "/mandantes"
export const MANDANTE = "/mandante"
export const VER_MANDANTE = "/mandante/:id"

export const SOCIEDADES = "/sociedades"
export const SOCIEDAD = "/sociedad"
export const VER_SOCIEDAD = "/sociedad/:id"

export const GERENCIAS = "/gerencias"
export const GERENCIA = "/gerencia"
export const VER_GERENCIA = "/gerencia/:id"

export const RESPONSABLES = "/responsables";
export const RESPONSABLE = "/responsable";
export const VER_RESPONSABLE = "/responsable/:id";

export const ENCUESTAS = "/encuestas"
export const ENCUESTA = "/encuesta"
export const VER_ENCUESTA = "/encuesta/:id"
export const VER_ENCUESTA_CONTRATOS = "/encuesta/contratos"
export const ENVIO_ENCUESTA_PROYECTO = "/envio_encuestas/:id"
export const RESULTADO_ENCUESTAS = "/resultado_encuesta/:id"

export const CONFIGURACION = "/configuracion"
export const LOGS = "/logs"

export const SOLICITUD_NUEVO_CONTRATO = "/solicitud_nuevo_contrato"
export const LIST_SOLICITUD_NUEVO_CONTRATO = "/lista/solicitud_nuevo_contrato"
export const c = "/v2/contrato/"
export const CONTRATO_v2_SOLICITUD = "/v2/contrato/:id"


export const PERMISOS_ROUTE = "/permisos";
export const PERMISOS_PERFIL_ROUTE = "/perfiles/:id/permisos";
export const PERFILES_ROUTE = "/perfiles";
export const PERFILES_ROL_ROUTE = "/roles/:id/perfiles";
export const PERFILES_USUARIO_ROUTE = "/usuarios-plataforma/:id/perfiles";
export const ROLES_ROUTE = "/roles";
export const USUARIOS_PLATAFORMA_ROUTE = "/usuarios-plataforma";

export const SCRIPT_FACTURA_ROUTE = "/factura-script";
export const PROCESOS_ELIMINACION_STEP = "/eliminacion/pasos"
export const PROCESOS_ELIMINACION_STEP_ADD = "/eliminacion/pasos/add"
export const PROCESOS_ELIMINACION_STEP_EDIT = "/eliminacion/pasos/edit/:id"