//ENCUESTAS
export const VER_CONTRATOS_CYD = 'verContratosCyD';
export const ENVIAR_ENCUESTA = 'enviarEncuesta';
export const VER_CONTRATOS_ENCARGADO = "verContratosEncargado";
export const VER_CONTRATOS_DE_GERENCIA ="verContratosGerencia";

//CONTRATOS
export const VER_CONTRATOS = 'verContratos';
export const AGREGAR_CONTRATO = 'agregarContrato';
export const ACTUALIZAR_CONTRATO = 'actualizarContrato';
export const ELIMINAR_CONTRATO = 'eliminarContrato';
export const SOLICITAR_CIERRE_CONTRATO = 'solicitarCierreContrato';
export const ACTUALIZAR_CONTRATO_ENCUESTA = 'actualizarAplicaEncuesta';
export const ACTUALIZAR_CONTRATO_BASICO =  'actualizarResponsableBasico'

//MANDANTES
export const VER_MANDANTES = 'verMandantes';
export const AGREGAR_MANDANTE = 'agregarMandante';
export const ACTUALIZAR_MANDANTE = 'actualizarMandante';
export const ELIMINAR_MANDANTE = 'eliminarMandante';

//SOCIEDADES
export const VER_SOCIEDADES = 'verSociedades';
export const AGREGAR_SOCIEDAD = 'agregarSociedad';
export const ACTUALIZAR_SOCIEDAD = 'actualizarSociedad';
export const ELIMINAR_SOCIEDAD = 'eliminarSociedad';

//GERENCIAS
export const VER_GERENCIAS = 'verGerencias';
export const AGREGAR_GERENCIA = 'agregarGerencia';
export const ACTUALIZAR_GERENCIA = 'actualizarGerencia';
export const ELIMINAR_GERENCIA = 'eliminarGerencia';

//RESPONSABLES
export const VER_RESPONSABLES = 'verResponsables';
export const AGREGAR_RESPONSABLE = 'agregarResponsable';
export const ACTUALIZAR_RESPONSABLE = 'actualizarResponsable';
export const ELIMINAR_RESPONSABLE = 'eliminarResponsable';


export const VER_CENTRO_COSTO =  'verCentroCosto'

export const ENVIAR_SOLICITUD_INGRESO =  'solicitarNuevoContrato'
export const VER_SOLICITUD_INGRESO =  'verSolicitarNuevoContrato'



//CONFIGURACIÓN
export const CONFIGURAR_PLATAFORMA = 'configurarPlataforma';

//PERMISOS
export const AGREGAR_PERMISOS_PERMISO = "agregarPermisos";
export const AGREGAR_PERFILES_PERMISO = "agregarPerfiles";
export const AGREGAR_ROLES_PERMISO = "agregarRoles";
export const AGREGAR_USUARIOS_PLATAFORMA_PERMISO = "agregarUsuariosPlataforma";
export const EDITAR_PERMISOS_PERMISO = "editarPermisos";
export const EDITAR_PERFILES_PERMISO = "editarPerfiles";
export const EDITAR_ROLES_PERMISO = "editarRoles";
export const EDITAR_USUARIOS_PLATAFORMA_PERMISO = "editarUsuariosPlataforma";
export const ELIMINAR_PERMISOS_PERMISO = "eliminarPermisos";
export const ELIMINAR_PERFILES_PERMISO = "eliminarPerfiles";
export const ELIMINAR_ROLES_PERMISO = "eliminarRoles";
export const ELIMINAR_USUARIOS_PLATAFORMA_PERMISO = "eliminarUsuariosPlataforma";
export const VER_ADMINISTRACION_PERMISO = "verAdministracion";
export const VER_PERMISOS_PERMISO = "verPermisos";
export const VER_PERFILES_PERMISO = "verPerfiles";
export const VER_ROLES_PERMISO = "verRoles";
export const VER_USUARIOS_PLATAFORMA_PERMISO = "verUsuarios";
export const VER_CONTRATO_PDF = "verContratoPdf";
export const VER_DONWLOAD_DOCUMENTO_PDF="verDownloadDocumentos"
export const VER_CONTRATOS_PROCESO_ELIMINACION="verContratosParaEliminar"


