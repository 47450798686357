import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid, Typography } from "@material-ui/core";

// const logo = require("../../../src/img/logo-cydocs-color.png");

const logo = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";

function Page({ texto: Texto }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container className={classes.grid_container}>
        <Grid item lg className={classes.center}>
          <img className={classes.img} src={logo} alt="logo" />
          <Typography className={classes.title} variant="h5">{Texto}</Typography>
          <LinearProgress color="primary" className={classes.linearProgress} />
        </Grid>
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  grid_container: {
    alignContent: "center",
    alignItems: "center",
  },
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11),
  },
  img: {
    height: 100,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linearProgress: {
    marginLeft: "60vh",
    marginRight: "60vh",
  },
  title: {
    margin: theme.spacing(3, 1, 3, 1),
  }
}));

export default Page;
