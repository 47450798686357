/**
 * Funciones de consulta a base de datos para perfiles
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import axios from 'axios';

/**
 * Obtener un perfil de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getPerfil = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}`;
  const promise = axios.get(url, {
    canceltoken: cancelToken
  });
  return promise
}

/**
 * Obtener todos los perfiles de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAllPerfil = (filters, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/`;
  const promise = axios.get(url, {
    params: filters,
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Actualizar los datos un perfil en la base de datos
 * @param {String} id id del registro
 * @param {Object} data campos actualizados
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updatePerfil = (id, data, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}`;
  const promise = axios.put(url, data, {
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Eliminar un perfil de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deletePerfil = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}`;
  const promise = axios.delete(url, {
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Crear un nuevo perfil en la base de datos
 * @param {Object} data Campos del nuevo registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postPerfil = (data, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/`;
  const promise = axios.post(url, data, {
    canceltoken: cancelToken
  });
  return promise
}


/**
 * Obtener los permisos de un perfil de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getPermisosPerfil = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}/permisos`;
  const promise = axios.get(url, {
    canceltoken: cancelToken
  });
  return promise
}

/**
 * Agregar los permisos de a un perfil de la base de datos
 * @param {String} id id del registro
 * @param {Array} permisos listado de id de permisos
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postPermisosPerfil = (id, permisos, cancelToken = null) => {

  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}/permisos`;
  console.log(id, permisos, url);
  const promise = axios.post(url, permisos, {
    canceltoken: cancelToken
  });
  return promise
}


/**
 * Eliminar los permisos de a un perfil de la base de datos
 * @param {String} id id del registro
 * @param {Array} permisos listado de id de permisos
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deletePermisosPerfil = (id, permisos, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/perfiles/${id}/permisos`;
  const promise = axios.delete(url, {
    canceltoken: cancelToken,
    data: permisos
  });
  return promise
}
