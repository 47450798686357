import React, { createRef, useEffect, useState } from "react";
import { Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormHelperText, Avatar, TextField, makeStyles, ListSubheader, Chip } from "@material-ui/core";
import ReactSelect from 'react-select';
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";
import schema from "./schema.json";
import ObjectFieldTemplate from "../../../adminProcesoEliminacion/FormCyd";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { useUsuarios } from "../../../responsables/nuevo/hooksPersonas";
import SelectorGenerico from "../../../../components/form/SelectorGenerico";

import { GuardarUsuario, obtenerUsuariosCyd } from "../requestUsuarios";

const Form = withTheme(MaterialUITheme);

let yourForm;

export default function PersonaAdd(props) {
  const { onClose, onEntered, open } = props;
  const [formData, setFormData] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const formUser = React.useRef(null);

  let cancelToken = axios.CancelToken.source();

  useEffect(() => {
    obtenerUsuariosCyd().then((result => setUsuarios(result)))
  }, [])

  const onCancel = () => {
    console.log("on reset being called");
  };

  // const onFormChanged = ({ formData }) => {
  //   console.log("onFormChanged: ", formData); // eslint-disable-line no-console
  //   setFormData(formData);
  // };
  const onError = () => {
    console.log("on reset being called");
  };

  const onSubmit = ({ formData }) => console.log("Data submitted: ", formData);

  function validate(formData, errors) {

    //let isValid = await validation.validateSync(formData); //.catch((err)=> console.error(
    //err.name, // => 'ValidationError'
    //err.errors // => [{ key: 'field_too_short', values: { min: 18 } }]
    //));
    //console.log("validae =>", isValid);

    if (formData.pass1 !== formData.pass2) {
      errors.pass2.addError("Passwords don't match");
    }
    return errors;
  }


  function transformErrors(errors) {
    console.log(errors);
    return errors.map((error) => {
      if (error.name === "pattern") {
        error.message = "Only sdf digits are allowed";
      }
      return error;
    });
  }

  const widgets = {
    selectItem: SelectItem,
  };
  const submitFormRef = createRef();
  let yourForm;
  return (
    <Dialog
      open={open}
      onEntered={onEntered}
      maxWidth={"md"}
      fullWidth={true}
      onClose={onClose}
    >

      <DialogTitle id="alert-dialog-title">Agregar Usuario</DialogTitle>
      <DialogContent>

        <Grid container>
          <Grid item lg>
            <Form
              method="post"
              action="submit"
              schema={schema}
              formData={formData}
              onCancel={onCancel}
              onSubmit={onSubmit}
              validate={validate}
              // onChange={onFormChanged}
              onError={onError}
              showErrorList={false}
              widgets={widgets}
              omitExtraData={false}
              noValidate={false}
              noHtml5Validate={true}
              transformErrors={transformErrors}
              ref={(form) => {
                yourForm = form;
              }}
              // ref={formUser}
              // ref={(form) => {
              //   console.log(form);
              //   yourForm = form
              //   formUser
              //   //setFormUser(form)
              // }}
              uiSchema={{
                "ui:ObjectFieldTemplate": ObjectFieldTemplate,
                "ui:submitButtonOptions": {
                  "norender": true,
                  submitText: "Guardar",
                },
                usuario: {
                  "ui:widget": SelectorGenerico,
                  "ui:options": {
                    labelOption: "label",
                    backgroundColor: "yellow",
                    datos: usuarios,
                    fetch: obtenerUsuariosCyd,
                    "col": {
                      xs: 12,
                      md: 12,
                      xl: 12
                    }
                  },
                },

              }}
            >
              <button ref={submitFormRef} type="submit" style={{ display: "none" }} />
            </Form>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            console.log("onclick ")
            // submitFormRef.current.click()
            console.log(yourForm.state.formData)
            GuardarUsuario({
              "nombre": yourForm.state.formData.usuario.nombre_abreviado,
              "uid":yourForm.state.formData.usuario.usuario_id,
            })
            // yourForm.submit()
            console.log("onclick ")
          }}
        // disabled={!isValid || !values.excel}
        >
          Aceptar
        </Button>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>

    </Dialog>
  );
}

export const SelectItem = (props) => {
  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState(options.enumOptions);
  const [value, setValue] = useState(
    typeof props.value == "undefined" ? null : props.value
  );

  return (
    <Autocomplete
      label={props.label}
      value={value}
      //onChange={(event) => props.onChange(event.target.value)}
      onChange={(_ev, newValue, reason) => {
        props.onChange(newValue.value ?? {});
        setValue(newValue);
        //props.onChange(newValue.map(getOptionName));
      }}
      options={optionsUsuarios}
      getOptionLabel={(g) => `${g.label}`}
      renderInput={(params) => (
        <TextField
          label={props.label}
          variant="outlined"
          // required={props.required}
          {...params}
        />
      )}
    />
  );
};

const useStylesSlector = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  fullWidth: {
    width: "100%",
  },
}));

export const MultipleSelector = (props) => {

  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState([]);

  const classes = useStylesSlector();
  const [value, setValue] = useState(
    typeof props.value == "undefined" ? null : props.value
  );
  let cancelToken = axios.CancelToken.source();
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    null,
    cancelToken
  );

  useEffect(() => {
    // console.log(UsuariosResponsables);
    if (typeof UsuariosResponsables === "undefined") {
      setOptionsUsurios([]);
    } else {
      console.log(UsuariosResponsables.length);
      if (UsuariosResponsables.length === 0) {
        setOptionsUsurios([]);
      } else {
        setOptionsUsurios(UsuariosResponsables);
      }
    }
  }, [UsuariosResponsables]);

  const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return (
    <Autocomplete
      label={props.label}
      value={value}
      multiple={false}
      id="tags-outlined"
      onChange={(_ev, newValue, reason) => {
        props.onChange(newValue ?? {});
        setValue(newValue);
        //props.onChange(newValue.map(getOptionName));
      }}
      renderGroup={renderGroup}
      loading={loadResponsables}
      options={optionsUsuarios}
      getOptionLabel={(g) => `${g[options.labelOption]}`}
      filterSelectedOptions
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              style={{ margin: "3px" }}
              label={option.nombre_completo}
              avatar={<Avatar alt="Natacha" src={option.photo} />}
              {...getTagProps({ index })}
            />
          );
        });
        // </div>
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="ingrese el nombre del usuario"
            variant="outlined"
            label={props.label}
          />
        );
      }}
    />
  );
};