import React, { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

import { ObtenerUsuariosPlataforma, AgregarUsuarioPlataforma, ActualizarUsuarioPlataforma, EliminarUsuarioPlataforma } from "../requestUsuariosPlataforma";

import { ObtenerUsuarios } from "../requestUsuarios";

import MaterialTable from "../../../../components/material_table";
import { ObtenerPerfiles } from "../requestPerfiles";
import PerfilAdd from "./perfilAdd";
import { getAllPerfil } from "../../perfiles/requestPerfiles";
import PersonaAdd from "./personaAdd";

function TabUsuariosPlataforma(props) {
	const {
		data: usuariosPlataforma,
		error: errorUsuariosPlataforma,
		mutate: mutateUsuariosPlataforma } = useSWR("usuariosPlataforma", (key) => ObtenerUsuariosPlataforma(), { revalidateOnFocus: false });
	const { data: usuarios, error: errorUsuarios, mutate: mutateUsuarios } = useSWR("usuarios", (key) => ObtenerUsuarios(), { revalidateOnFocus: false });
	const { data: perfiles, error: errorPerfiles, mutate: mutatePerfiles } = useSWR("perfiles", (key) => ObtenerPerfiles(), { revalidateOnFocus: false });
	const [perfilesadd, setPerfiles] = useState([]);
	const [openUsuario, setOpenUsuario] = useState(false);
	const [openPerfiles, setOpenPerfiles] = useState(false);

	const notistack = useSnackbar();

	useEffect(() => {
		if (errorUsuariosPlataforma) {
			notistack.enqueueSnackbar("Error al intentar obtener los usuarios de plataforma.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
		if (errorUsuarios) {
			notistack.enqueueSnackbar("Error al intentar obtener los usuarios.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
		if (errorPerfiles) {
			notistack.enqueueSnackbar("Error al intentar obtener los perfiles.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}

	}, [errorUsuariosPlataforma, errorUsuarios, errorPerfiles]);

	const formik = useFormik({
		initialValues: {
			nombre: "",
			rut: "",
			uid: "",
			gerencia_sigla: "",
			_perfiles_ref: [],
			tags: [],
			_preferencias_ref: [],
		},
		validationSchema: object().shape({
			nombre: string()
				.min(3, "El nombre debe tener al menos ${min} caracteres.")
				.max(50, "El nombre debe tener a los más ${max} caracteres.")
				.required("El nombre es requerido."),
			rut: string()
				.min(7, "El RUT debe tener al menos ${min} caracteres.")
				.max(10, "El RUT debe tener a los más ${max} caracteres.")
				.required("El RUT es requerido."),
			uid: string()
				.min(7, "El UID debe tener al menos ${min} caracteres.")
				.required("El UID es requerido."),
			gerencia_sigla: string()
				.min(3, "La gerencia debe tener al menos ${min} caracteres.")
				.max(5, "La gerencia debe tener a los más ${max} caracteres.")
				.required("La gerencia es requerida."),
			_perfiles_ref: array()
				.of(string())
				.min(1, "Se debe incluir al menos ${min} perfil.")
				.required("El perfil es requerido."),
			tags: array()
				.of(string())
				.min(1, "Se debe incluir al menos ${min} tag.")
				.required("Los Tags son requeridos."),
			_preferencias_ref: array()
				.of(string())
				.optional(),
		}),
		onSubmit: (values, helper) => handleAceptar(values),
		enableReinitialize: true,
	});

	const formikPerfil = useFormik({
		initialValues: {
		},

		onSubmit: (values, helper) => handleAceptarPerfil(values),
		enableReinitialize: true,
	});

	/**
	 * Handler para agregar un nuevo rol.
	 */
	const handleAgregar = () => {
		formik.setFieldValue("tipo", "agregar");
		console.log("open")

	}



	const handleAgregarPerfil = () => {
		formik.setFieldValue("tipoPerfil", "agregar");

	}

	const onEntered = () => {
		if (formik.values) {
			getAllPerfil({})
				.then(response => {
					if (response.status === 200) {
						const perfiles = response.data;
						const suggestions = [];
						for (let i = 0; i < perfiles.length; i++) {
							const option = { label: perfiles[i].nombre, value: i, _id: perfiles[i]._id };
							if (formik.values.perfiles && formik.values.perfiles.find(a => a._id === perfiles[i]._id)) continue;
							suggestions.push(option);

						}
						setPerfiles(suggestions);
					} else if (response.status === 204) {
						notistack.enqueueSnackbar("No hay perfiles cargados", {
							variant: 'warning',
							anchorOrigin: {
								horizontal: "center",
								vertical: "bottom"
							}
						});
						setPerfiles([]);
					} else {
						notistack.enqueueSnackbar("Error al cargar los perfiles. Intente de nuevo o contacte con soporte.", {
							variant: 'error',
							anchorOrigin: {
								horizontal: "center",
								vertical: "bottom"
							}
						});
						console.log(response);
					}
				})
				.catch(e => {
					console.log(e);
				});
		}
	}

	/**
	 * Handler para actualizar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleActualizar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "actualizar");
	}

	/**
	 * Handler para eliminar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleEliminar = (event, row) => {

		let arrayPerfilesActual = formik.values.perfiles.map(perfil => {
			return perfil._id;
		})

		let arr = arrayPerfilesActual.filter(function (item) {
			return item !== row._id
		})
		console.log(arr);

		try {
			ActualizarUsuarioPlataforma(formik.values._id,
				{
					nombre: formik.values.nombre,
					uid: formik.values.uid,
					perfiles: arr
				});
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handleCancelar();
			mutateUsuariosPlataforma();
			mutateUsuarios();
		}


	}

	/**
	 * Handler para aceptar la acción.
	 * @param {*} values 
	 */
	const handleAceptar = async (values) => {
		console.log(values)
		try {
			switch (values.tipo) {
				case "agregar":
					await AgregarUsuarioPlataforma(values);
					break;
				case "actualizar":
					await ActualizarUsuarioPlataforma(values);
					break;
				case "eliminar":
					console.log(values);
					await EliminarUsuarioPlataforma(values);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handleCancelar();
		}
	}

	const handleCancelar = () => {
		mutateUsuariosPlataforma();
		mutateUsuarios();
		formik.resetForm();
	}

	const handleCancelarPerfil = () => {
		formik.setFieldValue("tipoPerfil", null);
	}

	const handleAgregarUsuario = () => {
		console.log("click add user");
		setOpenUsuario(true)
	}
	/**
	 * Handler para aceptar la acción.
	 * @param {*} values 
	 */
	const handleAceptarPerfil = async (values) => {
		console.log(values);
		let arrayPerfiles = values.perfiles.map(permiso => {
			return permiso._id;
		})
		let arrayPerfilesActual = formik.values.perfiles.map(perfil => {
			return perfil._id;
		})
		console.log(formik.values);
		console.log([...arrayPerfilesActual, ...arrayPerfiles])

		try {
			let result = await ActualizarUsuarioPlataforma(formik.values._id,
				{
					nombre: formik.values.nombre,
					uid: formik.values.uid,
					perfiles: [...arrayPerfilesActual, ...arrayPerfiles]
				});
			console.log(result);
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handleCancelar();
		}


	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "uid",
			field: "uid",
			render: (row) => {
				return (<Grid key={row.uid} container spacing={2}>
					{
						row.perfiles.map((perfil, index) => {
							return <Grid key={index} item style={{ padding: 5 }}>
								<Chip label={perfil.nombre} />
							</Grid>
						})
					}
				</Grid>)
			}
		}
	];

	let columnsPerfil = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "descripcion",
			field: "descripcion",
		}
	]
	let actions = [
		{
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregarUsuario(),
			isFreeAction: true,
		}, {
			tooltip: "Editar",
			icon: "edit",
			onClick: handleActualizar,
		}, {
			tooltip: "Eliminar",
			icon: "delete",
			onClick: handleEliminar,
		}
	];

	let actionsPerfil = [
		{
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregarPerfil(),
			isFreeAction: true,
		}, {
			tooltip: "Eliminar",
			icon: "delete",
			onClick: handleEliminar,
		}
	];



	useEffect(() => {

	}, [formik])


	return (
		<Fragment>
			<MaterialTable
				title="Usuario de Plataforma"
				data={usuariosPlataforma && usuariosPlataforma}
				columns={columns}
				actions={actions}
			/>
			<Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
				<DialogTitle>
					{formik.values.tipo === "agregar" && "Agregar Usuario de Plataforma"}
					{formik.values.tipo === "actualizar" && "Actualizar Usuario de Plataforma"}
					{formik.values.tipo === "eliminar" && "Eliminar Usuario de Plataforma"}
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						{/* USUARIO */}
						<Grid item xs={12}>
							<MaterialTable
								title="Perfiles del autorizados"
								data={formik.values.perfiles}
								columns={columnsPerfil}
								actions={actionsPerfil}
							/>

						</Grid>

						{/* PERFILES */}
						<Grid item xs={12}>

						</Grid>

					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelar} variant="outlined" color="primary">
						Cancelar
					</Button>
					<Button onClick={formik.submitForm} variant="contained" color="primary">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
			<PerfilAdd
				open={Boolean(formik.values.tipoPerfil)}
				formik={formikPerfil}
				onClose={handleCancelarPerfil}
				perfiles={perfilesadd}
				onEntered={onEntered}
			/>
			<PersonaAdd
				open={openUsuario}
				formik={formikPerfil}
				onClose={handleCancelarPerfil}
				perfiles={perfilesadd}
				onEntered={onEntered}
			/>

		</Fragment>
	);
}

export default TabUsuariosPlataforma;
