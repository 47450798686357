import Axios from "axios";

const BASE_URL = process.env.REACT_APP_DB_URL;
const ITEM_DE_ELIMINACION = "eliminacion/proceso";

/**
 * Método encargado de obtener todos los contratos.
 */
export async function obtenerItemsProcesos() {
	try {
		let url = `${BASE_URL}/${ITEM_DE_ELIMINACION}`;
		let response = await Axios.get(url);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function obtenerItemProceso(contratoID) {
	try {
		let url = `${BASE_URL}/${ITEM_DE_ELIMINACION}/${contratoID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un contrato.
 * @param {*} contrato Datos del contrato.
 */
export async function agregarItemProceso(contrato) {
	try {
		let url = `${BASE_URL}/${ITEM_DE_ELIMINACION}`;
		let response = await Axios.post(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un contrato.
 * @param {*} contrato Datos actualizados del contrato.
 */
export async function actualizarItemProceso(contrato, usuarioSesion) {
	try {
		let url = `${BASE_URL}/${ITEM_DE_ELIMINACION}/${contrato._id}`;
		console.log(usuarioSesion);
		let response = await Axios.put(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function eliminarItemProceso(contratoID, usuarioSesion) {
	try {
		let contrato = { is_eliminado: true };
		let url = `${BASE_URL}/${ITEM_DE_ELIMINACION}/${contratoID._id}`;
		let response = await Axios.put(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

////////////////////////////////////////////////////////////////////////
