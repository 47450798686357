import Axios from "axios";
import { CERRADO, EN_PROCESOS_DE_CIERRE } from "../../constants/estados";
import comunasJson from "./comunas.json"

const BASE_URL = process.env.REACT_APP_DB_URL;
const DEBUG = false;

/**
 * Método encargado de obtener todos los contratos.
 */
export async function obtenerContratos() {
	try {
		let url = `${BASE_URL}/contratos?is_centro_costo=${false}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function envioNotificacionContrato(contratoID, mensaje) {
	try {
		let url = `${BASE_URL}/contratos/cierre-contrato/envio-notificacion/${contratoID}`;
		let response = await Axios.post(url, { mensaje: mensaje });
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function obtenerContrato(contratoID) {
	try {
		let url = `${BASE_URL}/contratos/${contratoID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * 
 * @param {*} contratoID 
 * @returns 
 */
export async function obtenerContratoForm(contratoID) {
	try {
		let url = `${BASE_URL}/contratos/${contratoID}/form`;
		let response = await Axios.get(url);
		let contratoAux = {
			...response.data,
			codigo_nuevo: {
				gerencia: response.data.codigo.split("-")[0],
				responsable: response.data.codigo.split("-")[1],
				correlativo: response.data.codigo.split("-")[2],
			},
			correlativo: response.data.codigo.split("-")[2]
		}
		return contratoAux;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * 
 * @param {*} contratoID 
 * @param {*} idProceso 
 * @param {*} cerradoPor 
 * @param {*} estado 
 * @returns 
 */
export async function actualizarProcesoEliminacionContrato(contratoID, idProceso, cerradoPor, estado) {
	console.log(cerradoPor);
	try {
		let url = `${BASE_URL}/contratos/${contratoID}/finalizar-etapa/${idProceso}?debug=true`;
		let response = await Axios.put(url, {
			proceso_de_finalizacion: [
				{ id_proceso: idProceso, is_cerrado: estado, cerrado_por: cerradoPor },
			],
		});
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un contrato.
 * @param {*} contrato Datos del contrato.
 */
export async function agregarContrato(contrato) {
	try {
		let url = `${BASE_URL}/contratos?sendMail=true&debug=${DEBUG}`;
		let response = await Axios.post(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un contrato.
 * @param {*} contrato Datos actualizados del contrato.
 */
export async function actualizarContrato(contrato, usuarioSesion) {
	try {
		let url = `${BASE_URL}/contratos/${contrato._id}`;

		let response = await Axios.put(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function eliminarContrato(contratoID, usuarioSesion) {
	try {
		let contrato = {
			is_eliminado: false,
			estado: EN_PROCESOS_DE_CIERRE,
			inicio_proceso_de_finalizacion: Date(),
			incio_de_cierre_por: usuarioSesion,
		};
		let url = `${BASE_URL}/contratos/${contratoID._id}/update-estado?debug=true`;
		let response = await Axios.put(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function eliminarContratoDefinitivo(contratoID, usuarioSesion) {
	try {
		let contrato = {
			is_eliminado: true,
			estado: CERRADO,
			fecha_cierre_real: Date(),
			cerrado_por: usuarioSesion,
		};
		let url = `${BASE_URL}/contratos/${contratoID._id}/update-estado?debug=true`;
		let response = await Axios.put(url, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

////////////////////////////////////////////////////////////////////////

/**
 * Método encargado de obtener las gerencias.
 */
export async function obtenerGerencias() {
	try {
		let url = `${BASE_URL}/gerencias`;
		let response = await Axios.get(url);
		let result = response.data.map((gerencia) => {
			return { ...gerencia, label: `${gerencia.sigla} - ${gerencia.nombre}` };
		});
		return result;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las sociedades.
 */
export async function obtenerSociedades() {
	try {
		let url = `${BASE_URL}/sociedades`;
		let response = await Axios.get(url);
		let sociedades = response.data.map(soc => {
			return {
				...soc,
				label: `${soc.nombre} - ${soc.rut}`
			}
		})
		return sociedades;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las personas que pueden ser responsables de contratos/proyectos.
 */
export async function obtenerResponsables(query = null) {
	try {
		let url = `${BASE_URL}/responsables`;
		if (query) {
			url += `?${query}`;
		}
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los mandantes.
 */
export async function obtenerMandantes() {
	try {
		let url = `${BASE_URL}/mandantes`;
		let response = await Axios.get(url);
		let mandantes = response.data.map(man => {
			return {
				...man,
				label: `${man.nombre} - ${man.rut}`
			}
		})
		return mandantes;
	} catch (error) {
		console.error(error);
		throw error;
	}
}