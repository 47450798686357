import React, { useEffect } from "react";
import { useContratosVigentes } from "../contratos/hooks";
import { useSelector } from "react-redux";


import { getUserEspecial } from "../../services/database/usuario";

function Index(props) {
  // const [Contratos, SetContratos] = useState();

  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));
  const [{ loading, contratos: Contratos }] = useContratosVigentes(
    usuarioSesion,
    true
  );
  

  useEffect(() => {
    const usuario = async () => {
      let users = await getUserEspecial();
      console.log(users);
      
      var cont1 = 0;
      var cont2 = 0;
      Contratos.map(async (contrato) => {
        if (contrato.aprobadores_factura) {
          if(contrato.aprobadores_factura.aprobadores[0].uid){
            cont1++;
          }
          if(contrato.aprobadores_factura.aprobadores[1].uid){
            cont2++;
          }



          // if (!contrato.aprobadores_factura.aprobadores[1].uid) {
          //   var nombre = "";
          //   if (
          //     contrato.aprobadores_factura.aprobadores[1].nombre.split(" ")
          //       .length === 4
          //   ) {
          //     nombre =
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[0] +
          //       " " +
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[2][0] +
          //       "." +
          //       " " +
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[3][0] +
          //       ".";
          //   } else {
          //     nombre =
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[0] +
          //       " " +
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[1][0] +
          //       "." +
          //       " " +
          //       contrato.aprobadores_factura.aprobadores[1].nombre.split(
          //         " "
          //       )[2][0] +
          //       ".";
          //   }

          //   var result = users.find((item) => {
          //     var nombreRes = "";
          //     let nomArr = item.persona.nombre_abreviado.split(" ");
          //     if (nomArr.length === 4) {
          //       nombreRes = nomArr[0] + " " + nomArr[2] + " " + nomArr[3];
          //     } else {
          //       nombreRes = item.persona.nombre_abreviado;
          //     }
          //     return nombreRes === nombre;
          //   });

          //   if (typeof result === "undefined") {
          //     console.error(nombre);
          //   } else {
              
          //     var aprobador1 = contrato.aprobadores_factura.aprobadores[0];
          //     var aprobador2 = contrato.aprobadores_factura.aprobadores[1];
          //     aprobador2.uid = result.persona.usuario_id;
          //     var saved = {
          //       _id: contrato._id,
          //       aprobadores_factura: {
          //         aprobadores: [aprobador1, aprobador2],
          //       },
          //     };
          //     console.log(saved)
          //     //await ActualizarContrato(saved);
          //   }

          //   // if (nombre === item.nombre.split(" ")[0]) {

          //   //   //console.log(item.uid);
          //   //   //console.log(contrato.aprobadores_factura.aprobadores[0].uid);
          //   //   var aprobador1 = contrato.aprobadores_factura.aprobadores[0];
          //   //   var aprobador2 = contrato.aprobadores_factura.aprobadores[1];
          //   //   aprobador1.uid = item.uid;
          //   //   //console.log(aprobador1);
          //   //   // await ActualizarContrato({
          //   //   //   _id: item._id,
          //   //   //   aprobadores_factura: {
          //   //   //     aprobadores: [aprobador1, aprobador2],
          //   //   //   },
          //   //   // });
          //   // }
          // }
        }
      });
      console.log(cont1)
      console.log(cont2)
    };
    usuario();

    Contratos.map(async (item) => {
      if (item.aprobadores_factura) {
        //console.log(item.aprobadores_factura.aprobadores[0].uid);
        //console.log(item.aprobadores_factura.aprobadores[1].uid);
        if (!item.aprobadores_factura.aprobadores[0].uid) {
          // console.log(item.aprobadores_factura.aprobadores[0].uid)
          // console.log(item.aprobadores_factura.aprobadores[0].correo)
          // console.log(item.aprobadores_factura.aprobadores[0]._id);
          console.log(item.aprobadores_factura.aprobadores[0]);
        }

        // if(!item.aprobadores_factura.aprobadores[1].uid){
        //   console.log(item.aprobadores_factura.aprobadores[1])
        // }
        // var aprobador = {
        //   nombre: item.responsable_ref.persona.nombre_abreviado,
        //   nombre_abreviado:
        //     item.responsable_ref.persona.nombre_abreviado,
        //   apellido_materno:
        //     item.responsable_ref.persona.apellido_materno,
        //   apellido_paterno:
        //     item.responsable_ref.persona.apellido_paterno,
        //   nombre_completo: item.responsable_ref.persona.nombre_completo,
        //   email: item.responsable_ref.persona.contacto.email,
        //   photoUrl: item.responsable_ref.persona.photo,
        //   uid: item.responsable_ref.persona.auth_id,
        //   rut: item.responsable_ref.persona.run,
        //   cargo: item.responsable_ref.persona.cargo,
        // };
        // console.log(aprobador);

        // console.error({
        //   _id: item._id,
        //   aprobadores_factura: {
        //     aprobadores: [aprobador,aprobador],
        //   },
        // });
        /*ActualizarContrato({
            _id:item._id,
            aprobadores_factura:{
              aprobadores: [aprobador,aprobador],
            },
        });*/
      }
    });
  }, [loading]);

  return <d>hola mundo </d>;
}

export default Index;
