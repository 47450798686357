import React, { useState, useEffect } from "react";
import Page from "./page";
import { eliminarGerencia, obtenerGerencias } from "../requestGerencia";
import XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Gerencias, SetGerencias] = useState();
	const [DialogOpen, SetDialogOpen] = useState(false);
	const [ItemSeleccionado, SetItemSeleccionado] = useState(null);
	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerGerencias()
			.then(gerencias => SetGerencias(gerencias))
			.catch(error => {
				console.error(error);
				SetGerencias([]);
			});
	}, []);

	const handleShowDialog = (gerenciaID) => {
		SetDialogOpen(true);
		SetItemSeleccionado(gerenciaID);
	}

	/**
	 * Handler para eliminar una gerencia.
	 */
	const handleEliminar = () => {
		eliminarGerencia(ItemSeleccionado)
			.then(response => {
				console.log("Gerencia eliminada exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Gerencia eliminada exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar eliminar la gerencia", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar eliminar la gerencia", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				SetDialogOpen(false);
				SetItemSeleccionado(null);
			});
	}

	/**
	 * Handler para cancelar la eliminación.
	 */
	const handleCancelarEliminar = () => {
		SetDialogOpen(false);
		SetItemSeleccionado(null);
	}

	/**
	 * Hanlder para exportar datos con formato Excel.
	 */
	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Gerencias.map(g => ({
			nombre: g.nombre,
			gerencia: g.sigla,
			encargado: g.responsable_ref.persona.nombre_completo,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Gerencias");
		XLSX.writeFile(wb, "gerencias.xlsx");
	}

	return (
		<Page
			usuario={usuarioSesion}
			gerencias={Gerencias}
			dialog_open={DialogOpen}
			handle_show_dialog={handleShowDialog}
			handle_eliminar={handleEliminar}
			handle_cancelar={handleCancelarEliminar}
			handle_export={handleExport}
		/>
	);
}

export default Index;