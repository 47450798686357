import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CardActionArea, Grid } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { object, mixed } from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#dcdde1",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function ItemArchivoUpload(props) {
  const { onCickItem}=props;
  const classes = useStyles();
  const theme = useTheme();
  const notistack = useSnackbar();

  const [nombreFile, setNombreFile] = useState(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ["application/pdf"],
    multiple: false,
  });
  const formik = useFormik({
    initialValues: {
      archivo: null,
    },
    validationSchema: object().shape({
      archivo: mixed().nullable().optional(),
    }),
    onSubmit: async function (values, helper) {
      console.log(values);
      notistack.enqueueSnackbar("Subiendo los documentos...", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
      // let uploadedCurriculum = await uploadFileToCloudStorage(values.curriculum_vitae, "curriculums", "");
			// 		values.curriculum_vitae = uploadedCurriculum;

    },
  });

  useEffect(() => {
    console.log("acept file", acceptedFiles);
    formik.setFieldValue("nombre", acceptedFiles[0]);

    setNombreFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
      {/* {...getRootProps()} */}
        <CardActionArea onClick={onCickItem} >
          <CardContent className={classes.content}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <input {...getInputProps()} />
                <Grid item>
                  <CloudUploadIcon size={2} />
                </Grid>
                <Typography variant="body1">
                  {"Subir Archivo"}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </div>
    </Card>
  );
}
