import Axios from "axios";
const BASE_URL = process.env.REACT_APP_DB_URL;
/**
 * Método encargado de obtener un usuario plataforma, por su ID.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 */
export async function ObtenerUsuarioPlataforma(usuarioPlataformaID) {
  try {
    let url = `${BASE_URL}/usuarios-plataforma/${usuarioPlataformaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los usuarios plataforma.
 */
export async function ObtenerUsuariosPlataforma() {
  try {
    let url = `${BASE_URL}/usuarios`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 */
export async function AgregarUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${BASE_URL}/usuarios-plataforma`;
    let response = await Axios.post(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos actualizados del usuario plataforma.
 */
export async function ActualizarUsuarioPlataforma(id,values) {
  try {
    let url = `${BASE_URL}/usuarios/${id}`;
    let response = await Axios.put(url, values);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 */
export async function EliminarUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${BASE_URL}/usuarios/${usuarioPlataforma._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
