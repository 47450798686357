import React, { Fragment, useEffect } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";
import axios from "axios";
import {  useUsuarios } from "./hooksPersonas";



function Page(props) {
  const { handle_limpiar, formik } = props;

  const classes = useStyle();
  const {
    values,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,
    errors,
    touched,
  } = formik;

  let cancelToken = axios.CancelToken.source();
  const [inputValue, setInputValue] = React.useState("");
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    inputValue,
    cancelToken
  );
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoadding] = React.useState(false);

  

  useEffect(() => {
    console.log(UsuariosResponsables);
    if (typeof UsuariosResponsables === "undefined") {
      setOptions([]);
    } else {
      console.log(UsuariosResponsables.length);
      if (UsuariosResponsables.length === 0) {
        setOptions([]);
      } else {
        setOptions(UsuariosResponsables);
      }
    }
  }, [UsuariosResponsables]);



  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

 

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Responsables</Typography>
        <Link to="/responsables">Responsables</Link>
        <Typography color="textPrimary">Ingresar Responsable</Typography>
      </Breadcrumbs>
      <Paper className={classes.card}>
        <form onSubmit={handleSubmit}>
          {/* CAMPOS DEL RESPONSABLE */}
          <Grid container spacing={3}>
            <Grid item xs={6} xl={4}>
              {/* USUARIO RESPONSABLE */}
              <Autocomplete
                id="asynchronous-demo"
                open={open}
                loading={loading}
                onOpen={() => {
                  setOpen(true);
                  setLoadding(true);
                }}
                onClose={() => {
                  console.log("close");
                  setOpen(false);
                  setLoadding(false);
                }}
                onChange={(event, value) => {
                  setOpen(false);
                  setLoadding(false);
                  setFieldValue("persona", value ? value : null);
                }}
                options={options}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                getOptionSelected={(option, value) =>
                  option.run_nombre_formateados === value.run_nombre_formateados
                }
                getOptionLabel={(responsable) => {
                  return (
                    responsable.run_nombre_formateados +
                    " | " +
                    responsable.contacto.email
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Usuario"
                    variant="outlined"
                    fullWidth={true}
                    required
                    error={
                      touched.persona &&
                      errors.persona &&
                      Boolean(errors.persona)
                    }
                    helperText={
                      touched.persona && errors.persona ? errors.persona : ""
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadResponsables ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
             
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* CORRELATIVO */}
              <TextField
                name="correlativo"
                label="Correlativo"
                value={values.correlativo}
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                error={
                  touched.correlativo &&
                  errors.correlativo &&
                  Boolean(errors.correlativo)
                }
                helperText={
                  touched.correlativo && errors.correlativo
                    ? errors.correlativo
                    : "Correlativo, usado para los código de contrato Ej. GMI-(correlativo)-xxxxx, en caso de ser un responsable sin codigo correlativo asignado por GAF ingrese -1"
                }
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              onClick={handle_limpiar}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              Limpiar
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Agregar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  );
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default Page;
