import {  ButtonBase } from "@material-ui/core";
import React from "react";
const BarraAvance = (props) => {
  const { color, completed, width = "80px", onClick } = props;

  const containerStyles = {
    height: 20,
    width: width,
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    display: "flex",
    gap: "10px 20px",
    flexDirection: "column",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: color,
    borderRadius: "inherit",
    textAlign: "center",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    alignItems: "center",
  };
  const fillerStylesBtn = {
    height: "100%",
    width: `100%`,
    borderRadius: "inherit",
    textAlign: "center",
    flexDirection: "column",
    display: "flex",
  };

  const labelStyles = {
    color: "white",
    fontWeight: "bold",
  };

  return (
    <ButtonBase
      onClick={onClick}
      style={{ display: "flex", flexDirection: "column", gap: "5px" }}
    >
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
      <div style={fillerStylesBtn}>
        <span>Ver Avance</span>
      </div>
    </ButtonBase>
  );
};

export default BarraAvance;
