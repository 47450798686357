import React, { useState } from "react";
import { Container, CardHeader, CardContent, Tabs, Grid, Card, Tab, makeStyles } from "@material-ui/core";
import ListadoRoles from "./roles/listadoRoles";
import ListadoPerfiles from "./perfiles/listadoPerfiles";
import ListadoPermisos from "./permisos/listadoPermisos";
import TabUsuariosPlataforma from "./usuarios/listadoUsuarios";
const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1, 1, 3, 1)
  },
  center: {
    textAlign: "center",
  },
  main: {
    overflow: "auto",
    maxWidth: "95vw"
  },
  card: {
    marginBottom: theme.spacing(2)
  }

}));
function Page(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  return (
    <Container className={classes.main}>
      <Card>
        <CardHeader
          titleTypographyProps={{
            align: "center"
          }}
          title="Configuración"
        />
        <CardContent>
          <Grid container>
            <Grid item lg>
              <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} aria-label="content-tabs">
                <Tab label="Roles" id="tabRoles" aria-controls="panelRoles" />
                <Tab label="Perfiles" id="tabPerfiles" aria-controls="panelPerfiles" />
                <Tab label="Permisos" id="tabPermisos" aria-controls="panelPermisos" />
                <Tab label="Usuarios Especiales" id="tabPermisos" aria-controls="panelPermisos" />
                <Tab label="Notificaciones" id="tabPermisos" aria-controls="panelPermisos" />
              </Tabs>
            </Grid>
          </Grid>
          {selectedTab === 0 && <div id="panelRoles"><ListadoRoles/></div>}
          {selectedTab === 1 && <div id="panelPerfiles"><ListadoPerfiles/></div>}
          {selectedTab === 2 && <div id="panelPermisos"><ListadoPermisos/></div>}
          {selectedTab === 3 && <div id="panelPermisos"><TabUsuariosPlataforma/></div>}
          
        </CardContent>
      </Card>
    </Container>
  );
}

export default Page;