import axios from "axios";
import { useEffect, useState } from "react";

import { obtenerUsuariosResponsables } from "../requestResponsable";

export const useResponsable = (texto) => {
  const [UsuariosResponsables, SetUsuariosResponsables] = useState();
  const [loadResponsables, setLoadResponsable] = useState(false);

  useEffect(() => {
    if (texto.length ?? 0 >= 3) {
      setLoadResponsable(true);
      obtenerUsuariosResponsables()
        .then((usuariosResponsables) => {
          setLoadResponsable(false);
          SetUsuariosResponsables(usuariosResponsables);
        })
        .catch((error) => {
          console.error(error);
          SetUsuariosResponsables([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texto]);

  return {
    UsuariosResponsables,
    loadResponsables,
  };
};

export const useUsuarios = (texto, cancelToken = null) => {
  const [UsuariosResponsables, SetUsuariosResponsables] = useState();
  const [loadResponsables, setLoadResponsable] = useState(false);

  useEffect(() => {
    setLoadResponsable(true);
    axios
      .get("https://api-usuarios.cydocs.cl/personas?is_gsuite_talana=true", {
        cancelToken: cancelToken.token,
      })
      .then((result) => {
        let usuariosAux = result.data.map(user => {
          return {
            ...user,
            photoUrl: user.photo,
            uid: user.usuario_id
          }
        })
        SetUsuariosResponsables(usuariosAux);
        setLoadResponsable(false);
      })
      .catch((error) => {
        setLoadResponsable(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    UsuariosResponsables,
    loadResponsables,
  };
};
