import React, { useEffect } from 'react';
import { Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import { useUsuarios } from "../../containers/responsables/nuevo/hooksPersonas";
import SeccionView from '../../containers/contratos/nuevov3/components/SeccionView';
import ViewAprobador from '../../containers/contratos/detalle/view_aprobador';
import SelectorUsuarioForm from './SelectorUsuarioForm';

function AprobadoresContratoForm(props) {
  const { formData } = props;
  const [options, setOptions] = React.useState([]);
  const [responsablesFlujo, setResponsablesFlujo] = React.useState(formData.aprobadores);
  let cancelToken = axios.CancelToken.source();
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    null,
    cancelToken
  );

  useEffect(() => {
    if (typeof UsuariosResponsables === "undefined") {
      setOptions([]);
    } else {
      if (UsuariosResponsables.length === 0) {
        setOptions([]);
      } else {
        setOptions(UsuariosResponsables);
      }
    }
  }, [UsuariosResponsables]);

  const handleChangeAprobador = (index, value) => {
    let aux = responsablesFlujo;
    if (value !== null) {
      let valAux = {
        nombre: value.nombre_abreviado,
        nombre_abreviado: value.nombre_abreviado,
        apellido_materno: value.apellido_materno,
        apellido_paterno: value.apellido_paterno,
        nombre_completo: value.nombre_completo,
        email: value.email
          ? value.email
          : value.contacto.email,
        photoUrl: value.photo,
        uid: value.auth_id,
        rut: value.run,
        cargo: value.cargo,
      }
      aux[index] = valAux
    } else {
      aux[index] = null
    }
    setResponsablesFlujo(responsablesFlujo);
    props.onChange({ aprobadores: aux });
  }

  return (
    !props.disabled ?
      <Grid container spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Grid item xs={12} xl={12}>
          <SeccionView
            borderColor="#731f1f"
            nombreSeccion="Aprobadores Rendiciones"
          >
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item xs={3} xl={3}>
                <ViewAprobador
                  aprobador={formData.aprobadores[0]}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={3} xl={3}>
                <ViewAprobador
                  aprobador={formData.aprobadores[1]}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={3} xl={3}>
								<ViewAprobador
									aprobador={formData.aprobadores[2]}
								/>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={2} xl={2}>
								<ViewAprobador
									aprobador={formData.aprobadores[3]}
								/>
              </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item xs={3} xl={3}>
                < SelectorUsuarioForm
                  label={"aprobador 1"}
                  nameField="aprobador_1"
                  index={0}
                  data={options}
                  onChange={handleChangeAprobador}
                  loadingData={loadResponsables}
                  valueInit={formData?.aprobadores[0] || null}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={3} xl={3}>
                <SelectorUsuarioForm
                  label={"aprobador 2"}
                  nameField="aprobador_2"
                  index={1}
                  onChange={handleChangeAprobador}
                  data={options}
                  loadingData={loadResponsables}
                  valueInit={formData?.aprobadores[1] || null}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={3} xl={3}>
                <SelectorUsuarioForm
                  label={"aprobador 3"}
                  nameField="aprobador_3"
                  index={2}
                  data={options}
                  onChange={handleChangeAprobador}
                  loadingData={loadResponsables}
                  valueInit={formData?.aprobadores[2] || null}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
              <Grid item xs={2} xl={2}>
                <SelectorUsuarioForm
                  label={"aprobador 4"}
                  nameField="aprobador_4"
                  index={3}
                  data={options}
                  onChange={handleChangeAprobador}
                  loadingData={loadResponsables}
                  valueInit={formData?.aprobadores[3] || null}
                />
              </Grid>
            </Grid>
          </SeccionView>
        </Grid>
      </Grid> : <></>
  )
}

export default AprobadoresContratoForm;