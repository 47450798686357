import React, { Fragment } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

function Page(props) {
  const { handle_limpiar, formik } = props;

  const classes = useStyle();
  const {
    values,
    handleChange,
    submitForm,
    handleSubmit,
    errors,
    touched,
  } = formik;

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Sociedades</Typography>
        <Link to="/sociedades">Sociedades</Link>
        <Typography color="textPrimary">Ingresar Sociedad</Typography>
      </Breadcrumbs>
      <Paper className={classes.card}>
        <form onSubmit={handleSubmit}>
          {/* CAMPOS DE LA SOCIEDAD */}
          <Grid container spacing={3}>
            <Grid item xs={6} xl={4}>
              {/* CÓDIGO */}
              <TextField
                name="codigo"
                label="Código"
                value={values.codigo}
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                error={
                  touched.codigo && errors.codigo && Boolean(errors.codigo)
                }
                helperText={
                  touched.codigo && errors.codigo ? errors.codigo : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* RUT */}
              <TextField
                name="rut"
                label="RUT"
                value={values.rut}
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                error={touched.rut && errors.rut && Boolean(errors.rut)}
                helperText={touched.rut && errors.rut ? errors.rut : ""}
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* NOMBRE */}
              <TextField
                name="nombre"
                label="Nombre"
                value={values.nombre}
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                error={
                  touched.nombre && errors.nombre && Boolean(errors.nombre)
                }
                helperText={
                  touched.nombre && errors.nombre ? errors.nombre : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* GIRO */}
              <TextField
                name="giro"
                label="Giro"
                value={values.giro}
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                error={touched.giro && errors.giro && Boolean(errors.giro)}
                helperText={touched.giro && errors.giro ? errors.giro : ""}
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* TÉLEFONO */}
              <TextField
                name="contacto.telefono"
                label="Teléfono"
                value={values.contacto.telefono}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.contacto &&
                  touched.contacto.telefono &&
                  errors.contacto &&
                  errors.contacto.telefono &&
                  Boolean(errors.contacto.telefono)
                }
                helperText={
                  touched.contacto &&
                  touched.contacto.telefono &&
                  errors.contacto &&
                  errors.contacto.telefono
                    ? errors.contacto.telefono
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* TÉLEFONO ALTERNATIVO */}
              <TextField
                name="contacto.telefono_alternativo"
                label="Teléfono Alternativo"
                value={values.contacto.telefono_alternativo}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.contacto &&
                  touched.contacto.telefono_alternativo &&
                  errors.contacto &&
                  errors.contacto.telefono_alternativo &&
                  Boolean(errors.contacto.telefono_alternativo)
                }
                helperText={
                  touched.contacto &&
                  touched.contacto.telefono_alternativo &&
                  errors.contacto &&
                  errors.contacto.telefono_alternativo
                    ? errors.contacto.telefono_alternativo
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* FAX */}
              <TextField
                name="contacto.fax"
                label="Fax"
                value={values.contacto.fax}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.contacto &&
                  touched.contacto.fax &&
                  errors.contacto &&
                  errors.contacto.fax &&
                  Boolean(errors.contacto.fax)
                }
                helperText={
                  touched.contacto &&
                  touched.contacto.fax &&
                  errors.contacto &&
                  errors.contacto.fax
                    ? errors.contacto.fax
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* DIRECCIÓN */}
              <TextField
                name="ubicacion.direccion"
                label="Dirección"
                value={values.ubicacion.direccion}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.ubicacion &&
                  touched.ubicacion.direccion &&
                  errors.ubicacion &&
                  errors.ubicacion.direccion &&
                  Boolean(errors.ubicacion.direccion)
                }
                helperText={
                  touched.ubicacion &&
                  touched.ubicacion.direccion &&
                  errors.ubicacion &&
                  errors.ubicacion.direccion
                    ? errors.ubicacion.direccion
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* COMUNA */}
              <TextField
                name="ubicacion.comuna"
                label="Comuna"
                value={values.ubicacion.comuna}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.ubicacion &&
                  touched.ubicacion.comuna &&
                  errors.ubicacion &&
                  errors.ubicacion.comuna &&
                  Boolean(errors.ubicacion.comuna)
                }
                helperText={
                  touched.ubicacion &&
                  touched.ubicacion.comuna &&
                  errors.ubicacion &&
                  errors.ubicacion.comuna
                    ? errors.ubicacion.comuna
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={4}>
              {/* REGIÓN */}
              <TextField
                name="ubicacion.region"
                label="Región"
                value={values.ubicacion.region}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.ubicacion &&
                  touched.ubicacion.region &&
                  errors.ubicacion &&
                  errors.ubicacion.region &&
                  Boolean(errors.ubicacion.region)
                }
                helperText={
                  touched.ubicacion &&
                  touched.ubicacion.region &&
                  errors.ubicacion &&
                  errors.ubicacion.region
                    ? errors.ubicacion.region
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              onClick={handle_limpiar}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              Limpiar
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Agregar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  );
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default Page;
