import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  ACTUALIZAR_CONTRATO,
  ACTUALIZAR_CONTRATO_BASICO,
  ACTUALIZAR_CONTRATO_ENCUESTA,
} from "../../../constants/permissions";
import { Link, useHistory } from "react-router-dom";

import { CERRADO, EN_PROCESOS_DE_CIERRE } from "../../../constants/estados";

import FileDropUpload from "./file_drop_upload";

import schema from "./schema.json";

import {
  MultipleSelector,
  SelectItem,
  TextFieldCyd,
} from "../../adminProcesoEliminacion/add";
import ObjectFieldTemplate from "../../adminProcesoEliminacion/FormCyd";
import { obtenerGerencias, obtenerResponsables, obtenerMandantes, obtenerSociedades, actualizarContrato } from "../requestContrato";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";

import SelectWidgetCyd from "../../../components/form/SelectWidget"
import DateTimeWidgetCyd from "../../../components/form/DatePickerCyd"
import SelectorGenerico from "../../../components/form/SelectorGenerico";
import SelectorResponsable from "../../../components/form/SelectorResponsable";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AprobadoresContratoForm from "../../../components/form/AprobadoresContratoForm";



const Form = withTheme(MaterialUITheme);



function Detalle(props) {
  const {
    onCancel,
    onFormChanged,
    onError,
    submitForm,
    validation,
    gerencias,
    sociedades,
    mandantes,
    handle_salir,
    formik,
    usuario,
    // cambioDeCorrelatico,
    contrato,
  } = props;



  const classes = useStyle();

  const [formData, setFormData] = React.useState(contrato);
  const [responsables, SetResponsables] = useState(null);
  const [cambioDeCorrelatico, setCambioDeCorrelatico] = useState(null);

  const [options, setOptions] = React.useState([]);


  const summit = async (value, callback) => {
    console.log(value.formData);
    let isValid = await validation.isValid(formData).catch((err) =>
      console.error(
        err.name, // => 'ValidationError'
        err.errors // => [{ key: 'field_too_short', values: { min: 18 } }]
      )
    );

  };

  const history = useHistory();
  const notistack = useSnackbar()
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  /**
  * Handler para actualizar el contrato.
  */
  const handleActualizar = (values) => {
    //Actualización del código del contrato.
    console.log(values)
    if (cambioDeCorrelatico) {
      if (contrato.is_centro_costo) {
        values.codigo = `${values.codigo_nuevo.gerencia}-${values.codigo_nuevo.responsable}`;
      } else {
        values.codigo = `${values.codigo_nuevo.gerencia}-${values.codigo_nuevo.responsable}-${values.codigo_nuevo.correlativo}`;
      }
    }
    values.is_eliminado = values.estado === CERRADO ? true : false

    // Se actualiza el contrato.
    actualizarContrato(values, usuarioSesion)
      .then((response) => {
        console.log("Contrato actualizado exitosamente.", response);
        let mensajeExito = notistack.enqueueSnackbar(
          "Contrato actualizado exitosamente.",
          {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .catch((error) => {
        console.error("Error al intentar actualizar el contrato.", error);
        let mensajeError = notistack.enqueueSnackbar(
          "Error al intentar actualizar el contrato.",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .finally(() => {
        history.push("/contratos");
      });
  };


  useEffect(() => {

    setFormData(contrato);
  }, [contrato]);

  useEffect(() => {
    obtenerResponsables().then((result => {
      SetResponsables(result)
    }))
  }, []);

  const [openUploadFile, setOpenUploadFile] = React.useState(false);

  const handleClickOpen = () => {

    setOpenUploadFile(true);
  };

  const handleClose = () => {
    setOpenUploadFile(false);
  };


  const permisos = Array.from(usuario.permisos);

  const permisoActualizarBasico = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
  );
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO
  );

  const permisoActualizarEncuesta = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_ENCUESTA
  );

  if (!gerencias || !responsables || !sociedades || !mandantes) {
    //Si aún no cargan los datos.
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  function transformErrors(errors) {
    console.log(errors);
    return errors.map((error) => {
      if (error.name === "pattern") {
        error.message = "Only digits are allowed";
      }
      return error;
    });
  }
  function validate(formData, errors) {

    //let isValid = await validation.validateSync(formData); //.catch((err)=> console.error(
    //err.name, // => 'ValidationError'
    //err.errors // => [{ key: 'field_too_short', values: { min: 18 } }]
    //));
    //console.log("validae =>", isValid);
    return errors;
    if (formData.pass1 !== formData.pass2) {
      errors.pass2.addError("Passwords don't match");
    }
    return errors;
  }

  const widgets = {
    customTextField: TextFieldCyd,
    TextWidget: TextFieldCyd,
    selectItem: SelectItem,
    MultipleSelector: MultipleSelector,
    SelectorGenerico: SelectorGenerico,
    SelectorResponsable: SelectorResponsable,
    SelectWidget: SelectWidgetCyd,
    DateTimeWidgetCyd: DateTimeWidgetCyd,
    AprobadoresContratoForm: AprobadoresContratoForm
  };
  const fields = { aprobadores_factura: AprobadoresContratoForm };
  let yourForm;


  return (
    responsables ?
      <Fragment>
        <Breadcrumbs separator=">">
          <Typography color="textPrimary">Módulo Contratos</Typography>
          <Link to="/contratos">Contratos</Link>
          <Typography color="textPrimary">{`Modificar Contrato ${contrato.codigo}`}</Typography>
        </Breadcrumbs>
        <Paper className={classes.card}>
          {/* CODIGO ACTUAL Y NUEVO */}
          <Grid container spacing={3}>
            <Grid item xs={contrato.is_eliminado ? 12 : 6}>
              {/* CODIGO ACTUAL */}
              <Typography variant="h6" align="center">
                {"Código Actual"}
              </Typography>
              <Typography variant="h4" align="center">
                {contrato.codigo}
              </Typography>
            </Grid>
            {!contrato.is_eliminado ? (
              <Grid item xs={6}>
                {/* CODIGO NUEVO */}
                <Typography variant="h6" align="center">
                  {"Código Nuevo"}
                </Typography>
                <Typography variant="h4" align="center">
                  {cambioDeCorrelatico
                    ? (contrato.is_centro_costo ? `${formData.codigo_nuevo.gerencia}-${formData.codigo_nuevo.responsable}` : `${formData.codigo_nuevo.gerencia}-${formData.codigo_nuevo.responsable}-${formData.codigo_nuevo.correlativo}`)
                    : contrato.codigo}
                </Typography>
              </Grid>
            ) : (
              <Container></Container>
            )}
          </Grid>
          {/* CAMPOS DEL CONTRATO */}
          <Container style={{ paddingTop: "50px" }} maxWidth="xl">
            <Form
              method="post"
              action="submit"
              schema={schema}
              fields={fields}
              formData={formData}
              onCancel={onCancel}
              onSubmit={summit}
              validate={validate}
              onChange={(data) => {
                let filtro = null;
                let codigoAux = {
                  ...data.formData
                }
                try{
                  codigoAux.codigo_nuevo = {
                    gerencia: data.formData.gerencia_ref.sigla,
                    responsable: data.formData.responsable_ref.correlativo ? data.formData.responsable_ref.correlativo : " ",
                    correlativo: data.formData.codigo.split("-")[2],
                  }
                }catch(err){
                  codigoAux.codigo_nuevo={
                    gerencia: data.formData.gerencia_ref.sigla,
                    responsable: "",
                    correlativo: "",
                  }
                }
                


                // codigoAux.responsable_ref = "";
                // codigoAux.responsable_admin_ref = "";
                filtro = `gerencia=${data.formData.gerencia_ref.sigla}`;
                setCambioDeCorrelatico(data.formData.cambioCorrelativo)
                setFormData(codigoAux);

                filtro = `gerencia=${data.formData.gerencia_ref.sigla}`;
                obtenerResponsables(filtro)
                  .then((responsables) => {
                    if (responsables) {
                      SetResponsables(responsables);
                    } else {
                      SetResponsables([]);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                    SetResponsables([]);
                  });
              }}
              onError={onError}
              widgets={widgets}
              showErrorList={false}
              omitExtraData={false}
              required={false}
              noValidate={false}
              noHtml5Validate={true}
              ref={(form) => {
                yourForm = form;
              }}
              transformErrors={transformErrors}
              uiSchema={{
                "ui:ObjectFieldTemplate": ObjectFieldTemplate,

                aprobadores_factura: {
                  "ui:widget": AprobadoresContratoForm,
                  "ui:field": "aprobadores_factura",
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false,
                  "ui:options": {
                    "col": {
                      xs: 12,
                      md: 12,
                      xl: 12
                    },

                  },
                },
                "ui:submitButtonOptions": {
                  "norender": true,
                },

                "aplicaEncuesta": {
                  "ui:widget": "radio",
                  "ui:disabled": permisoActualizarEncuesta
                    ? false
                    : true
                },
                is_centro_costo: {
                  "ui:widget": "radio",
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false
                },
                cambioCorrelativo: {
                  "ui:widget": "radio",
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false
                },
                correlativo: {
                  "ui:disabled": true,
                  "ui:readonly": true,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false
                },
                estado: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false
                },
                tipo_de_servicio: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                  "uid:options": {
                    "variant": "outlined"
                  },
                },
                nombre: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false,
                },
                region: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                comuna: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                direccion: {
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },

                responsable_ref: {
                  "ui:widget": SelectorResponsable,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                  "ui:options": {
                    labelOption: "persona.nombre_abreviado_custom",
                    datos: responsables,
                    gerencia: formData.gerencia_ref

                  },
                },
                responsable_admin_ref: {
                  "ui:widget": SelectorResponsable,
                  "ui:options": {
                    labelOption: "persona.nombre_abreviado_custom",
                    datos: responsables,
                    gerencia: formData.gerencia_ref
                  },
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                fecha_adjudicacion: {
                  "ui:widget": DateTimeWidgetCyd,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                fecha_termino_estimada: {
                  "ui:widget": DateTimeWidgetCyd,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                fecha_inicio: {
                  "ui:widget": DateTimeWidgetCyd,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? false
                      : true,
                },
                mandante_ref: {
                  "ui:widget": SelectorGenerico,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false,
                  "ui:options": {
                    labelOption: "label",
                    datos: mandantes,
                    fetch: obtenerMandantes,
                  },
                },
                sociedad_ref: {
                  "ui:widget": SelectorGenerico,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false,
                  "ui:options": {
                    labelOption: "label",
                    datos: sociedades,
                    fetch: obtenerSociedades,
                  },
                },
                gerencia_ref: {
                  "ui:widget": SelectorGenerico,
                  "ui:disabled": permisoActualizar
                    ? false
                    : permisoActualizarBasico
                      ? true
                      : false,
                  "ui:options": {
                    labelOption: "label",
                    datos: gerencias,
                    fetch: obtenerGerencias,
                  },
                },
              }}
            />
          </Container>
          {/* <AprobadoresContrato contrato={contrato} formik={formik} form={yourForm} /> */}
          {/* <Grid container spacing={3} style={{ paddingLeft: 14, paddingRight: 14 }}>
            <Grid item xs={12} xl={12}>
              <SeccionView
                borderColor="#731f1f"
                nombreSeccion="Documentos de adjudicación "
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 1"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 2"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 3"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivoUpload onCickItem={handleClickOpen} />
                  </Grid>
                </Grid>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<MailOutlineIcon />}
                >
                  Solicitar documentos
                </Button>
              </SeccionView>
            </Grid>
          </Grid> */}

          {/* CAMPOS DEL CONTRATO */}
          <Box display="flex" flexDirection="row-reverse">
            <Button
              onClick={handle_salir}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              Salir
            </Button>
            <Button
              onClick={() => {



                handleActualizar(yourForm.state.formData)
                yourForm.submit();
              }}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Actualizar
            </Button>
          </Box>

          <FileDropUpload
            contrato={contrato}
            handleClose={handleClose}
            open={openUploadFile}
          />
        </Paper>
      </Fragment> : <></>

  );
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderRadius: 15,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 25,
  },
}));







export default Detalle;
