import React, { useEffect, useState } from "react";
import Page from "./page";
import {
  obtenerSolicitudContratosByID,
  obtenerGerencias,
  obtenerResponsables,
  obtenerSociedades,
  obtenerComunas,
  obtenerRegiones,
  obtenerMandantes,
  obtenerContratos,
  actualizarSolicitudContrato,
} from "../../solicitud/requestSolicitud";
import * as ROUTES from "../../../constants/routes";

import { agregarContrato } from "../requestContrato";
import { useHistory, useParams } from "react-router-dom";
import { date, object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import Moment from "moment";
import Axios from "axios";

function Index(props) {
  const [Contrato, SetContrato] = useState(null);
  const [Correlativo, SetCorrelativo] = useState();
  const [Correlativos, SetCorrelativos] = useState();
  const [isCentroCosto, setIsCentroCosto] = useState(false);
  const [Gerencias, SetGerencias] = useState();
  const [Responsables, SetResponsables] = useState();
  const [Sociedades, SetSociedades] = useState();
  const [Mandantes, SetMandantes] = useState();
  const [cambioDeCorrelatico, setCambioDeCorrelatico] = useState(false);

  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);

  const { id: contratoID } = useParams();
  const history = useHistory();
  const notistack = useSnackbar();

  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  useEffect(() => {
    
    if (contratoID) {
      Promise.all([
        obtenerSolicitudContratosByID(contratoID),//0
        obtenerGerencias(),//1
        obtenerResponsables(),//2
        obtenerSociedades(),//3
        obtenerMandantes(),//4
        obtenerContratos(),//5
        obtenerComunas(),//6
        obtenerRegiones(),//7
        
      ])
        .then((responses) => {
          console.log(responses[0]);
          setComunas(responses[6]);
          setRegiones(responses[7]);
          SetCorrelativos(responses[5].map((c) => c.codigo.split("-")[2]));
          SetContrato(responses[0]);
          SetGerencias(responses[1]);
          SetResponsables(responses[2]);
          SetSociedades(responses[3]);
          SetMandantes(responses[4]);
        })
        .catch((error) => {
          
          SetContrato({});
          SetGerencias([]);
          SetResponsables([]);
          SetSociedades([]);
          SetMandantes([]);
        });
    } else {
      Promise.all([
        obtenerGerencias(),
        obtenerResponsables(),
        obtenerSociedades(),
        obtenerMandantes(),
        obtenerComunas(),
        obtenerRegiones(),
        obtenerContratos(),
      ])
        .then((responses) => {
          console.log(responses[5]);
          setComunas(responses[4]);
          setRegiones(responses[5]);
          SetCorrelativos(responses[6].map((c) => c.codigo.split("-")[2]));
          SetGerencias(responses[0]);
          SetResponsables(responses[1]);
          SetSociedades(responses[2]);
          SetMandantes(responses[3]);
        })
        .catch((error) => {
          console.log(error);
          SetContrato({});
          SetGerencias([]);
          SetResponsables([]);
          SetSociedades([]);
          SetMandantes([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
  }, []);

  useEffect(() => {
    if (Correlativos) {
      let nuevoCorrelativo = ObtenerNuevoCorrelativo(Correlativos);
      SetCorrelativo(nuevoCorrelativo);
    }
  }, [Correlativos]);

  /**
   * Valores iniciales del formulario.
   */
  const initials = {
    _id: Contrato ? Contrato._id : "",
    nombre: Contrato ? Contrato.nombre : "",
    codigo_ref: Contrato ? Contrato.codigo : "",
    codigo: {
      gerencia: Contrato ? Contrato.codigo.split("-")[0] : "",
      responsable: Contrato ? Contrato.codigo.split("-")[1] : "",
      correlativo: Correlativo ? Correlativo : "",
    },
    estado: Contrato ? "Activo" : "",
    fecha_inicio:
      Contrato && Contrato.fecha_inicio ? Contrato.fecha_inicio : null,
    fecha_termino_estimada:
      Contrato && Contrato.fecha_termino_estimada
        ? Contrato.fecha_termino_estimada
        : null,
    fecha_termino_real:
      Contrato && Contrato.fecha_termino_real
        ? Contrato.fecha_termino_real
        : null,
    responsable_ref:
      Contrato && Contrato.responsable_ref ? Contrato.responsable_ref._id : "",
    responsable_admin_ref:
      Contrato && Contrato.responsable_admin_ref
        ? Contrato.responsable_admin_ref._id
        : "",
    aprobadores_factura: {
      aprobadores: [
        {
          nombre:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_abreviado
              : "",
          nombre_abreviado:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_abreviado
              : "",
          apellido_materno:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.apellido_materno
              : "",
          apellido_paterno:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.apellido_paterno
              : "",
          nombre_completo:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_completo
              : "",
          email:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.contacto.email
              : "",
          photoUrl:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.photo
              : "",
          uid:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.usuario_id
              : "",
          rut:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.run
              : "",
          cargo:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.cargo
              : "",
        },
        {
          nombre:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_abreviado
              : "",
          nombre_abreviado:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_abreviado
              : "",
          apellido_materno:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.apellido_materno
              : "",
          apellido_paterno:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.apellido_paterno
              : "",
          nombre_completo:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.nombre_completo
              : "",
          email:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.contacto.email
              : "",
          photoUrl:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.photo
              : "",
          uid:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.usuario_id
              : "",
          rut:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.run
              : "",
          cargo:
            Contrato && Contrato.responsable_ref
              ? Contrato.responsable_ref.persona.cargo
              : "",
        },
      ],
    },
    gerencia_ref:
      Contrato && Contrato.gerencia_ref ? Contrato.gerencia_ref._id : "",
    mandante_ref:
      Contrato && Contrato.mandante_ref ? Contrato.mandante_ref._id : "",
    sociedad_ref:
      Contrato && Contrato.sociedad_ref ? Contrato.sociedad_ref._id : "",
    fecha_adjudicacion:
      Contrato && Contrato.fecha_adjudicacion
        ? Contrato.fecha_adjudicacion
        : null,
    region: Contrato ? Contrato.region : "",
    comuna: Contrato ? Contrato.comuna : "",
    direccion: Contrato ? Contrato.direccion : "",
    monto_del_contrato: Contrato ? Contrato.monto_del_contrato : "",
  };

  /**
   * Esquema de validación para el contrato.
   */
  const validation = object().shape({
    nombre: string()
      .min(3, "El nombre debe tener al menos 3 caracteres.")
      .max(70, "El nombre debe tener a lo más 70 caracteres.")
      .required("El nombre es requerido."),
    codigo: string()
      .min(3, "El código debe tener al menos 3 caracteres.")
      .max(15, "El código debe tener a lo más 15 caracteres.")
      .required("El código es requerido."),
    estado: string().required("El estado es requerido."),
    fecha_inicio: date().nullable().optional(),
    fecha_termino_estimada: date()
      .nullable()
      .required("La fecha estima de término es requerida."),
    fecha_termino_real: date().nullable().optional(),
    // .required("La fecha de término real es requerida."),
    responsable_ref: string().required("El responsable es requerido."),
    responsable_admin_ref: string().required("El responsable es requerido."),
    gerencia_ref: string().required("La gerencia es requerida."),
    mandante_ref: string().required("El mandante es requerido."),
    sociedad_ref: string().required("La sociedad es requerida."),
  });

  /**
   * Configuración de Formik Hook.
   */
  const formik = useFormik({
    initialValues: initials,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, helper) => handleAgregar(values),
  });

  useEffect(() => {
    let filtro = null;
    formik.values.responsable_ref = "";
    formik.values.responsable_admin_ref = "";
    if (formik && formik.values && formik.values.gerencia_ref) {
      let gerenciaID = formik.values.gerencia_ref;
      let gerencia = Array.from(Gerencias).find((g) => g._id === gerenciaID);
      filtro = `gerencia=${gerencia.sigla}`;
    }
    obtenerResponsables(filtro)
      .then((responsables) => {
        if (responsables) {
          SetResponsables(responsables);
        } else {
          SetResponsables([]);
        }
      })
      .catch((error) => {
        console.error(error);
        SetResponsables([]);
      });
  }, [formik.values.gerencia_ref]);

  useEffect(() => {
    console.log("cambio el codigo");
    //SetCorrelativo(formik.values.codigo_ref)
  }, [formik.values.codigo_ref]);

  /**
   * Handler para agregar contrato.
   */
  const handleAgregar = (values) => {
    let correlativo = values.codigo.correlativo
      ? values.codigo.correlativo
      : Correlativo;
    if (values.fecha_termino_estimada === null) {
      //Si no se asigno una fecha estimada de término, se asigna 31/12/2099 para indefinido.
      values.fecha_termino_estimada = Moment("31/12/2099", "MM-DD-YYYY");
    }
    let check = Array.from(Correlativos).includes(correlativo);
    if (check) {
      //Error en caso de correlativo repetido.
      let mensajeCorrelativoRepetido = notistack.enqueueSnackbar(
        "El correlativo ingresado ya está siendo utilizado.",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          action: (
            <IconButton
              onClick={() =>
                notistack.closeSnackbar(mensajeCorrelativoRepetido)
              }
            >
              <Close />
            </IconButton>
          ),
        }
      );
    } else {
      //Se construye y asigna el código de contrato.

      values.codigo = isCentroCosto
        ? `${values.codigo.gerencia}-${values.codigo.correlativo}`
        : `${values.codigo.gerencia}-${values.codigo.responsable}-${correlativo}`;
      values.is_centro_costo = isCentroCosto;
      //Se agrega el contrato.
      let idSolicitud = values._id;
      delete values._id;
      delete values.codigo_ref;
      console.log(usuarioSesion);
      values.ingresado_por = usuarioSesion;

      values.is_eliminado = false;
      console.log(values);

      agregarContrato(values)
        .then((response) => {
          console.log("Contrato agregado exitosamente.", response);
          let mensajeExito = notistack.enqueueSnackbar(
            "Contrato agregado exitosamente.",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              action: (
                <IconButton
                  onClick={() => notistack.closeSnackbar(mensajeExito)}
                >
                  <Close />
                </IconButton>
              ),
            }
          );

					//Llamado a método encargado de agregar los datos del contrato a SAP.
					AgregarContratoSAP(response);
          
					if (idSolicitud) {
            return actualizarSolicitudContrato({
              _id: idSolicitud,
              is_eliminado: true,
              estado: "ingresado",
              codigo: response.codigo,
            });
          } else {
            return new Promise((resolve, reject) => {
              resolve(true);
            });
          }
        })
        .then((result) => {
          console.log("Solicitud Contrato agregado exitosamente.", result);
        })
        .catch((error) => {
          console.error("Error al intentar agregar el contrato.", error);
          let mensajeError = notistack.enqueueSnackbar(
            "Error al intentar agregar el contrato.",
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              action: (
                <IconButton
                  onClick={() => notistack.closeSnackbar(mensajeError)}
                >
                  <Close />
                </IconButton>
              ),
            }
          );
        })
        .finally(() => {
          history.push(ROUTES.LANDING);
        });
    }
  };

  /**
   * Handler para salir del formulario.
   */
  const handleSalir = () => {
    history.push("/contratos");
  };

  const handleCambioDeCorrelatico = (valor) => {
    console.log(valor.target.checked);
    setCambioDeCorrelatico(valor.target.checked);
  };

  const handleCentroCosto = (value) => {
    setIsCentroCosto(value.target.checked);
  };

  return (
    <Page
      correlativo={Correlativo}
      isCentroCosto={isCentroCosto}
      gerencias={Gerencias}
      responsables={Responsables}
      usuario={usuarioSesion}
      sociedades={Sociedades}
      mandantes={Mandantes}
      handle_salir={handleSalir}
      formik={formik}
      cambioDeCorrelatico={cambioDeCorrelatico}
      onChangeCambioDeCorrelativo={handleCambioDeCorrelatico}
      onChangeCentroCosto={handleCentroCosto}
      comunas={comunas}
      regiones={regiones}
    />
  );
}

/**
 * Método encargado de estimar el nuevo correlativo para el contrato.
 * @param {*} contratos
 */
function ObtenerNuevoCorrelativo(contratos) {
  //Se obtienen los códigos de los contratos, se separa por "-" y se obtiene el numero.
  let correlativos = Array.from(contratos).map((c) => Number(c));
  //Se calcula el mayor.
  let ultimoCorrelativo = Math.max.apply(null, correlativos);
  //Se retorna el siguiente del mayor.
  return ultimoCorrelativo + 1;
}

/**
 * Método encargado de agregar un contrato en SAP.
 * @param {*} contrato Datos del contrato.
 * @returns Response.
 */
async function AgregarContratoSAP(contrato) {
  try {
    let contratoSAP = {
      Code: contrato.codigo,
      Name: contrato.nombre,
      ValidFrom: contrato.fecha_inicio,
      ValidTo: contrato.fecha_termino_estimada,
      Active: "tYES",
    }

		const SAP_URL = "https://api-sap.cydocs.cl";
    let response = await Axios.post(SAP_URL, contratoSAP);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default Index;
