import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerMandantes, eliminarMandante } from "../requestMandante";
import XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Mandantes, SetMandantes] = useState();
	const [DialogOpen, SetDialogOpen] = useState(false);
	const [ItemSeleccionado, SetItemSeleccionado] = useState(null);
	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerMandantes()
			.then(mandantes => SetMandantes(mandantes))
			.catch(error => SetMandantes([]));
	}, []);

	const handleShowDialog = (mandanteID) => {
		SetDialogOpen(true);
		SetItemSeleccionado(mandanteID);
	}

	/**
	 * Handler para eliminar un mandante.
	 */
	const handleEliminar = () => {
		eliminarMandante(ItemSeleccionado)
			.then(response => {
				console.log("Mandante eliminado exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Mandante eliminado exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar eliminar el mandante", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar eliminar el mandante", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				SetDialogOpen(false);
				SetItemSeleccionado(null);
			});
	}

	/**
	 * Handler para cancelar la eliminación.
	 */
	const handleCancelarEliminar = () => {
		SetDialogOpen(false);
		SetItemSeleccionado(null);
	}

	/**
	 * Hanlder para exportar datos con formato Excel.
	 */
	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Mandantes.map(m => ({
			nombre: m.nombre,
			rut: m.rut_formateado,
			responsable: m.responsable_actual ? m.responsable_actual.nombre_completo : "Sin Responsable",
			tipo: m.tipo,
			giro: m.giro,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Mandantes");
		XLSX.writeFile(wb, "mandantes.xlsx");
	}

	return (
		<Page
			usuario={usuarioSesion}
			mandantes={Mandantes}
			dialog_open={DialogOpen}
			handle_show_dialog={handleShowDialog}
			handle_eliminar={handleEliminar}
			handle_cancelar={handleCancelarEliminar}
			handle_export={handleExport}
		/>
	);
}

export default Index;