import React, { Fragment } from "react";
import {
	Avatar,
	Box,
	Chip,
	Breadcrumbs,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Tooltip,
	Grid,
	withStyles,
	makeStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

//import Tooltip from '@material-ui/core/Tooltip';
import Container from "@material-ui/core/Container";
import Moment from "moment";
import MaterialTable from "../../../components/material_table";
import { useHistory } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
	ACTUALIZAR_CONTRATO,
	AGREGAR_CONTRATO,
	ELIMINAR_CONTRATO,

	ACTUALIZAR_CONTRATO_BASICO,
	SOLICITAR_CIERRE_CONTRATO,
	ACTUALIZAR_CONTRATO_ENCUESTA,
} from "../../../constants/permissions";

import {
	ACTIVO,
	EN_PROCESOS_DE_CIERRE,
	EN_PROCESOS_DE_CIERRE_2,
} from "../../../constants/estados";
import { redirecTo } from "../../../services/auth";
import { tienePermisosParaVerTodosContrato } from "../../../services/database/usuario";

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "rgb(28,69,135)",
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

function TablaContratos(props) {
	const {
		usuario,
		contratos,
		dialog_open,
		handle_show_dialog,
		handle_eliminar,
		handle_cancelar,
		handle_export,
		contratoSelect,
	} = props;

	const classes = style();
	const history = useHistory();
	const permisos = Array.from(usuario.permisos);

	const permisoAgregar = permisos.find((p) => p.nombre === AGREGAR_CONTRATO);
	const permisoEliminar = permisos.find((p) => p.nombre === SOLICITAR_CIERRE_CONTRATO);
	const permisoActualizar = permisos.find(
		(p) => p.nombre === ACTUALIZAR_CONTRATO
	);
	const permisoActualizarBasico = permisos.find(
		(p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
	);
	const permisoActualizarEncuesta = permisos.find(
		(p) => p.nombre === ACTUALIZAR_CONTRATO_ENCUESTA
	);

	let columns = [
		{
			title: "Código Proyecto",
			field: "codigo",
			grouping: false,
			cellStyle: {
				fontSize: "0.9em",
				width: "15px",
			},
			render: (row) => {
				let status = generarStaus(row);
				let codigo = generarCodigoContratoView(row.codigo);
				return (
					<div
						style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}
					>
						{/* TODO cambio */}
						{/* <Grid>{status}</Grid> */}
						<Box fontSize={13} m={1}>
							{codigo}
						</Box>
					</div>
				);
			},
		}, {
			title: "Gerencia",
			field: "gerencia_ref.sigla",
			cellStyle: {
				width: "7px",
			},
			render: (row) => generarCodigoGerenciaView(row),
		}, {
			title: "Empresa",
			field: "sociedad_ref.nombre",
		}, {
			title: "Contrato",
			field: "nombre",
		}, {
			title: "Monto (UF)",
			field: "monto_contrato",
			hidden: true,
		}, {
			title: "Región",
			field: "region",
			hidden: true,
		}, {
			title: "Comuna",
			field: "comuna",
			hidden: true,
		}, {
			title: "Dirección",
			field: "direccion",
			hidden: true,
		}, {
			title: "Fecha Inicio",
			field: "fecha_inicio",
			render: (row) => generarFechaView(row, "fecha_inicio"),
			hidden: true,
		}, {
			title: "Fecha Est. Término",
			field: "fecha_termino_estimada",
			render: (row) => generarFechaView(row, "fecha_termino_estimada"),
		}, {
			title: "Responsable",
			field: "responsable_ref.persona.nombre_completo",
			render: (row) => getEncargadoView(row),
		}, {
			title: "Responsable Talana",
			field: "responsable_ref.persona.nombre_completo",
			render: (row) => getEncargadoTalanaView(row),
		}, {
			title: "Aprob. Factura 1",
			field: "aprobadores_factura.aprobadores.0.nombre_abreviado",
			render: (row) => getEncargadoFactura(row, 0),
		}, {
			title: "Aprob. Factura 2",
			field: "aprobadores_factura.aprobadores.1.nombre_abreviado",
			render: (row) => getEncargadoFactura(row, 1),
		}, {
			title: "Aprob. Factura 3",
			field: "aprobadores_factura.aprobadores.2.nombre_abreviado",
			render: (row) => getEncargadoFactura(row, 2),
			hidden: true,
		}, {
			title: "Aprob. Factura 4",
			field: "aprobadores_factura.aprobadores.3.nombre_abreviado",
			render: (row) => getEncargadoFactura(row, 3),
			hidden: true,
		}, {
			title: "Cliente",
			field: "mandante_ref.nombre",
			render: (row) => generarMandantesView(row),
		}, {
			title: "Aplica Encuesta",
			field: "aplicaEncuesta",
			render: (row) => generarAplicaEncuesta(row),
		},
	];

	console.log(!(permisoActualizar || permisoActualizarBasico))
	console.log(permisoActualizar, permisoActualizarBasico, permisoActualizarEncuesta)

	let actions = [
		(rowData) => ({
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => {
				console.log(!(permisoActualizar || permisoActualizarBasico) &&
					rowData.responsable_ref.persona.usuario_id !== usuario.uid)
				history.push(`/contrato/${row._id}`)
			},

			iconProps: {
				style: {
					color:
						!(tienePermisosParaVerTodosContrato(usuario) ||
							permisoActualizar ||
							permisoActualizarBasico || permisoActualizarEncuesta)
							? "#757575"
							: null,
				},
			},
			disabled: !(permisoActualizar || permisoActualizarBasico || permisoActualizarEncuesta) && !CheckResponsable(rowData.responsable_ref, usuario),
		}),

		// {
		//   icon: "edit",
		//   tooltip: "Editar",
		//   onClick: (event, row) => history.push(`/contrato/${row._id}`),
		//   iconProps: { style: { color: "#757575" } },
		//   iconProps: {
		//     style: {
		//       color:
		//         permisoActualizar || permisoActualizarBasico ? "#757575" : null,
		//     },
		//   },
		//   // hidden: !permisoActualizar,
		//   disabled: !(permisoActualizar || permisoActualizarBasico),
		// },
		(rowData) => {

			return {
				icon: "delete",
				tooltip: "Cerrar Contrato",
				onClick: (event, row) => {
					// TODO cambio producion
					// handle_show_dialog(row)
				},
				iconProps: { style: { color: !permisoEliminar ? "#757575" : null } },
				// hidden: !permisoEliminar,
				hidden: (rowData.estado === EN_PROCESOS_DE_CIERRE),
				disabled: !permisoEliminar,
			};
		},
		(rowData) => {
			return {
				icon: "email",
				tooltip: "Encuesta",
				onClick: (event, row) =>
					redirecTo(`https://encuestas.cydocs.cl/envio_encuestas/${row._id}`),

				hidden: (rowData.estado === EN_PROCESOS_DE_CIERRE),
				iconProps: {
					style: {
						color:
							!(tienePermisosParaVerTodosContrato(usuario) ||
								permisoActualizar ||
								permisoActualizarBasico)
								? "#757575"
								: null,
					},
				},
				disabled: !(permisoActualizar || permisoActualizarBasico) && !CheckResponsable(rowData.responsable_ref, usuario),
			};
		},

		{
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/contrato"),
			isFreeAction: true,
			// hidden: !permisoAgregar,
			disabled: !permisoAgregar,
		},
	];

	function createData(grupo, proceso, detalle, area) {
		return { grupo, proceso, detalle, area };
	}

	const rows = [
		createData(
			"Financiero",
			"Venta",
			"Revisar si hay solicitudes de facturas de ventas en CyDocs",
			"Control de Gestión"
		),
		createData(
			"Financiero",
			"Cobranza",
			"Revisar si hay facturas de venta sin cobrar",
			"Control de Gestión"
		),
		createData(
			"Financiero",
			"Producción",
			"Revisar si hay proyección de venta futura",
			"Control de Gestión"
		),
		createData(
			"Financiero",
			"Activo Financiero",
			"Revisar si hay Fondo Fijo abierto en el contrato",
			"Control de Gestión"
		),
		createData(
			"Financiero",
			"Pagos",
			"Revisar si hay factura de compra o rendición en proceso de pago en el SGP",
			"Tesorería"
		),
		createData(
			"Financiero",
			"Código Contable",
			"Deshabilitar código en Flexine, para evitar reclasificaciones",
			"Contabilidad"
		),
		//Operaciones
		createData(
			"Operaciones",
			"Solicitudes Recursos",
			"Revisar si hay solicitudes de recursos en curso en SDA",
			"Compras"
		),
		createData(
			"Operaciones",
			"Activos Fijos",
			"Revisar en SGA los activos vigente",
			"Logística"
		),
		createData(
			"Operaciones",
			"Cuentas de Google",
			"Revisar con Consola de Google si existen profesionales asociados a ese contrato",
			"TI"
		),
		createData(
			"Personas",
			"Profesionales Contratados",
			"Revisar con Talana si existen profesionales contratados.",
			"GPO"
		),
		createData(
			"Comercial",
			"Encuesta",
			"Enviar encuesta de satisfacción a cliente",
			"Comercial"
		),
		createData(
			"Comercial",
			"Experiencia",
			"Cargar certificado de experiencia, finiquito, etc.",
			"Comercial"
		),
	];
	return (
		<Fragment>
			<main>
				<Container maxWidth="xl" className={classes.main}>
					<Breadcrumbs separator=">">
						<Typography color="textPrimary">Módulo Contratos</Typography>
						<Typography color="textPrimary">Contratos</Typography>
					</Breadcrumbs>
					<Container maxWidth="xl">
						<MaterialTable
							title="Portafolio de Contratos"
							is_loading={!contratos}
							data={contratos}
							columns={columns}
							actions={actions}
							export_function={handle_export}
							fixedColumns={{ right: 0 }}
							exportar={true}
							options={{
								headerStyle: { position: "sticky", top: 0 },
								fixedColumns: {
									left: 1,
								},
							}}
						/>
					</Container>

					<Dialog
						open={dialog_open}
						disableBackdropClick
						maxWidth={"md"}
						disableEscapeKeyDown
						PaperProps={{ style: { borderRadius: "15px" } }}
					>
						<DialogTitle>Inicio de proceso de cierre</DialogTitle>
						<DialogContent dividers>
							{contratoSelect && (
								<Typography>
									Dará inicio al proceso de cierre al contrato{" "}
									<b>{contratoSelect.nombre}</b> con código{" "}
									<b>{contratoSelect.codigo}</b>, este contempla la revisión de
									los siguiente ítem.
								</Typography>
							)}
							<br></br>
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label="simple table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Grupos</StyledTableCell>
											<StyledTableCell align="right">Proceso</StyledTableCell>
											<StyledTableCell align="right">Detalle</StyledTableCell>
											<StyledTableCell align="right">
												Área Responsable
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row, index) => {
											let showCell = false;
											let collapseNum = 0;
											if (index === 0) {

												showCell = true;
												collapseNum = 6;
											} else if (index === 6) {

												showCell = true;
												collapseNum = 3;
											} else if (index === 9) {

												showCell = true;
												collapseNum = 1;
											} else if (index === 10) {

												showCell = true;
												collapseNum = 2;
											}
											return (
												<StyledTableRow key={row.grupo}>
													{showCell && (
														<TableCell
															component="th"
															scope="row"
															rowSpan={collapseNum}
														>
															{row.grupo}
														</TableCell>
													)}
													<TableCell align="right">{row.proceso}</TableCell>
													<TableCell align="right">{row.detalle}</TableCell>
													<TableCell align="right">{row.area}</TableCell>
												</StyledTableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handle_eliminar}
								variant="contained"
								color="primary"
								style={{ borderRadius: 15 }}
							>
								Iniciar cierre
							</Button>
							<Button
								autoFocus
								onClick={handle_cancelar}
								color="primary"
								style={{ borderRadius: 15 }}
							>
								Cancelar
							</Button>
						</DialogActions>
					</Dialog>
				</Container>
			</main>
		</Fragment>
	);
}

/**
 * Método encargado de generar el CHIP con el código del contrato/proyecto.
 * @param {*} codigo Código del contrato/proyecto.
 */
function generarCodigoContratoView(codigo) {
	if (!codigo) {
		return <Chip label={"Sin Código"} color="primary" />;
	} else {
		return <Chip label={codigo} color="primary" />;
	}
}

/**
 * Método encargado de generar el CHIP con la sigla de la gerencia.
 * @param {*} data Datos del contrato.
 */
function generarCodigoGerenciaView(data) {
	let texto = "Sin Gerencia";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.gerencia_ref) {
		texto = data.gerencia_ref.sigla;
	}
	return <Chip label={texto} variant="outlined" color="primary" />;
}

/**
 * Método encargado de generar el CHIP con foto y nombre del encargado.
 * @param {*} data Datos del contrato.
 */
function getEncargadoView(data) {
	let texto = "Sin Responsable";
	let foto = "";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.responsable_ref) {
		texto = data.responsable_ref.persona.nombre_completo;
		foto = data.responsable_ref.persona.photo;
	}
	return (
		<div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
			<Avatar alt="Foto Encargado" src={foto} />
			<Box fontSize={13} m={1}>
				{texto}
			</Box>
		</div>
	);
}

function getEncargadoTalanaView(data) {
	let texto = "Sin Responsable";
	let foto = "";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.responsable_admin_ref) {
		texto = data.responsable_admin_ref.persona.nombre_completo;
		foto = data.responsable_admin_ref.persona.photo;
	}
	return (
		<div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
			<Avatar alt="Foto Encargado" src={foto} />
			<Box fontSize={13} m={1}>
				{texto}
			</Box>
		</div>
	);
}
function generarStaus(data) {

	let color = "";
	switch (data.estado) {
		case ACTIVO:
			color = "#2ecc71";
			break;
		case EN_PROCESOS_DE_CIERRE:
			color = "#f1c40f";
			break;
		case EN_PROCESOS_DE_CIERRE_2:
			color = "#f1c40f";
			break;
		default:
			color = "#2ecc71";
			break;
	}
	return (
		<Tooltip
			title={
				data.estado === EN_PROCESOS_DE_CIERRE_2 ||
					data.estado === EN_PROCESOS_DE_CIERRE
					? "En proceso de cierre"
					: "Activo"
			}
		>
			<FiberManualRecordIcon
				fontSize="small"
				style={{
					color: color,
					fontSize: 10,
				}}
			/>
		</Tooltip>
	);
}

function getEncargadoFactura(data, posicion) {
	let texto = "Sin aprobador";
	let foto = "";
	if (typeof data === "string") {
		texto = data;
	} else if (
		typeof data === "object" &&
		data.aprobadores_factura.aprobadores[posicion]
	) {
		try {
			texto = data.aprobadores_factura.aprobadores[posicion].nombre_completo;
			foto = data.aprobadores_factura.aprobadores[posicion].photoUrl;
		} catch (error) {
			console.error(error, data.probadores_factura);
		}
	}
	return (
		<div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
			<Avatar alt="Foto Encargado" src={foto} />
			<Box fontSize={13} m={1}>
				{texto}
			</Box>
		</div>
	);
}

function generarAplicaEncuesta(data) {
	let texto = "Si";
	if (typeof data.aplicaEncuesta !== "undefined") {
		texto = data.aplicaEncuesta ? "si" : "N/A";
	}
	return <Chip label={texto} variant="outlined" color="primary" />;
}

/**
 * Método encargado de formatear las fechas, si corresponde.
 * @param {*} fecha Fecha que será verificada y formateada.
 */
function generarFechaView(data, propiedad) {
	let texto = "Indefinida";
	if (data && typeof data === "string") {
		texto = data;
	} else if (data && Moment(data[propiedad]).year() === 2099) {
		texto = "Indefinido";
	} else if (data && data[propiedad]) {
		texto = Moment(data[propiedad]).format("DD/MM/YYYY");
	}
	return texto;
}

/**
 * Método encargado de generar el CHIP para mandantes.
 * @param {*} data Datos del contrato.
 */
function generarMandantesView(data) {
	let texto = "Sin Gerencia";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.mandante_ref) {
		texto =
			data.mandante_ref.nombre.length < 25
				? data.mandante_ref.nombre
				: data.mandante_ref.nombre.substring(0, 25) + "...";
	}
	return <Chip label={texto} variant="outlined" color="primary" />;
}

/**
 * Método encargado de verificar datos del responsable y el usuario.
 * @param {*} responsable Datos del responsable.
 * @param {*} usuario Datos del usuario.
 * @returns TRUE: El el responsable es el usuario | FALSE: Todo otro caso.
 */
function CheckResponsable(responsable, usuario) {
	if (!responsable || !responsable.persona || !usuario) {
		return false;
	}
	if (responsable === "null" || usuario === "null") {
		return false;
	}
	if (responsable.persona.usuario_id !== usuario.uid) {
		return false;
	}
	return true;
}



const style = makeStyles((theme) => ({
	main: {
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
}));

export default TablaContratos;
