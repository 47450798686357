import React, { Fragment } from "react";
import {
  Avatar,
  Box,
  Chip,
  Breadcrumbs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
} from "@material-ui/core";
import Moment from "moment";
import MaterialTable from "../../../components/material_table";

import { useHistory } from "react-router-dom";
import {
  ACTUALIZAR_CONTRATO,
  ELIMINAR_CONTRATO,
  ACTUALIZAR_CONTRATO_BASICO,
} from "../../../constants/permissions";

import ClearIcon from "@material-ui/icons/Clear";

function TablaContratos(props) {
  const {
    usuario,
    contratos,
    dialog_open,
    handle_show_dialog,
    handle_eliminar,
    handle_cancelar,
    handle_export,
  } = props;

  
  const history = useHistory();
  const permisos = Array.from(usuario.permisos);
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO
  );
  
  const permisoEliminar = permisos.find((p) => p.nombre === ELIMINAR_CONTRATO);
  
  const permisoActualizarBasico = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
  );

  let columns = [
    {
      title: "Código Proyecto",
      field: "codigo",
      grouping: false,
      cellStyle: {
        fontSize: "0.9em",
        width: "15px",
      },
      render: (row) => generarCodigoContratoView(row.codigo),
    },
    {
      title: "Solicitado por",
      field: "ingresado_por.nombre",
      render: (row) => getIngresadoPor(row),
    },
    {
      title: "Gerencia",
      field: "gerencia_ref.sigla",
      width: "5%",
      cellStyle: {
        //width: "7px",
        //whiteSpace: 'nowrap'
      },
      render: (row) => generarCodigoGerenciaView(row),
    },
    {
      title: "Empresa",
      field: "sociedad_ref.nombre",
    },
    {
      title: "Contrato",
      field: "nombre",
    },
    {
      title: "Fecha Inicio",
      field: "fecha_inicio",
      hidden: true,
      render: (row) => generarFechaView(row, "fecha_inicio"),
    },
    {
      title: "Fecha Est. Término",
      field: "fecha_termino_estimada",
      hidden: true,
      render: (row) => generarFechaView(row, "fecha_termino_estimada"),
    },
    {
      title: "Responsable",
      field: "responsable_ref.persona.nombre_completo",
      render: (row) => getEncargadoView(row),
    },
    {
      title: "Responsable Talana",
      field: "responsable_ref.persona.nombre_completo",
      render: (row) => getEncargadoTalanaView(row),
    },
    {
      title: "Cliente",
      field: "mandante_ref.nombre",
      render: (row) => generarMandantesView(row),
    },
  ];

  let actions = [
    (rowData) => ({
      icon: rowData.is_eliminado
        ? ""
        : () => <Chip label="Agregar" color="primary" variant="outlined" />,
      
      tooltip: "Arpobar solicitud",
      onClick: (event, row) => history.push(`/v2/contrato/${row._id}`),
      disabled:
        !(permisoActualizar || permisoActualizarBasico) &&
        rowData.responsable_ref.persona.usuario_id !== usuario.uid,
    }),
    (rowData) => ({
      icon: rowData.is_eliminado ? "" : () => <ClearIcon />,
      tooltip: "Eliminar",
      disabled:  !permisoEliminar && !rowData.is_eliminado,
      onClick: (event, row) => handle_show_dialog(row),
      iconProps: { style: { color: permisoEliminar ? "#757575" : null } },
      // hidden: !permisoEliminar,
      
    }),
  ];

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Contratos</Typography>
        <Typography color="textPrimary">Solicitud de Contrato</Typography>
      </Breadcrumbs>
      <Container maxWidth="xl">
        <MaterialTable
          title="Solicitud de código de Contrato"
          is_loading={!contratos}
          data={contratos}
          columns={columns}
          actions={actions}
          export_function={handle_export}
          styleRow={(rowData) => {
            return {
              backgroundColor:
                rowData.estado !== "en-espera"
                  ? "#badc58"
                  : rowData.is_eliminado
                  ? "#ff797920"
                  : "#dff9fb",
            };
          }}
        />
      </Container>
      <Dialog open={dialog_open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>Eliminar Solicitud de Contrato</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Se debe confirmar la eliminación de la solicitud.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handle_eliminar} variant="contained" color="primary">
            Eliminar
          </Button>
          <Button autoFocus onClick={handle_cancelar} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

/**
 * Método encargado de generar el CHIP con el código del contrato/proyecto.
 * @param {*} codigo Código del contrato/proyecto.
 */
function generarCodigoContratoView(codigo) {
  if (!codigo) {
    return <Chip label={"Sin Código"} color="primary" />;
  } else {
    return <Chip label={codigo} color="primary" />;
  }
}

/**
 * Método encargado de generar el CHIP con la sigla de la gerencia.
 * @param {*} data Datos del contrato.
 */
function generarCodigoGerenciaView(data) {
  let texto = "Sin Gerencia";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.gerencia_ref) {
    texto = data.gerencia_ref.sigla;
  }
  return <Chip label={texto} variant="outlined" color="primary" />;
}

/**
 * Método encargado de generar el CHIP con foto y nombre del encargado.
 * @param {*} data Datos del contrato.
 */
function getEncargadoView(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.responsable_ref) {
    texto = data.responsable_ref.persona.nombre_completo;
    foto = data.responsable_ref.persona.photo;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

function getIngresadoPor(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.ingresado_por) {
    texto = data.ingresado_por.nombre;
    foto = data.ingresado_por.photoUrl;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

function getEncargadoTalanaView(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.responsable_admin_ref) {
    texto = data.responsable_admin_ref.persona.nombre_completo;
    foto = data.responsable_admin_ref.persona.photo;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

/**
 * Método encargado de formatear las fechas, si corresponde.
 * @param {*} fecha Fecha que será verificada y formateada.
 */
function generarFechaView(data, propiedad) {
  let texto = "Indefinida";
  if (data && typeof data === "string") {
    texto = data;
  } else if (data && Moment(data[propiedad]).year() === 2099) {
    texto = "Indefinido";
  } else if (data && data[propiedad]) {
    texto = Moment(data[propiedad]).format("DD/MM/YYYY");
  }
  return texto;
}

/**
 * Método encargado de generar el CHIP para mandantes.
 * @param {*} data Datos del contrato.
 */
function generarMandantesView(data) {
  let texto = "Sin Gerencia";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.mandante_ref) {
    texto =
      data.mandante_ref.nombre.length < 25
        ? data.mandante_ref.nombre
        : data.mandante_ref.nombre.substring(0, 25) + "...";
  }
  return <Chip label={texto} variant="outlined" color="primary" />;
}



export default TablaContratos;
