import React from "react";
import Page from "./page";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actionOpenAddPermiso } from "../actionsPermisos";
import * as yup from 'yup'
import { postPermiso } from "../requestPermisos";
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function AgregarPermiso() {

  const dispatch = useDispatch();
  const notistack = useSnackbar();
  const { open } = useSelector((state) => ({
    open: state.reducerPermiso.openAddPermiso
  }));

  const validationSchema = yup.object({
    nombre: yup.string().required("Campo requerido"),
    descripcion: yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
      descripcion: ""
    },
    onSubmit: values => {
      const key = notistack.enqueueSnackbar("Guardando Permiso...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      try {
        postPermiso(values)
          .then((response) => {
            if (response.status === 201) {
              notistack.closeSnackbar(key);
              const successkey = notistack.enqueueSnackbar("Operanción exitosa: Permiso guardado", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
              });
              dispatch(actionOpenAddPermiso());
              //dispatch(actionRefreshTablePermiso(true));
            } else {
              console.log(response);
              notistack.closeSnackbar(key);
              const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Permiso. Intente nuevamente o contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
              });
            }
          })
          .catch((e) => {
            console.log(e);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Permiso. Contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          });

      }
      catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible cargar el Permiso Contacte con soporte el equipo de soporte", {
          variant: 'error',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
        });
      }
    },
    validationSchema: validationSchema
  });

  const onClose = () => dispatch(actionOpenAddPermiso());

  const onEntered = () => {
    formik.resetForm({
      nombre: "",
      descripcion: ""
    });
  }

  return (
    <Page
      formik={formik}
      onClose={onClose}
      onEntered={onEntered}
      open={open}
    />
  );
}