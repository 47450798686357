import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerSociedades, eliminarSociedad } from "../requestSociedad";
import XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Sociedades, SetSociedades] = useState();
	const [DialogOpen, SetDialogOpen] = useState(false);
	const [ItemSeleccionado, SetItemSeleccionado] = useState(null);
	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerSociedades()
			.then(sociedades => SetSociedades(sociedades))
			.catch(error => SetSociedades([]));
	}, []);

	const handleShowDialog = (sociedadID) => {
		SetDialogOpen(true);
		SetItemSeleccionado(sociedadID);
	}

	/**
	 * Handler para eliminar una sociedad.
	 */
	const handleEliminar = () => {
		eliminarSociedad(ItemSeleccionado)
			.then(response => {
				console.log("Sociedad eliminada exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Sociedad eliminada exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar eliminar el sociedad", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar eliminar el sociedad", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				SetDialogOpen(false);
				SetItemSeleccionado(null);
			});
	}

	/**
	 * Handler para cancelar la eliminación.
	 */
	const handleCancelarEliminar = () => {
		SetDialogOpen(false);
		SetItemSeleccionado(null);
	}

	/**
	 * Hanlder para exportar datos con formato Excel.
	 */
	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Sociedades.map(s => ({
			codigo: s.codigo,
			rut: s.rut_formateado,
			nombre: s.nombre,
			giro: s.giro,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Sociedades");
		XLSX.writeFile(wb, "sociedades.xlsx");
	}

	return (
		<Page
			usuario={usuarioSesion}
			sociedades={Sociedades}
			dialog_open={DialogOpen}
			handle_show_dialog={handleShowDialog}
			handle_eliminar={handleEliminar}
			handle_cancelar={handleCancelarEliminar}
			handle_export={handleExport}
		/>
	);
}

export default Index;