import React from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Button,
	makeStyles
} from '@material-ui/core/';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    display: "inline-block"
  },
  hidden: {
    display: "none"
  },
  small_padding: {
    padding: "3px"
  },
  default_padding: {
    padding: "12px"
  }
}));

function MenuDropdown(props) {

  const classes = useStyles();

  const { button, icon, items, tooltip, hidden, padding, variant } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e, onClick) => {
    setAnchorEl(null);
    onClick(e);
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx({ [classes.hidden]: hidden, [classes.container]: true })}>
      {tooltip && icon &&
        <Tooltip title={tooltip}>
          <IconButton className={padding && classes[padding] ? classes[padding] : classes.small_padding} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
            {icon}
          </IconButton>
        </Tooltip>
      }
      {!tooltip && icon &&
        <IconButton className={padding && classes[padding] ? classes[padding] : classes.small_padding} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
          {icon}
        </IconButton>
      }
      {tooltip && button &&
        <Tooltip title={tooltip}>
          <Button variant={variant ? variant : "outlined"} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
            {button}
          </Button>
        </Tooltip>
      }
      {!tooltip && button &&
        <Button variant={variant ? variant : "outlined"} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
          {button}
        </Button>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) =>
          (<MenuItem key={index} className={clsx({ [classes.hidden]: item.hidden })} onClick={(e) => handleClick(e, item.onClick)} {...item.props} >
            {item.content}
          </MenuItem>))}
      </Menu>
    </div>
  );
}

export default MenuDropdown;