import React, { Fragment, useEffect } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Chip,
  Container,

} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  ACTUALIZAR_CONTRATO,

  ACTUALIZAR_CONTRATO_BASICO,
} from "../../../constants/permissions";
import axios from "axios";
import { useUsuarios } from "../../responsables/nuevo/hooksPersonas";
import SeccionView from "../nuevov3/components/SeccionView";
import SelectorUsuario from "./SelectorUsuario";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Moment from "moment";
import ItemArchivo from "./item_archivo";
import ItemArchivoUpload from "./item_archivo_upload";

import FileDropUpload from "./file_drop_upload";
import View_aprobador from "./view_aprobador";
import { Field } from "formik";



function Page(props) {
  const {
    gerencias,
    responsables,
    sociedades,
    mandantes,
    handle_salir,
    formik,
    usuario,
    cambioDeCorrelatico,

    onChangeCambioDeCorrelativo,
    contrato,
    comunas,
    regiones,
  } = props;

  const classes = useStyle();
  const {
    values,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = formik;
  const [options, setOptions] = React.useState([]);
  let cancelToken = axios.CancelToken.source();
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    null,
    cancelToken
  );

  const [openUploadFile, setOpenUploadFile] = React.useState(false);

  const handleClickOpen = () => {
    console.log("open dialog");
    setOpenUploadFile(true);
  };

  const handleClose = () => {
    setOpenUploadFile(false);
  };

  useEffect(() => {
    // console.log(UsuariosResponsables);
    if (typeof UsuariosResponsables === "undefined") {
      setOptions([]);
    } else {
      // console.log(UsuariosResponsables.length);
      if (UsuariosResponsables.length === 0) {
        setOptions([]);
      } else {
        setOptions(UsuariosResponsables);
      }
    }
  }, [UsuariosResponsables]);

  const permisos = Array.from(usuario.permisos);
  const permisoActualizarBasico = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
  );
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO
  );

  if (!gerencias || !responsables || !sociedades || !mandantes) {
    //Si aún no cargan los datos.
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  console.log(contrato.aplicaEncuesta)

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Contratos</Typography>
        <Link to="/contratos">Contratos</Link>
        <Typography color="textPrimary">{`Modificar Contrato ${values.codigo}`}</Typography>
      </Breadcrumbs>
      <Paper className={classes.card}>
        <form onSubmit={handleSubmit}>
          {/* CODIGO ACTUAL Y NUEVO */}
          <Grid container spacing={3}>
            <Grid item xs={values.is_eliminado ? 12 : 6}>
              {/* CODIGO ACTUAL */}
              <Typography variant="h6" align="center">
                {"Código Actual"}
              </Typography> 
              <Typography variant="h4" align="center">
                {values.codigo}
              </Typography>
            </Grid>
            {!values.is_eliminado ? (
              <Grid item xs={6}>
                {/* CODIGO NUEVO */}
                <Typography variant="h6" align="center">
                  {"Código Nuevo"}
                </Typography>
                <Typography variant="h4" align="center">
                  {cambioDeCorrelatico
                    ? `${values.codigo_nuevo.gerencia}-${values.codigo_nuevo.responsable}-${values.codigo_nuevo.correlativo}`
                    : values.codigo}
                </Typography>
              </Grid>
            ) : (
              <Container></Container>
            )}
          </Grid>
          {/* CAMPOS DEL CONTRATO */}
          <Grid container spacing={3}>
            <Grid item xs={6} xl={3}>
              {/* GERENCIA */}
              <Autocomplete
                name="gerencia_ref"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico || !permisoActualizar
                    ? true
                    : false
                }
                value={Array.from(gerencias).find(
                  (g) => g._id === values.gerencia_ref
                )}
                loading={!gerencias}
                options={gerencias}
                onChange={(event, value) => {
                  setFieldValue("gerencia_ref", value ? value._id : "");
                  setFieldValue(
                    "codigo_nuevo.gerencia",
                    value ? value.sigla : ""
                  );
                }}
                getOptionLabel={(g) => `${g.sigla} - ${g.nombre}`}
                renderInput={(params) => (
                  <TextField
                    label="Gerencia"
                    variant="outlined"
                    required
                    error={
                      touched.gerencia_ref &&
                      errors.gerencia_ref &&
                      Boolean(errors.gerencia_ref)
                    }
                    helperText={
                      touched.gerencia_ref && errors.gerencia_ref
                        ? errors.gerencia_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* MANDANTE */}
              <Autocomplete
                name="mandante_ref"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                value={Array.from(mandantes).find(
                  (m) => m._id === values.mandante_ref
                )}
                loading={!mandantes}
                options={mandantes}
                onChange={(event, value) =>
                  setFieldValue("mandante_ref", value ? value._id : "")
                }
                getOptionLabel={(m) => m.nombre}
                renderInput={(params) => (
                  <TextField
                    label="Mandante"
                    variant="outlined"
                    required
                    error={
                      touched.mandante_ref &&
                      errors.mandante_ref &&
                      Boolean(errors.mandante_ref)
                    }
                    helperText={
                      touched.mandante_ref && errors.mandante_ref
                        ? errors.mandante_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              <TextField
                name="nombre"
                label="Nombre"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                value={values.nombre}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                required
                error={
                  touched.nombre && errors.nombre && Boolean(errors.nombre)
                }
                helperText={
                  touched.nombre && errors.nombre ? errors.nombre : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* SOCIEDAD */}
              <Autocomplete
                name="sociedad_ref"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                value={Array.from(sociedades).find(
                  (s) => s._id === values.sociedad_ref
                )}
                loading={!sociedades}
                options={sociedades}
                onChange={(event, value) =>
                  setFieldValue("sociedad_ref", value ? value._id : "")
                }
                getOptionLabel={(s) => s.nombre}
                renderInput={(params) => (
                  <TextField
                    label="Sociedad"
                    variant="outlined"
                    required
                    error={
                      touched.sociedad_ref &&
                      errors.sociedad_ref &&
                      Boolean(errors.sociedad_ref)
                    }
                    helperText={
                      touched.sociedad_ref && errors.sociedad_ref
                        ? errors.sociedad_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* RESPONSABLE */}
              <Autocomplete
                name="responsable_ref"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                value={Array.from(responsables).find(
                  (r) => r._id === values.responsable_ref
                )}
                loading={!responsables}
                options={responsables}
                onChange={(event, value) => {
                  setFieldValue("responsable_ref", value ? value._id : "");
                  setFieldValue(
                    "codigo_nuevo.responsable",
                    value ? value.correlativo : ""
                  );
                }}
                getOptionLabel={(responsable) =>
                  responsable.persona.nombre_completo
                }
                renderInput={(params) => (
                  <TextField
                    label="Responsable"
                    variant="outlined"
                    required
                    error={
                      touched.responsable_ref &&
                      errors.responsable_ref &&
                      Boolean(errors.responsable_ref)
                    }
                    helperText={
                      touched.responsable_ref && errors.responsable_ref
                        ? errors.responsable_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
              <FormControlLabel
                disabled={
                  permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                control={
                  <Checkbox
                    checked={cambioDeCorrelatico}
                    onChange={onChangeCambioDeCorrelativo}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Cambia correlativo"
              />
              <FormControlLabel
                disabled={
                  permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                control={
                  <Checkbox
                    name={"aplicaEncuesta"}
                    checked={
                      typeof values.aplicaEncuesta === "undefined"
                        ? true
                        : values.aplicaEncuesta
                    }
                    onChange={handleChange}
                    color="primary"
                    label="Aplica Encuesta"
                  />
                }
                label="Aplica Encuesta"
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* RESPONSABLE */}
              <Autocomplete
                name="responsable_admin_ref"
                value={Array.from(responsables).find(
                  (r) => r._id === values.responsable_admin_ref
                )}
                loading={!responsables}
                options={responsables}
                onChange={(event, value) => {
                  setFieldValue(
                    "responsable_admin_ref",
                    value ? value._id : ""
                  );
                }}
                getOptionLabel={(responsable) =>
                  responsable.persona.nombre_completo
                }
                disabled={values.is_eliminado ? true : false}
                renderInput={(params) => (
                  <TextField
                    label="Responsable Talana"
                    variant="outlined"
                    required
                    error={
                      touched.responsable_admin_ref &&
                      errors.responsable_admin_ref &&
                      Boolean(errors.responsable_admin_ref)
                    }
                    helperText={
                      touched.responsable_admin_ref &&
                      errors.responsable_admin_ref
                        ? errors.responsable_admin_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA DE INICIO */}
              <DatePicker
                name="fecha_inicio"
                label="Fecha de Inicio"
                value={values.fecha_inicio}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                fullWidth
                onChange={(date) => setFieldValue("fecha_inicio", date)}
                disabled={values.is_eliminado ? true : false}
                error={
                  touched.fecha_inicio &&
                  errors.fecha_inicio &&
                  Boolean(errors.fecha_inicio)
                }
                helperText={
                  touched.fecha_inicio && errors.fecha_inicio
                    ? errors.fecha_inicio
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA ESTIMADA TERMINO */}
              <DatePicker
                name="fecha_termino_estimada"
                label="Fecha Estimada de Término"
                value={values.fecha_termino_estimada}
                format="DD/MM/YYYY"
                maxDate={Moment("31/12/2099", "MM-DD-YYYY")}
                inputVariant="outlined"
                disabled={values.is_eliminado ? true : false}
                fullWidth
                required
                onChange={(date) =>
                  setFieldValue("fecha_termino_estimada", date)
                }
                error={
                  touched.fecha_termino_estimada &&
                  errors.fecha_termino_estimada &&
                  Boolean(errors.fecha_termino_estimada)
                }
                helperText={
                  touched.fecha_termino_estimada &&
                  errors.fecha_termino_estimada
                    ? errors.fecha_termino_estimada
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA DE INICIO */}
              <DatePicker
                name="fecha_adjudicacion"
                value={values.fecha_adjudicacion}
                label="Fecha de Adjudicación"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                fullWidth
                disabled={values.is_eliminado ? true : false}
                onChange={(date) => setFieldValue("fecha_adjudicacion", date)}
                error={
                  touched.fecha_adjudicacion &&
                  errors.fecha_adjudicacion &&
                  Boolean(errors.fecha_adjudicacion)
                }
                helperText={
                  touched.fecha_adjudicacion && errors.fecha_adjudicacion
                    ? errors.fecha_adjudicacion
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA TERMINO REAL */}
              <DatePicker
                name="fecha_termino_real"
                label="Fecha Término Real"
                value={values.fecha_termino_real}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                fullWidth
                disabled={values.is_eliminado ? true : false}
                onChange={(date) => setFieldValue("fecha_termino_real", date)}
                error={
                  touched.fecha_termino_real &&
                  errors.fecha_termino_real &&
                  Boolean(errors.fecha_termino_real)
                }
                helperText={
                  touched.fecha_termino_real && errors.fecha_termino_real
                    ? errors.fecha_termino_real
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* ESTADO */}
              <Autocomplete
                name="estado"
                disabled={
                  values.is_eliminado
                    ? true
                    : permisoActualizar
                    ? false
                    : permisoActualizarBasico
                    ? true
                    : false
                }
                value={["Activo", "Terminado"].find(
                  (e) =>
                    e.toLocaleLowerCase() === values.estado.toLocaleLowerCase()
                )}
                options={["Activo", "Pendiente de eliminacion", "Terminado"]}
                onChange={(event, value) =>
                  setFieldValue("estado", value ? value : "")
                }
                renderInput={(params) => (
                  <TextField
                    label="Estado"
                    variant="outlined"
                    required
                    error={
                      touched.estado && errors.estado && Boolean(errors.estado)
                    }
                    helperText={
                      touched.estado && errors.estado ? errors.estado : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            {values.is_eliminado ?? (
              <Grid item xs={6} xl={3}>
                {/* ESTADO */}
                <Chip label="Contrato Terminado" color="secondary" />
              </Grid>
            )}

            <Grid item xs={6} xl={3}>
              {/* FECHA CREACION */}
              <DatePicker
                label="Fecha Ingreso"
                value={values.fecha_creacion}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                readOnly
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA ACTUALIZACION */}
              <DatePicker
                label="Fecha Actualización"
                value={values.fecha_actualizacion}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                readOnly
                disabled
                fullWidth
              />
            </Grid>

            {/* nuevos compos */}
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              {/* RESPONSABLE */}
              <Autocomplete
                name="region"
                loading={!regiones}
                options={regiones}
                value={Array.from(regiones).find((g) => {
                  return g.nombre === values.region ? values.region : "";
                })}
                onChange={(event, value) => {
                  setFieldValue("region", value ? value.nombre : "");
                }}
                getOptionLabel={(region) => {
                  return region.nombre;
                }}
                renderInput={(params) => (
                  <TextField
                    label="Región"
                    variant="outlined"
                    required
                    error={
                      touched.region && errors.region && Boolean(errors.region)
                    }
                    helperText={
                      touched.region && errors.region ? errors.region : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              <Autocomplete
                name="comuna"
                loading={!comunas}
                options={comunas}
                onChange={(event, value) => {
                  setFieldValue("comuna", value ? value.nombre : "");
                }}
                getOptionLabel={(responsable) => {
                  return responsable.nombre;
                }}
                value={Array.from(comunas).find((g) => {
                  return g.nombre === values.comuna ? values.comuna : "";
                })}
                renderInput={(params) => (
                  <TextField
                    label="Comuna"
                    variant="outlined"
                    required
                    error={
                      touched.comuna && errors.comuna && Boolean(errors.comuna)
                    }
                    helperText={
                      touched.comuna && errors.comuna ? errors.comuna : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              <TextField
                name="direccion"
                label="Dirección"
                variant="outlined"
                required
                fullWidth
                value={values.direccion}
                onChange={handleChange}
                error={
                  touched.direccion &&
                  errors.direccion &&
                  Boolean(errors.direccion)
                }
                helperText={
                  touched.direccion && errors.direccion ? errors.direccion : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              <TextField
                name="monto_del_contrato"
                label="Monto del Contrato. Por ejemplo, UF 15.000 "
                value={values.monto_del_contrato}
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                error={
                  touched.monto_del_contrato &&
                  errors.monto_del_contrato &&
                  Boolean(errors.monto_del_contrato)
                }
                helperText={
                  touched.monto_del_contrato && errors.monto_del_contrato
                    ? errors.monto_del_contrato
                    : ""
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} xl={12}>
              <SeccionView
                borderColor="#731f1f"
                nombreSeccion="Documentos de adjudicación "
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 1"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 2"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivo tipo={"pdf"} nombre={"archivo 3"} />
                  </Grid>
                  <Grid item>
                    <ItemArchivoUpload onCickItem={handleClickOpen} />
                  </Grid>
                </Grid>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<MailOutlineIcon />}
                >
                  Solicitar documentos
                </Button>
              </SeccionView>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} xl={12}>
              <SeccionView
                borderColor="#731f1f"
                nombreSeccion="Aprobadores Rendiciones (Versión en Desarrollo)"
              >
                <Grid container spacing={2} direction="row" alignItems="center">
                  <Grid item xs={3} xl={3}>
                    <View_aprobador
                      aprobador={contrato.aprobadores_factura.aprobadores[0]}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} xl={3}>
                    <View_aprobador
                      aprobador={contrato.aprobadores_factura.aprobadores[1]}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    {contrato.aprobadores_factura?.aprobadores[2] ===
                    undefined ? (
                      <View_aprobador
                        aprobador={contrato.aprobadores_factura.aprobadores[2]}
                      />
                    ) : (
                      <Container />
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    {contrato.aprobadores_factura?.aprobadores[3] ===
                    undefined ? (
                      <View_aprobador
                        aprobador={contrato.aprobadores_factura.aprobadores[3]}
                      />
                    ) : (
                      <Container />
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2} direction="row" alignItems="center">
                  <Grid item xs={3} xl={3}>
                    <SelectorUsuario
                      label={"aprobador 1"}
                      nameField="aprobador_1"
                      data={options}
                      formik={formik}
                      loadingData={loadResponsables}
                      valueInit={
                        contrato.aprobadores_factura?.aprobadores[0]
                          ? contrato.aprobadores_factura?.aprobadores[0]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} xl={3}>
                    <SelectorUsuario
                      label={"aprobador 2"}
                      nameField="aprobador_2"
                      data={options}
                      formik={formik}
                      loadingData={loadResponsables}
                      valueInit={
                        contrato.aprobadores_factura?.aprobadores[1]
                          ? contrato.aprobadores_factura?.aprobadores[1]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    <SelectorUsuario
                      label={"aprobador 3"}
                      nameField="aprobador_3"
                      data={options}
                      formik={formik}
                      loadingData={loadResponsables}
                      valueInit={
                        contrato.aprobadores_factura?.aprobadores[2]
                          ? contrato.aprobadores_factura?.aprobadores[2]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <ArrowForwardIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    <SelectorUsuario
                      label={"aprobador 4"}
                      nameField="aprobador_4"
                      data={options}
                      formik={formik}
                      loadingData={loadResponsables}
                      valueInit={
                        contrato.aprobadores_factura?.aprobadores[2]
                          ? contrato.aprobadores_factura?.aprobadores[2]
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </SeccionView>
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              onClick={handle_salir}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              Salir
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Actualizar
            </Button>
          </Box>
        </form>
        <FileDropUpload
          contrato={contrato}
          handleClose={handleClose}
          open={openUploadFile}
        />
      </Paper>
    </Fragment>
  );
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 25,
  },
}));

export default Page;
