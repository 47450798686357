import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
// Internals
import schema from "../schema.json";

import {
  Avatar,
  Chip, CircularProgress,
  Container, ListSubheader, makeStyles,
  Paper, TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import HeaderBackTable from "../../../components/HeaderBackTable";
import { useUsuarios } from "../../responsables/nuevo/hooksPersonas";
import ObjectFieldTemplate from "../FormCyd";
import { actualizarItemProceso, obtenerItemProceso } from "../requestItem";

// One could also use the `Theme4` alias
// import { Theme4 as MaterialUITheme } from '@rjsf/material-ui';

// Make modifications to the theme with your own fields and widgets

const Form = withTheme(MaterialUITheme);
const useStyles = makeStyles((theme) => ({
  formControl: {
    // width: "100%"
  },
  root: {
    display: "inline-block",
  },
  // myfield: {
  //   <Grid item xs={12} sm={6}>
  // }
}));

function AdminProcesoEliminacionAdd(props) {
  const [formData, setFormData] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));
  const { id: contratoID } = useParams();

  useEffect(() => {
    obtenerItemProceso(contratoID).then((result) => {
      console.log({
        ...result,
        grupo: {
          label: result.grupo,
          value: result.grupo,
        },
      });
      setFormData(result);
    });
  }, []);

  const onSubmit = (value, callback) => {
    console.log(contratoID);

    let item = value.formData.responsables.map((item, index) => {
      console.log(item);
      let responsable = {
        nombre: item.nombre_abreviado,
        nombre_abreviado: item.nombre_abreviado,
        apellido_materno: item.apellido_materno,
        apellido_paterno: item.apellido_paterno,
        nombre_completo: item.nombre_completo,
        email: item.email ? item.email : item.contacto.email,
        photoUrl: item.photo,
        uid: item.auth_id,
        rut: item.run,
        cargo: item.cargo,
      };
      //value.formData.responsables[index] = responsable;
    });
    value.formData._id = contratoID;
    console.log(value.formData);
    console.log(item);
    actualizarItemProceso(value.formData, usuarioSesion).then((response) => {
      console.log(response);
      history.push(`/eliminacion/pasos/`);
    });
    // agregarItemProceso(value.formData).then((response) => {
    //   console.log(response);
    // });
    setFormData({});

    //propssetTimeout(() => callback && callback(), 2000); // just an example in real world can be your XHR call
  };

  const onCancel = () => {
    console.log("onCancel reset being called");
  };

  const onFormChanged = ({ formData }) => {
    console.log("onFormChanged: ", formData); // eslint-disable-line no-console
    setFormData(formData);
  };

  const onUpload = (value) => {
    console.log("onUpload: ", value); // eslint-disable-line no-console
  };

  const onError = (errors) => {
    console.log(errors);
    console.log("onError reset being called");
  };

  function transformErrors(errors) {
    return errors.map((error) => {
      if (error.name === "pattern") {
        error.message = "Only sdf digits are allowed";
      }
      return error;
    });
  }

  function validate(formData, errors) {
    console.log(formData);
    console.log(errors);
    if (formData.pass1 !== formData.pass2) {
      errors.pass2.addError("Passwords don't match");
    }
    return errors;
  }

  const widgets = {
    customTextField: TextFieldCyd,
    TextWidget: TextFieldCyd,
    selectItem: SelectItem,
    MultipleSelector: MultipleSelector,
  };
  return (
    <Container style={{ paddingTop: "50px" }} maxWidth={"xl"}>
      <Paper style={{ padding: 15, borderRadius: "15px" }} elevation={3}>
        <HeaderBackTable titulo={"Ítem de eliminación"} />
        {formData !== null ? (
          <Form
            method="post"
            action="submit"
            schema={schema}
            formData={formData}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onChange={onFormChanged}
            onError={onError}
            validate={validate}
            widgets={widgets}
            showErrorList={false}
            omitExtraData={false}
            transformErrors={transformErrors}
            uiSchema={{
              "ui:ObjectFieldTemplate": ObjectFieldTemplate,
              "ui:submitButtonOptions": {
                submitText: "Guardar",
              },
              fecha: {
                "ui:widget": "date",
              },
              mensaje_notificacion: {
                "ui:widget": TextFieldCyd,
                "ui:options": {
                  multiline: true,
                  rows: 4,
                },
              },
              responsables: {
                "ui:widget": MultipleSelector,
                "ui:options": {
                  labelOption: "nombre_completo",
                  backgroundColor: "yellow",
                },
              },
              grupo: {
                "ui:widget": SelectItem,
                "ui:options": {
                  labelOption: "value",
                },
              },
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </Paper>
    </Container>
  );
}
const useStylesSlector = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  fullWidth: {
    width: "100%",
  },
}));

const MultipleSelector = (props) => {
  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState([]);

  const classes = useStylesSlector();
  const [value, setValue] = useState(
    typeof props.value == "undefined" ? null : props.value
  );

  let cancelToken = axios.CancelToken.source();
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    null,
    cancelToken
  );

  useEffect(() => {
    // console.log(UsuariosResponsables);
    if (typeof UsuariosResponsables === "undefined") {
      setOptionsUsurios([]);
    } else {
      // console.log(UsuariosResponsables.length);
      if (UsuariosResponsables.length === 0) {
        setOptionsUsurios([]);
      } else {
        let sanitizacion = UsuariosResponsables.map((item) => {
          let responsable = {
            nombre: item.nombre_abreviado,
            nombre_abreviado: item.nombre_abreviado,
            apellido_materno: item.apellido_materno,
            apellido_paterno: item.apellido_paterno,
            nombre_completo: item.nombre_completo,
            email: item.email ? item.email : item.contacto.email,
            photoUrl: item.photo,
            uid: item.auth_id,
            rut: item.run,
            cargo: item.cargo,
          };
          return responsable;
        });
        setOptionsUsurios(sanitizacion);
      }
      console.log("set usuario", props.value);
      setValue(props.value);
    }
  }, [UsuariosResponsables]);

  const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return   <Autocomplete
  label={props.label}
  value={value}
  multiple
  id="tags-outlined"
  onChange={(_ev, newValue, reason) => {
    console.log(newValue);
    props.onChange(newValue ?? {});
    setValue(newValue);
    //props.onChange(newValue.map(getOptionName));
  }}
  renderGroup={renderGroup}
  loading={loadResponsables}
  options={optionsUsuarios}
  getOptionSelected={(option, value) => {
    //console.log(value)

    if (value.uid === option.uid) {
      return true;
    } else {
      return false;
    }
  }}
  getOptionLabel={(g) => `${g[options.labelOption]}`}
  filterSelectedOptions
  renderTags={(value, getTagProps) => {
    return value.map((option, index) => {
      return (
        <Chip
          style={{ margin: "3px" }}
          label={option.nombre_completo}
          avatar={<Avatar alt="Natacha" src={option.photoUrl} />}
          {...getTagProps({ index })}
        />
      );
    });
    // </div>
  }}
  renderInput={(params) => {
    return (
      <TextField
        {...params}
        placeholder="Favorites"
        variant="outlined"
        label={props.label}
      />
    );
  }}
/>
  return !loadResponsables ? (
    <Autocomplete
      label={props.label}
      value={value}
      multiple
      id="tags-outlined"
      onChange={(_ev, newValue, reason) => {
        console.log(newValue);
        props.onChange(newValue ?? {});
        setValue(newValue);
        //props.onChange(newValue.map(getOptionName));
      }}
      renderGroup={renderGroup}
      loading={loadResponsables}
      options={optionsUsuarios}
      getOptionSelected={(option, value) => {
        //console.log(value)

        if (value.uid === option.uid) {
          return true;
        } else {
          return false;
        }
      }}
      getOptionLabel={(g) => `${g[options.labelOption]}`}
      filterSelectedOptions
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              style={{ margin: "3px" }}
              label={option.nombre_completo}
              avatar={<Avatar alt="Natacha" src={option.photoUrl} />}
              {...getTagProps({ index })}
            />
          );
        });
        // </div>
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Favorites"
            variant="outlined"
            label={props.label}
          />
        );
      }}
    />
  ) : (
    <CircularProgress />
  );
};

const SelectItem = (props) => {
  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState(options.enumOptions);
  const [value, setValue] = useState(
    typeof props.value == "undefined"
      ? null
      : { label: props.value, value: props.value }
  );
  console.log(props.value);

  return (
    <Autocomplete
      label={props.label}
      value={value}
      autoSelect={true}
      //onChange={(event) => props.onChange(event.target.value)}
      onChange={(_ev, newValue, reason) => {
        props.onChange(newValue.value ?? {});
        setValue(newValue);

        //props.onChange(newValue.map(getOptionName));
      }}
      getOptionSelected={(option, value) => {
        return value === option.value;
      }}
      options={optionsUsuarios}
      getOptionLabel={(g) => {
        return `${g.label}`;
      }}
      renderInput={(params) => {
        return (
          <TextField
            label={props.label}
            variant="outlined"
            required={props.required}
            {...params}
          />
        );
      }}
    />
  );
};

const TextFieldCyd = (props) => {
  const { options } = props;
  return (
    <TextField
      label={props.label}
      value={props.value}
      rows={options.rows}
      multiline={options.multiline}
      variant="outlined"
      required={props.required}
      fullWidth
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};

export default AdminProcesoEliminacionAdd;
