import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Store from "./redux/store/store";
import { SnackbarProvider } from "notistack";
import App from "./App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";

const theme = createMuiTheme({
	typography: {
		fontSize: 14,
		htmlFontSize: 17,
		fontFamily: ["Open Sans", "sans-serif"]
	},
	palette: {
		primary: {
			light: "#a64c46",
			main: "#731f1f",
			dark: "#430000",
			contrastText: "#ffffff",
		},
		secondary: {
			light: "#4f5b62",
			main: "#263238",
			dark: "#000a12",
			contrastText: "#ffffff",
		},
		text: {
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)",
		},
	},
});

Sentry.init({
	dsn: "https://d86fe8ebb7dc4c3b871724b965d00ad3@o4504243009093632.ingest.sentry.io/4505041159651328",
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	enabled: true,
});

const MainContent = (
	<Provider store={Store}>
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<BrowserRouter>
					<SnackbarProvider>
						<App />
					</SnackbarProvider>
				</BrowserRouter>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	</Provider>
);

ReactDOM.render(MainContent, document.getElementById("root"));