import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  Backdrop,
  Chip,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import SeccionView from "../nuevov3/components/SeccionView";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import HeaderCierre from "./componentes/header_cierre";
import { green } from "@material-ui/core/colors";
import ItemCierre from "./componentes/item_cierre";
import { obtenerItemsProcesos } from "../../adminProcesoEliminacion/requestItem";
import { useSelector } from "react-redux";
import {
  eliminarContrato,
  eliminarContratoDefinitivo,
  obtenerContrato,
  envioNotificacionContrato,
} from "../requestContrato";
import { useSnackbar } from "notistack";
import { Close } from "@material-ui/icons";
import { generarCodigoContratoView } from "./page";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    borderRadius: "15px",
    margin: 33,
    backgroundColor: "rgb(255,255,255,0.5)",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function DetalleDeCierre(props) {
  const classes = useStyles();
  const [loadingEnvio, setLoadingEnvio] = React.useState(false);
  const {
    openDialog,
    onClickCloseDialog,
    setOpenDialog,
    contrato,
    hideCierreContrato,
  } = props;
  const [open, setOpen] = React.useState(openDialog);
  const [itemLoading, setItemLoading] = React.useState(false);
  const [listado, setLitado] = React.useState([]);
  const [contratoActual, setContratoActual] = React.useState(null);
  const [eliminarOpen, setEliminarOpen] = React.useState(false);
  const [envioMensajeOpen, setEnvioMensajeOpen] = React.useState(false);
  const [itemSelectEnvio, setItemSelectEnvio] = React.useState(null);
  const [txtEnvioCorreo, settxtEnvioCorreo] = React.useState("null");

  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  const [listadoFinanciero, setLitadoFinanciero] = React.useState([]);
  const [listadoOperaciones, setLitadoOperaciones] = React.useState([]);
  const [listadoComercial, setLitadoComercial] = React.useState([]);
  const [listadoPersonas, setLitadoPersonas] = React.useState([]);

  const [totalEstado, setTotalEstados] = React.useState([]);
  const [canFinalizado, setCanFinalizado] = React.useState(false);
  const notistack = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [porcentajeAvance, setPorcentajeAvance] = React.useState(0);

  useEffect(() => {
    obtenerContratiActual();
    console.log("obtiene contrato ");
  }, [openDialog]);

  useEffect(() => {
    settxtEnvioCorreo(
      itemSelectEnvio?.mensaje_notificacion
        ? itemSelectEnvio?.mensaje_notificacion
        : ""
    );
  }, [itemSelectEnvio]);

  useEffect(() => {
    getInfoContrato();
  }, [contratoActual]);

  useEffect(() => {
    calcularPorcentaje();
    console.log("Calcla % ");
    habilitatCierre();
    console.log("Habilita cierre");
  }, [totalEstado]);

  const obtenerContratiActual = async () => {
    if (contrato) {
      obtenerContrato(contrato._id).then((contrato) => {
        setContratoActual(contrato);
      });
    }
  };
  const getInfoContrato = async () => {
    obtenerItemsProcesos()
      .then((result) => {
        if (contrato) {
          let financiero = [];
          let operaciones = [];
          let comercial = [];
          let personas = [];
          let mapEstados = result.map((item) => {
            let resultMap = {};
            if (contratoActual?.proceso_de_finalizacion) {
              resultMap = contratoActual.proceso_de_finalizacion.find(
                (estado) => estado.id_proceso === item._id
              );
            }

            if (item.grupo === "Financiero") {
              financiero.push({ ...item, ...resultMap });
            } else if (item.grupo === "Operaciones") {
              operaciones.push({ ...item, ...resultMap });
            } else if (item.grupo === "Comercial") {
              comercial.push({ ...item, ...resultMap });
            } else if (item.grupo === "Personas") {
              personas.push({ ...item, ...resultMap });
            }

            return {
              ...resultMap,
              ...item,
            };
          });

          setLitadoFinanciero(financiero);
          setLitadoOperaciones(operaciones);
          setLitadoComercial(comercial);
          setLitadoPersonas(personas);
          setTotalEstados(mapEstados);

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleOnchangeItem = (loading = false) => {
    console.log("contrato actualizado");
    if (!loading) {
      obtenerContratiActual();
      getInfoContrato();
    } else {
    }
    setItemLoading(loading);
  };

  const handleEnviarMensaje = () => {
    setLoadingEnvio(true);
    envioNotificacionContrato(contrato._id, txtEnvioCorreo)
      .then((result) => {
        console.log(result);
        setLoadingEnvio(false);
        setEnvioMensajeOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setEnvioMensajeOpen(false);
        setLoadingEnvio(false);
      });
  };
  const handleEliminar = () => {
    eliminarContratoDefinitivo(contrato, usuarioSesion)
      .then((response) => {
        console.log("Contrato eliminado exitosamente.", response);
        let mensajeExito = notistack.enqueueSnackbar(
          "Contrato eliminado exitosamente.",
          {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .catch((error) => {
        console.error("Error al intentar eliminar el contrato", error);
        let mensajeError = notistack.enqueueSnackbar(
          "Error al intentar eliminar el contrato",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .finally(() => {
        setEliminarOpen(false);
        setOpenDialog(false);
      });
  };
  const timer = React.useRef();
  /**
   * Handler para cancelar la eliminación.
   */
  const handleCancelarEliminar = () => {
    setEliminarOpen(false);
  };
  const handleCancelarEnvio = () => {
    setEnvioMensajeOpen(false);
    settxtEnvioCorreo("");
  };

  const handleChangeMensaje = (event) => {
    settxtEnvioCorreo(event.target.value);
  };

  const habilitatCierre = () => {
    console.log(contratoActual);
    console.log(totalEstado);
    let itemObligatorios = totalEstado.filter((item) => item.is_obligatorio);
    let puedeCerrar = itemObligatorios.every((item) => {
      let aux;
      console.log(typeof item.is_cerrado === "undefined");
      typeof item.is_cerrado === "undefined"
        ? (aux = false)
        : (aux = item.is_cerrado);
      return aux === true;
    });
    console.log(itemObligatorios);
    setCanFinalizado(puedeCerrar);
    console.log(puedeCerrar);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const calcularPorcentaje = () => {
    if (contratoActual) {
      let cantFinalizados = totalEstado.filter((item) => item.is_cerrado);

      let per = (cantFinalizados.length * 100) / totalEstado.length;
      console.log(per, contratoActual);
      setPorcentajeAvance(per);
    }
  };

  const generarEstadoContratoView = () => {
    let color = ["#2ecc71", "#f1c40f", "#e67e22", "#c0392b"];
    let index = 3;
    if (contratoActual) {
      if (porcentajeAvance < 30) {
        index = 3;
      } else if (porcentajeAvance >= 31 && porcentajeAvance <= 60) {
        index = 2;
      } else if (porcentajeAvance >= 61 && porcentajeAvance < 100) {
        index = 1;
      } else if (porcentajeAvance >= 75) {
        if (canFinalizado) {
          index = 0;
        } else {
          index = 1;
        }
      }

      return (
        <Chip
          label={porcentajeAvance.toFixed(1) + " % "}
          style={{ background: color[index], color: "#fff" }}
        />
      );
    } else {
      return <Container></Container>;
    }
  };

  let color = ["#2ecc71", "#c0392b"];

  const getSeccion = (items, nombre) => {
    return items.length !== 0 ? (
      <SeccionView
        borderColor="#731f1f"
        nombreSeccion={nombre}
        marginBottom={10}
      >
        <HeaderCierre />
        {items.map((item, index) => {
          let usuario = item.responsables.find(
            (e) => e.uid === usuarioSesion.uid
          );

          return (
            <ItemCierre
              key={item._id}
              itemCierre={{
                id: item._id,
                area: item.area,
                proceso: item.proceso,
                detalle: item.detalle,
                responsables: item.responsables,
                estado: item.is_cerrado,
                is_cerrado:
                  typeof item.is_cerrado !== "undefined"
                    ? item.is_cerrado
                    : false,
                cerrado_por: item.cerrado_por,
                can_notificar: item.can_notificar,
                mensaje_notificacion: item.mensaje_notificacion,
              }}
              onClickSendInfo={(item) => {
                console.log(item);
                setItemSelectEnvio(item);
                setEnvioMensajeOpen(true);
              }}
              onChange={handleOnchangeItem}
              contrato={props.contrato}
              canFinalizarEtapa={usuario ? true : false}
            />
          );
        })}
      </SeccionView>
    ) : (
      <Container />
    );
  };

  return (
    <div>
      <Dialog
        onClose={onClickCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth={true}
        maxWidth={"xl"}
        PaperProps={{ style: { borderRadius: "15px" } }}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClickCloseDialog}>
          {contratoActual?.codigo &&
            generarCodigoContratoView(contratoActual.codigo)}{" "}
          | Estatus del Proceso de Cierre | Avance {generarEstadoContratoView()}
        </DialogTitle>
        <DialogContent dividers>
          <>
            <Grid
              container
              direction="column"
              justifycontent="flex-start"
              alignItems="flex-start"
            >
              <Grid item container spacing={0}>
                <Grid item xs={12} xl={12}>
                  {getSeccion(listadoFinanciero, "Financiero")}
                  {getSeccion(listadoOperaciones, "Operaciones")}
                  {getSeccion(listadoPersonas, "Personas")}
                  {getSeccion(listadoComercial, "Comercial")}
                </Grid>
              </Grid>
              <Grid item>
                {!hideCierreContrato && (
                  <Button
                    onClick={() => {
                      setEliminarOpen(true);
                    }}
                    variant="contained"
                    color="primary"
                    disabled={!canFinalizado}
                    style={{ borderRadius: 15 }}
                  >
                    Cerrar Contrato
                  </Button>
                )}
              </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={itemLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        </DialogContent>
      </Dialog>
      <Dialog
        open={eliminarOpen}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { borderRadius: "15px" } }}
      >
        <DialogTitle>Cerrar Contrato</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Se procedera a realizar el cierre definitivo del contrato. ¿Desea
            Continuar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEliminar} variant="contained"  style={{ borderRadius: 15 }} color="primary">
            Si, cerrar contrato
          </Button>
          <Button autoFocus onClick={handleCancelarEliminar} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={envioMensajeOpen}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth={"xs"}
        PaperProps={{ style: { borderRadius: "15px" } }}
      >
        <DialogTitle>Enviar Mensaje</DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item>
              <Typography>Se enviar mensaje a responsables.</Typography>
            </Grid>

            <Grid item>
              <TextField
                id="outlined-multiline-static"
                label="Mensaje"
                multiline
                rows={4}
                fullWidth
                onChange={handleChangeMensaje}
                value={txtEnvioCorreo}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={loadingEnvio}
              onClick={handleEnviarMensaje}
              style={{ borderRadius: 15 }}
            >
              Enviar mensaje
            </Button>
            {loadingEnvio && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>

          <Button autoFocus onClick={handleCancelarEnvio} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
