import React, { useEffect, useState } from "react";
import Page from "./page";
import {
  obtenerContratos,
  agregarSolicitudContrato,
  obtenerGerencias,
  obtenerResponsables,
  obtenerSociedades,
  obtenerMandantes,
  obtenerComunas,
  obtenerRegiones,
} from "./requestSolicitud";
import { useHistory } from "react-router-dom";
import { date, number, object, string } from "yup";
import { useFormik } from "formik";
import Moment from "moment";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as ROUTES from "../../constants/routes";
import { useSelector } from "react-redux";

function Index(props) {
  const [Correlativo, SetCorrelativo] = useState();
  const [Correlativos, SetCorrelativos] = useState();
  const [Gerencias, SetGerencias] = useState();
  const [Responsables, SetResponsables] = useState();
  const [Sociedades, SetSociedades] = useState();
  const [Mandantes, SetMandantes] = useState();
  const [isCentroCosto, setIsCentroCosto] = useState(false);

  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);

  const history = useHistory();
  const notistack = useSnackbar();

  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  useEffect(() => {
    Promise.all([
      obtenerContratos(),
      obtenerGerencias(),
      obtenerResponsables(),
      obtenerSociedades(),
      obtenerMandantes(),
      obtenerComunas(),
      obtenerRegiones(),
    ])
      .then((responses) => {
        SetCorrelativos(responses[0].map((c) => c.codigo.split("-")[2]));
        SetGerencias(responses[1]);
        SetResponsables(responses[2]);
        SetSociedades(responses[3]);
        SetMandantes(responses[4]);
        setComunas(responses[5]);
        setRegiones(responses[6]);
      })
      .catch((error) => {
        console.error(error);
        SetCorrelativo("");
        SetGerencias([]);
        SetResponsables([]);
        SetSociedades([]);
        SetMandantes([]);
      });
  }, []);

  useEffect(() => {
    if (Correlativos) {
      let nuevoCorrelativo = ObtenerNuevoCorrelativo(Correlativos);
      SetCorrelativo(nuevoCorrelativo);
    }
  }, [Correlativos]);

  /**
   * Valores iniciales del formulario.
   */
  const initials = {
    nombre: "",

    estado: "",
    region: "",
    comuna: "",
    direccion: "",
    monto_del_contrato: "",
    fecha_inicio: Moment(),
    fecha_termino_estimada: null,
    fecha_adjudicacion: Moment(),
    fecha_termino_real: null,
    tipo_de_servicio: "",
    responsable_ref: "",
    responsable_admin_ref: "",
    gerencia_ref: "",
    mandante_ref: "",
    sociedad_ref: "",
    codigo: {
      gerencia: "",
      responsable: "",
      correlativo: Correlativo ? Correlativo : "",
    },
  };

  /**
   * Esquema de validación para el contrato.
   */
  const validation = object().shape({
    nombre: string()
      .min(3, `El nombre debe tener al menos 7 caracteres.`)
      .max(70, `El nombre debe tener a lo más 70 caracteres.`)
      .required("El nombre es requerido."),
    codigo: object({
      gerencia: string()
        .length(3, "La gerencia incorrecta.")
        .required("La gerencia requerida."),
      responsable: string()
        .length(1, "El correlativo de responsable incorrecto.")
        .required("El correlativo de responsable requerido."),
      correlativo: number()
        .typeError("El correlativo de contrato debe ser número.")
        .integer("El correlativo de contrato debe ser un número natural.")
        .min(0, `El correlativo de contrato debe ser mayor a 0.`)
        .max(99999, `El correlativo de contrato debe ser menor a 99999.`),
      // .required("El correlativo de contrato es requerido."),
    }),

    region: string().required("El Region es requerido."),
    comuna: string().required("El Comuna es requerido."),
    direccion: string().required("El Direccion es requerido."),
    monto_del_contrato: string().required("El Monto del contrato es requerido."),
    fecha_inicio: date().optional().required("Fecha de inicio es requerida."),
    fecha_termino_estimada: date().nullable().optional(),
    fecha_adjudicacion: date().nullable().optional(),
    tipo_de_servicio: string().required("Tipo de Servicio es requerido."),
    responsable_ref: string().required("El responsable es requerido."),
    gerencia_ref: string().required("La gerencia es requerida."),
    mandante_ref: string().required("El mandante es requerido."),
    sociedad_ref: string().required("La sociedad es requerida."),
  });

  /**
   * Configuración de Formik Hook.
   */
  const formik = useFormik({
    initialValues: initials,
    validationSchema: validation,
    // enableReinitialize: true,
    // submission logic
    onSubmit: (values, { resetForm }) => {
      // submission logic
      return handleAgregar(values);
    },
    onReset: (values, { resetForm }) => {
      console.log(values);
    },
    // onSubmit: (values, helper) => {
    //   return handleAgregar(values);
    // },
  });

  useEffect(() => {
    let filtro = null;
    formik.values.responsable_ref = "";
    if (formik && formik.values && formik.values.gerencia_ref) {
      let gerenciaID = formik.values.gerencia_ref;
      let gerencia = Array.from(Gerencias).find((g) => g._id === gerenciaID);
      filtro = `gerencia=${gerencia.sigla}`;
    }
    let gerenciaID = formik.values.gerencia_ref;
    if (gerenciaID) {
      let gerencia = Array.from(Gerencias).find((g) => g._id === gerenciaID);
      let usuarioID = gerencia.responsable_ref.persona.usuario_id;
      let gerentess = null;
      obtenerResponsables(`persona.usuario_id=${usuarioID}`)
        .then((gerentes) => {
          gerentess = gerentes;
        })
        .then(() => obtenerResponsables(filtro))
        .then((responsables) => {
          if (responsables) {
            if (
              !responsables.find((r) =>
                gerentess.find(
                  (g) => g.persona.usuario_id === r.persona.usuario_id
                )
              )
            ) {
              responsables = responsables.concat(gerentess);
            }

            SetResponsables(responsables);
          } else {
            SetResponsables(gerentess);
          }
        })
        .catch((error) => {
          console.error(error);
          SetResponsables([]);
        });
    }
  }, [formik.values.gerencia_ref, Gerencias]);

  /**
   * Handler para agregar contrato.
   */
  const handleAgregar = (values) => {
    console.log(values);
    let correlativo = values.codigo.correlativo
      ? values.codigo.correlativo
      : Correlativo;

    if (values.fecha_termino_estimada === null) {
      //Si no se asigno una fecha estimada de término, se asigna 2099 para indefinido.
      values.fecha_termino_estimada = Moment("31/12/2099", "DD/MM/YYYY");
    }
    let check = Array.from(Correlativos).includes(correlativo);
    if (check) {
      //Error en caso de correlativo repetido.
      let mensajeCorrelativoRepetido = notistack.enqueueSnackbar(
        "El correlativo ingresado ya está siendo utilizado.",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          action: (
            <IconButton
              onClick={() =>
                notistack.closeSnackbar(mensajeCorrelativoRepetido)
              }
            >
              <Close />
            </IconButton>
          ),
        }
      );
    } else {
      //Se construye y asigna el código de contrato.

      values.codigo = isCentroCosto
        ? `${values.codigo.gerencia}-${values.codigo.correlativo}`
        : `${values.codigo.gerencia}-${values.codigo.responsable}-${correlativo}`;
      values.ingresado_por = {
        nombre: usuarioSesion.nombre,
        email: usuarioSesion.email,
        photoUrl: usuarioSesion.photoUrl,
      };
      values.is_eliminado = false
			
      //Se agrega el contrato.
      agregarSolicitudContrato(values)
        .then((response) => {
          console.log("Contrato agregado exitosamente.", response);
          let mensajeExito = notistack.enqueueSnackbar(
            "Contrato agregado exitosamente.",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              action: (
                <IconButton
                  onClick={() => notistack.closeSnackbar(mensajeExito)}
                >
                  <Close />
                </IconButton>
              ),
            }
          );
        })
        .catch((error) => {
          console.error("Error al intentar agregar el contrato.", error);
          let mensajeError = notistack.enqueueSnackbar(
            "Error al intentar agregar el contrato.",
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              action: (
                <IconButton
                  onClick={() => notistack.closeSnackbar(mensajeError)}
                >
                  <Close />
                </IconButton>
              ),
            }
          );
        })
        .finally(() => {
          history.push(ROUTES.LIST_SOLICITUD_NUEVO_CONTRATO);
        });
    }
  };

  /**
   * Handler para limpiar el formulario.
   */
  const handleLimpiar = () => {
    formik.resetForm(initials);
  };

  const handleCentroCosto = (value) => {
    setIsCentroCosto(value.target.checked);
  };

  return (
    <Page
      correlativo={Correlativo}
      gerencias={Gerencias}
      responsables={Responsables}
      sociedades={Sociedades}
      mandantes={Mandantes}
      handle_limpiar={handleLimpiar}
      formik={formik}
      onChangeCentroCosto={handleCentroCosto}
      isCentroCosto={isCentroCosto}
      comunas={comunas}
      regiones={regiones}
    />
  );
}

/**
 * Método encargado de estimar el nuevo correlativo para el contrato.
 * @param {*} contratos
 */
function ObtenerNuevoCorrelativo(contratos) {
  //Se obtienen los códigos de los contratos, se separa por "-" y se obtiene el numero.
  let correlativos = Array.from(contratos).map((c) => Number(c));
  //Se calcula el mayor.
  let ultimoCorrelativo = Math.max.apply(null, correlativos);
  //Se retorna el siguiente del mayor.
  return ultimoCorrelativo + 1;
}

export default Index;
