import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";

const SelectorGenerico = (props) => {
  const { options } = props;

  const [optionsGerencias, setOptionsGerencias] = useState(options.datos);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(
    typeof props.value === "undefined" ? "" : props.value
  );

  const [valueSelected, setValueSelected] = useState(
    typeof props.value === "undefined" ? "" : props.value
  );

  useEffect(() => {
    options.fetch.call().then((result) => {

      setOptionsGerencias(result);
      setLoading(false);
    });


  }, []);

  useEffect(() => {

    props.onChange(value ?? "");
  }, [value]);



  return (
    <Autocomplete
      label={props.label}
      value={valueSelected}
      multiple={false}
      loading={loading}
      id={props.id}
      disabled={props.disabled}
      onChange={(_ev, newValue, reason) => {

        props.onChange(newValue ?? "");
        setValueSelected(newValue);
        setValue(newValue ?? "");
      }}
      //inputValue={valueSelected ? `${valueSelected.label}` : ""}
      getOptionSelected={(option, value) => {
        return option._id === value._id;
      }}
      options={optionsGerencias}
      getOptionLabel={(g) => `${Object.byString(g, options.labelOption)}`}
      filterSelectedOptions={false}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Favorites"
            variant="outlined"
            label={props.label}
          />
        );
      }}
    />
  );
};

export default SelectorGenerico;

Object.byString = function (o, s) {
  try {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch (error) {
    console.error(error)
    return " ---";
  }
};
