import React, {  useState } from "react";
import Page from "./page";
import XLSX from "xlsx";
import Moment from "moment";
import { useSolicitudContratosVigentes } from "../hooks";
import { useSelector } from "react-redux";
import { eliminarSolicitudDeContrato } from "../requestSolicitud";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
  // const [Contratos, SetContratos] = useState();
  const [DialogOpen, SetDialogOpen] = useState(false);
  const [ItemSeleccionado, SetItemSeleccionado] = useState(null);
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));
  const [{ contratos: Contratos },refetch] = useSolicitudContratosVigentes(
    usuarioSesion,
	true,
	
  );
  console.log(Contratos)
  const notistack = useSnackbar();

  const handleShowDialog = (contratoID) => {
    SetDialogOpen(true);
    SetItemSeleccionado(contratoID);
  };

  /**
   * Handler para eliminar un contrato.
   */
  const handleEliminar = () => {

    eliminarSolicitudDeContrato(ItemSeleccionado)
      .then((response) => {
        console.log("Contrato eliminado exitosamente.", response);
        refetch();
        let mensajeExito = notistack.enqueueSnackbar(
          "Contrato eliminado exitosamente.",
          {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .catch((error) => {
        console.error("Error al intentar eliminar el contrato", error);
        let mensajeError = notistack.enqueueSnackbar(
          "Error al intentar eliminar el contrato",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .finally(() => {
        SetDialogOpen(false);
        SetItemSeleccionado(null);
      });
  };

  /**
   * Handler para cancelar la eliminación.
   */
  const handleCancelarEliminar = () => {
    SetDialogOpen(false);
    SetItemSeleccionado(null);
  };

  /**
   * Hanlder para exportar datos con formato Excel.
   */
  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    console.log();
    let exportData = Contratos.map((c) => {
	  console.log(c);
	  
      return {
        codigo: c.codigo,
        nombre: c.nombre,
        gerencia: c.gerencia_ref.sigla,
        empresa: c.sociedad_ref.nombre,
        fecha_inicio: Moment(c.fecha_inicio).format("DD/MM/YYYY HH:MM"),
        fecha_estimada_termino: Moment(c.fecha_termino_estimada).format(
          "DD/MM/YYYY HH:MM"
        ),
        encargado: c.responsable_ref.persona.nombre_completo,
		encargado_talana: c.responsable_admin_ref? c.responsable_admin_ref.persona.nombre_completo:c.responsable_ref.persona.nombre_completo,
		rut_encargado_talana: c.responsable_admin_ref? c.responsable_admin_ref.persona.run:c.responsable_ref.persona.run,
		email_encargado_talana: c.responsable_admin_ref? c.responsable_admin_ref.persona.contacto.email:c.responsable_ref.persona.contacto.email,
        mandante: c.mandante_ref.nombre,
      };
    });
    // let exportData = Contratos.map((c) => ({
    //   codigo: c.codigo,
    //   nombre: c.nombre,
    //   gerencia: c.gerencia_ref.sigla,
    //   empresa: c.sociedad_ref.nombre,
    //   fecha_inicio: Moment(c.fecha_inicio).format("DD/MM/YYYY HH:MM"),
    //   fecha_estimada_termino: Moment(c.fecha_termino_estimada).format(
    //     "DD/MM/YYYY HH:MM"
    //   ),
    //   encargado: c.responsable_ref.persona.nombre_completo,
    //   encargado_talana: c.responsable_admin_ref.persona.nombre_completo,
    //   mandante: c.mandante_ref.nombre,
    // }));
    let ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, "Contratos");
    XLSX.writeFile(wb, "contratos.xlsx");
  };

  return (
    <Page
      usuario={usuarioSesion}
      contratos={Contratos}
      dialog_open={DialogOpen}
      handle_show_dialog={handleShowDialog}
      handle_eliminar={handleEliminar}
      handle_cancelar={handleCancelarEliminar}
      handle_export={handleExport}
    />
  );
}


export default Index;
