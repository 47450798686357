import React, { useEffect, useState } from "react";
import Page from "./page";
import { getAllPerfil } from "../requestPerfiles";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { actionOpenAddPerfil, actionRefreshTablePerfil, actionOpenEditPerfil, actionOpenDeletePerfil, actionOpenViewPermisoPerfil } from "../actionsPerfiles";
export default function ListadoPerfiles() {

  const { resfreshTable } = useSelector(state => ({
    resfreshTable: state.reducerPerfil.refreshTablePerfil
  }));
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const cargarData = () => {

    const CancelToken = axios.CancelToken;
    let cancelRequest = null;
    let cancelToken = new CancelToken(function executor(c) {
      cancelRequest = c;
    });

    getAllPerfil({}, cancelToken)
      .then((response) => {
        dispatch(actionRefreshTablePerfil(false));
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data);
        } else if (response.status === 204) {
          setIsLoading(false);
          setData([]);
        } else {
          setIsLoading(false);
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setData([]);
      });
    return cancelRequest;
  };

  useEffect(() => {
    if (resfreshTable || isLoading) {
      setIsLoading(true);
      const cancelRequest = cargarData();
      return () => {
        if (cancelRequest) cancelRequest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resfreshTable]);

  const onClickOpenAddPerfil = () => dispatch(actionOpenAddPerfil(true));
  const onClickOpenDeletePerfil = (rowData) => dispatch(actionOpenDeletePerfil(true, rowData));
  const onClickOpenEditPerfil = (rowData) => dispatch(actionOpenEditPerfil(true, rowData));
  const onClickOpenViewPermisosPerfil = (rowData) => dispatch(actionOpenViewPermisoPerfil(true, rowData));


  return (
    <Page
      data={data}
      isLoading={isLoading}
      onClickOpenAddPerfil={onClickOpenAddPerfil}
      onClickOpenDeletePerfil={onClickOpenDeletePerfil}
      onClickOpenEditPerfil={onClickOpenEditPerfil}
      onClickOpenViewPermisosPerfil={onClickOpenViewPermisosPerfil}
    />
  );
}