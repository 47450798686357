import React, { Fragment } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import Moment from "moment";

function Page(props) {
  const {
    correlativo,
    gerencias,
    responsables,
    sociedades,
    mandantes,
    handle_limpiar,
    formik,
    onChangeCentroCosto,
    isCentroCosto,
  } = props;

  const classes = useStyle();
  const {
    values,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,

    errors,
    touched,
  } = formik;

  if (!gerencias || !responsables || !sociedades || !mandantes) {
    return (
      <Box
        display="flex"
        justifycontent="center"
        alignItems="center"
        height="100%"
      >
        {/* SI AUN NO CARGAN LOS DATOS */}
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Contratos</Typography>
        <Link to="/contratos">Contratos</Link>
        <Typography color="textPrimary">Ingresar Contrato</Typography>
      </Breadcrumbs>
      <Paper className={classes.card}>
        {/* CODIGO ACTUAL Y NUEVO */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* CODIGO ACTUAL */}
            <Typography variant="h6" align="center">
              Código Actual
            </Typography>
            <Typography variant="h4" align="center">
              {!isCentroCosto
                ? `${
                    values.codigo.gerencia
                      ? values.codigo.gerencia
                      : "[Gerencia]"
                  }-${
                    values.codigo.responsable
                      ? values.codigo.responsable
                      : "[Responsable]"
                  } 
			  -${values.codigo.correlativo ? values.codigo.correlativo : correlativo}`
                : `${
                    values.codigo.gerencia
                      ? values.codigo.gerencia
                      : "[Gerencia]"
                  }-${
                    values.codigo.responsable
                      ? values.codigo.correlativo
                      : "[Responsable]"
                  } `}
            </Typography>
            <Typography align="center" className={classes.caption_text_top}>
              Correlativo estimado, puede variar.
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          {/* CAMPOS DEL CONTRATO */}
          <Grid container spacing={3}>
            <Grid item xs={6} xl={3}>
              {/* CORRELATIVO CONTRATO */}
              <TextField
                name="codigo.correlativo"
                label="Correlativo"
                value={values.codigo.correlativo}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                error={
                  touched.codigo &&
                  touched.codigo.correlativo &&
                  errors.codigo &&
                  errors.codigo.correlativo &&
                  Boolean(errors.codigo.correlativo)
                }
                helperText={
                  touched.codigo &&
                  touched.codigo.correlativo &&
                  errors.codigo &&
                  errors.codigo.correlativo
                    ? errors.codigo.correlativo
                    : ""
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCentroCosto}
                    onChange={onChangeCentroCosto}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Área de apoyo"
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* GERENCIA */}
              <Autocomplete
                name="gerencia_ref"
                loading={!gerencias}
                options={gerencias}
                onChange={(event, value) => {
                  setFieldValue("gerencia_ref", value ? value._id : "");
                  setFieldValue("codigo.gerencia", value ? value.sigla : "");
                }}
                getOptionLabel={(g) => `${g.sigla} - ${g.nombre}`}
                renderInput={(params) => (
                  <TextField
                    label="Gerencia"
                    variant="outlined"
                    required
                    error={
                      touched.gerencia_ref &&
                      errors.gerencia_ref &&
                      Boolean(errors.gerencia_ref)
                    }
                    helperText={
                      touched.gerencia_ref && errors.gerencia_ref
                        ? errors.gerencia_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* MANDANTE */}
              <Autocomplete
                name="mandante_ref"
                loading={!mandantes}
                options={mandantes}
                onChange={(event, value) =>
                  setFieldValue("mandante_ref", value ? value._id : "")
                }
                getOptionLabel={(m) => `[${m.rut_formateado}] ${m.nombre}`}
                renderInput={(params) => (
                  <TextField
                    label="Mandante"
                    variant="outlined"
                    required
                    error={
                      touched.mandante_ref &&
                      errors.mandante_ref &&
                      Boolean(errors.mandante_ref)
                    }
                    helperText={
                      touched.mandante_ref && errors.mandante_ref
                        ? errors.mandante_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* NOMBRE CONTRATO */}
              <TextField
                name="nombre"
                label="Nombre"
                value={values.nombre}
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                error={
                  touched.nombre && errors.nombre && Boolean(errors.nombre)
                }
                helperText={
                  touched.nombre && errors.nombre ? errors.nombre : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* SOCIEDAD */}
              <Autocomplete
                name="sociedad_ref"
                loading={!sociedades}
                options={sociedades}
                onChange={(event, value) =>
                  setFieldValue("sociedad_ref", value ? value._id : "")
                }
                getOptionLabel={(s) => s.nombre}
                renderInput={(params) => (
                  <TextField
                    label="Sociedad"
                    variant="outlined"
                    required
                    error={
                      touched.sociedad_ref &&
                      errors.sociedad_ref &&
                      Boolean(errors.sociedad_ref)
                    }
                    helperText={
                      touched.sociedad_ref && errors.sociedad_ref
                        ? errors.sociedad_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* RESPONSABLE */}
              <Autocomplete
                name="responsable_ref"
                loading={!responsables}
                options={responsables}
                onChange={(event, value) => {
                  setFieldValue("responsable_ref", value ? value._id : "");
                  setFieldValue(
                    "codigo.responsable",
                    value ? value.correlativo : ""
                  );
                }}
                getOptionLabel={(responsable) => {
                  console.log(responsable);
                  return (
                    responsable.persona.nombre_completo +
                    `( ${responsable.gerencia}${
                      responsable.correlativo === "-1"
                        ? ""
                        : "-" + responsable.correlativo
                    } )`
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    label="Responsable"
                    variant="outlined"
                    required
                    error={
                      touched.responsable_ref &&
                      errors.responsable_ref &&
                      Boolean(errors.responsable_ref)
                    }
                    helperText={
                      touched.responsable_ref && errors.responsable_ref
                        ? errors.responsable_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* RESPONSABLE */}
              <Autocomplete
                name="responsable_admin_ref"
                value={Array.from(responsables).find(
                  (r) => r._id === values.responsable_admin_ref
                )}
                loading={!responsables}
                options={responsables}
                onChange={(event, value) => {
                  setFieldValue(
                    "responsable_admin_ref",
                    value ? value._id : ""
                  );
                }}
                getOptionLabel={(responsable) =>
                  responsable.persona.nombre_completo
                }
                renderInput={(params) => (
                  <TextField
                    label="Responsable Talana"
                    variant="outlined"
                    required
                    error={
                      touched.responsable_admin_ref &&
                      errors.responsable_admin_ref &&
                      Boolean(errors.responsable_admin_ref)
                    }
                    helperText={
                      touched.responsable_admin_ref &&
                      errors.responsable_admin_ref
                        ? errors.responsable_admin_ref
                        : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} xl={3}>
              {/* FECHA DE INICIO */}
              <DatePicker
                name="fecha_inicio"
                value={values.fecha_inicio}
                label="Fecha de Inicio"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                fullWidth
                onChange={(date) => setFieldValue("fecha_inicio", date)}
                error={
                  touched.fecha_inicio &&
                  errors.fecha_inicio &&
                  Boolean(errors.fecha_inicio)
                }
                helperText={
                  touched.fecha_inicio && errors.fecha_inicio
                    ? errors.fecha_inicio
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* FECHA ESTIMADA TERMINO */}
              <DatePicker
                name="fecha_termino_estimada"
                value={values.fecha_termino_estimada}
                label="Fecha Estimada de Término (En blanco para indefinido)"
                format="DD/MM/YYYY"
                maxDate={Moment("31/12/2099", "DD/MM/YYYY")}
                inputVariant="outlined"
                fullWidth
                onChange={(date) =>
                  setFieldValue("fecha_termino_estimada", date)
                }
                error={
                  touched.fecha_termino_estimada &&
                  errors.fecha_termino_estimada &&
                  Boolean(errors.fecha_termino_estimada)
                }
                helperText={
                  touched.fecha_termino_estimada &&
                  errors.fecha_termino_estimada
                    ? errors.fecha_termino_estimada
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} xl={3}>
              {/* ESTADO */}
              <Autocomplete
                name="estado"
                value={values.estado}
                options={["Activo", "Terminado"]}
                onChange={(event, value) =>
                  setFieldValue("estado", value ? value : "")
                }
                renderInput={(params) => (
                  <TextField
                    label="Estado"
                    variant="outlined"
                    required
                    error={
                      touched.estado && errors.estado && Boolean(errors.estado)
                    }
                    helperText={
                      touched.estado && errors.estado ? errors.estado : ""
                    }
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography align="center" className={classes.caption_text_bottom}>
            La información del contrato puede ser editada una vez generado.
          </Typography>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              onClick={handle_limpiar}
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              Limpiar
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Agregar
            </Button>
          </Box>
        </form>
      </Paper>
    </Fragment>
  );
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  caption_text_top: {
    fontSize: 12,
  },
  caption_text_bottom: {
    fontSize: 12,
    marginTop: theme.spacing(3),
  },
}));

export default Page;
