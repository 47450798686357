import React, { Fragment } from "react";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "../../../components/material_table";
import {
  ACTUALIZAR_RESPONSABLE,
  AGREGAR_RESPONSABLE,
  ELIMINAR_RESPONSABLE,
} from "../../../constants/permissions";

function Page(props) {
  const {
    usuario,
    responsables,
    dialog_open,
    handle_show_dialog,
    handle_eliminar,
    handle_cancelar,
    handle_export,
  } = props;

  const history = useHistory();
  const permisos = Array.from(usuario.permisos);
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_RESPONSABLE
  );
  const permisoAgregar = permisos.find((p) => p.nombre === AGREGAR_RESPONSABLE);
  const permisoEliminar = permisos.find(
    (p) => p.nombre === ELIMINAR_RESPONSABLE
  );
  console.log(responsables);

  let columns = [
    {
      title: "Nombre",
      field: "persona.nombre_completo",
	},
	{
		title: "correo",
		field: "persona.contacto.email",
	  },
    {
      title: "RUN",
      field: "persona.run",
    },
    {
      title: "Correlativo",
      field: "correlativo",
    },
    {
      title: "Gerencia",
      field: "gerencia",
    },
  ];

  let actions = [
    {
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, row) => history.push(`/responsable/${row._id}`),
      iconProps: { style: { color: permisoActualizar ? "#757575" : null } },
      // hidden: !permisoActualizar,
      disabled: !permisoActualizar,
    },
    {
      icon: "delete",
      tooltip: "Eliminar",
      onClick: (event, row) => handle_show_dialog(row._id),
      iconProps: { style: { color: permisoEliminar ? "#757575" : null } },
      // hidden: !permisoEliminar,
      disabled: !permisoEliminar,
    },
    {
      icon: "add",
      tooltip: "Agregar",
      onClick: () => history.push("/responsable"),
      isFreeAction: true,
      // hidden: !permisoAgregar,
      disabled: !permisoAgregar,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Responsables</Typography>
        <Typography color="textPrimary">Listado Responsables</Typography>
      </Breadcrumbs>
      <MaterialTable
        title="Listado de Responsables"
        is_loading={!responsables}
        columns={columns}
        data={responsables}
        actions={actions}
        export_function={handle_export}
      />
      <Dialog open={dialog_open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>Eliminar Responsable</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Se debe confirmar la eliminación del responsable.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handle_eliminar} variant="contained" color="primary">
            Eliminar
          </Button>
          <Button autoFocus onClick={handle_cancelar} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Page;
