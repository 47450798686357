import React, { useEffect, useCallback } from "react";
import {
  obtenerContrato,
  obtenerContratos,
  obtenerContratosFinalizados,
  obtenerContratosPorFinalizar,
  obtenerContratosPorGerencia,
} from "../../../services/database/contratos";

import {
  tienePermisosParaVerTodosContrato,
  tienePermisoParaVerContratosEncargado,
  tienePermisosParaVerContratosDeSuGerencia,
  getUserAPI,
  tienePermisosParaVerCentroCosto,
} from "../../../services/database/usuario";



export const useContratosVigentes = (
  usuario,
  verTodoCyd = false,
  verCentroCosto = false
) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [contratos, setContratos] = React.useState([]);
  function fetchData() {
    obtenerContratos(tienePermisosParaVerCentroCosto(usuario)).then(
      async (result) => {
        let filtrado = result.map((item) => {
          let nombre_display =
            item.responsable_ref?.nombre +
            " " +
            item?.responsable_ref?.apellido_paterno +
            " " +
            item?.responsable?.apellido_materno;
          if (item.responsable_ref) {
            item.responsable_ref["nombre_display"] = nombre_display;
          }
          return item;
        });

        if (tienePermisosParaVerTodosContrato(usuario)) {
          setContratos(filtrado);
        } else if (tienePermisosParaVerContratosDeSuGerencia(usuario)) {
          if (!verTodoCyd) {
            setContratos(filtrado);
          } else {
            let filtroUsuario = [];
            if (usuario?.email) {
              let result = await getUserAPI(usuario.uid);
              
              let gerenciaPersona =
                result.contratos_ref[0].codigo.split("-")[0];
              filtroUsuario = filtrado.filter((contrato) => {
                return gerenciaPersona === contrato.gerencia_ref.sigla;
              });
              setContratos(filtroUsuario);
            }
          }
        } else if (tienePermisoParaVerContratosEncargado(usuario)) {
          let filtroUsuario = [];
          if (usuario.uid) {
            filtroUsuario = filtrado.filter((contrato) => {
              return (
                contrato.responsable_ref.persona.usuario_id === usuario.uid
              );
            });
          }
          setContratos(filtroUsuario);
        }

        setLoading(false);
      }
    );
  }
  const refetch = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return [
    {
      error,
      loading,
      contratos,
    },
    refetch,
  ];
};

export const useContratosFinalizados = (
  usuario,
  verTodoCyd = false,
  verCentroCosto = false
) => {
  
  const [loading, setLoading] = React.useState(true);
  const [contratos, setContratos] = React.useState([]);
  function fetchData() {
    obtenerContratosFinalizados(tienePermisosParaVerCentroCosto(usuario)).then(
      async (result) => {
        let filtrado = result.map((item) => {
          let nombre_display =
            item.responsable_ref?.nombre +
            " " +
            item?.responsable_ref?.apellido_paterno +
            " " +
            item?.responsable?.apellido_materno;
          if (item.responsable_ref) {
            item.responsable_ref["nombre_display"] = nombre_display;
          }
          return item;
        });
        if (tienePermisosParaVerTodosContrato(usuario)) {
          setContratos(filtrado);
        } else if (tienePermisosParaVerContratosDeSuGerencia(usuario)) {
          if (!verTodoCyd) {
            setContratos(filtrado);
          } else {
            let filtroUsuario = [];
            if (usuario?.email) {
              let result = await getUserAPI(usuario.uid);
              
              let gerenciaPersona =
                result.contratos_ref[0].codigo.split("-")[0];
              filtroUsuario = filtrado.filter((contrato) => {
                return gerenciaPersona === contrato.gerencia_ref.sigla;
              });
              setContratos(filtroUsuario);
            }
          }
        } else if (tienePermisoParaVerContratosEncargado(usuario)) {
          
          let filtroUsuario = [];
          if (usuario.uid) {
            filtroUsuario = filtrado.filter((contrato) => {
              return (
                contrato.responsable_ref.persona.usuario_id === usuario.uid
              );
            });
          }
          setContratos(filtroUsuario);
        }

        setLoading(false);
      }
    );
  }
  const refetch = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return [
    {
      
      loading,
      contratos,
    },
    refetch,
  ];
};

export const useContratosPorFinalizar = (
  usuario,
  verTodoCyd = false,
  verCentroCosto = false
) => {
  
  const [loading, setLoading] = React.useState(true);
  const [contratos, setContratos] = React.useState([]);
  function fetchData() {
    obtenerContratosPorFinalizar(tienePermisosParaVerCentroCosto(usuario)).then(
      async (result) => {
        
        let filtrado = [];
        if (result !== null) {
          filtrado = result.map((item) => {
            let nombre_display =
              item.responsable_ref?.nombre +
              " " +
              item?.responsable_ref?.apellido_paterno +
              " " +
              item?.responsable?.apellido_materno;
            if (item.responsable_ref) {
              item.responsable_ref["nombre_display"] = nombre_display;
            }
            return item;
          });
        }
        if (tienePermisosParaVerTodosContrato(usuario)) {
          setContratos(filtrado);
        } else if (tienePermisosParaVerContratosDeSuGerencia(usuario)) {
          if (!verTodoCyd) {
            setContratos(filtrado);
          } else {
            let filtroUsuario = [];
            if (usuario?.email) {
              let result = await getUserAPI(usuario.uid);
              
              let gerenciaPersona =
                result.contratos_ref[0].codigo.split("-")[0];
              filtroUsuario = filtrado.filter((contrato) => {
                return gerenciaPersona === contrato.gerencia_ref.sigla;
              });
              setContratos(filtroUsuario);
            }
          }
        } else if (tienePermisoParaVerContratosEncargado(usuario)) {
          
          let filtroUsuario = [];
          if (usuario.uid) {
            filtroUsuario = filtrado.filter((contrato) => {
              return (
                contrato.responsable_ref.persona.usuario_id === usuario.uid
              );
            });
          }
          setContratos(filtroUsuario);
        }

        setLoading(false);
      }
    );
  }
  const refetch = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return [
    {
      
      loading,
      contratos,
    },
    refetch,
  ];
};

export const useContrato = (id) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [contrato, setContrato] = React.useState(null);

  useEffect(() => {
    obtenerContrato(id)
      .then((result) => {
        setContrato(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
      });
  }, [id]);

  return {
    error,
    loading,
    contrato,
  };
};

