import React, { Fragment } from "react";
import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "../../../components/material_table";
import { ACTUALIZAR_SOCIEDAD, AGREGAR_SOCIEDAD, ELIMINAR_SOCIEDAD } from "../../../constants/permissions";

function Page(props) {
	const {
		usuario,
		sociedades,
		dialog_open,
		handle_show_dialog,
		handle_eliminar,
		handle_cancelar,
		handle_export,
	} = props;

	const history = useHistory();
	const permisos = Array.from(usuario.permisos);
	const permisoActualizar = permisos.find(p => p.nombre === ACTUALIZAR_SOCIEDAD);
	const permisoAgregar = permisos.find(p => p.nombre === AGREGAR_SOCIEDAD);
	const permisoEliminar = permisos.find(p => p.nombre === ELIMINAR_SOCIEDAD);

	let columns = [
		{
			title: "Código",
			field: "codigo",
		}, {
			title: "RUT",
			field: "rut_formateado",
		}, {
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Giro",
			field: "giro",
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => history.push(`/sociedad/${row._id}`),
			iconProps: { style: { color: permisoActualizar ? "#757575" : null } },
			// hidden: !permisoActualizar,
			disabled: !permisoActualizar,
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => handle_show_dialog(row._id),
			iconProps: { style: { color: permisoEliminar ? "#757575" : null } },
			// hidden: !permisoEliminar,
			disabled: !permisoEliminar,
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/sociedad"),
			isFreeAction: true,
			// hidden: !permisoAgregar,
			disabled: !permisoAgregar,
		},
	];

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Sociedades</Typography>
				<Typography color="textPrimary">Listado Sociedades</Typography>
			</Breadcrumbs>
			<MaterialTable
				title="Listado de Sociedades"
				is_loading={!sociedades}
				columns={columns}
				data={sociedades}
				actions={actions}
				export_function={handle_export}
			/>
			<Dialog
				open={dialog_open}
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogTitle>Eliminar Sociedad</DialogTitle>
				<DialogContent dividers>
					<Typography>Se debe confirmar la eliminación de la sociedad.</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handle_eliminar} variant="contained" color="primary">Eliminar</Button>
					<Button autoFocus onClick={handle_cancelar} color="primary">Cancelar</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default Page;