import React, { Fragment } from "react";
import {
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import Moment from "moment";

import SeccionView from "../contratos/nuevov3/components/SeccionView";

function Page(props) {
  const {
    correlativo,
    gerencias,
    responsables,
    sociedades,
    mandantes,
    handle_limpiar,
    formik,
    isCentroCosto,
    comunas,
    regiones,
  } = props;

  const classes = useStyle();
  const {
    values,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,
    errors,
    touched,
  } = formik;

  const tipo_de_servicio = [
    {
      id: "1",
      nombre: "AIF - Asesoría a la inspección Fiscal",
    },
    {
      id: "2",
      nombre: "AIT - Asesoría a la inspección Técnica de Explotación",
    },
    {
      id: "3",
      nombre: "GTO - Gerenciamiento",
    },
    {
      id: "4",
      nombre: "ITO - Inspección Técnica de Obras",
    },
    {
      id: "5",
      nombre: "INN - Ingeniero independiente",
    },
    {
      id: "6",
      nombre: "INF - Informe",
    },
    {
      id: "7",
      nombre: "BIM - Building Information Modeling",
    },
    {
      id: "8",
      nombre: "ASE - Asesoría",
    },
    {
      id: "9",
      nombre: "MNT - Monitoreo",
    },
    {
      id: "10",
      nombre: "DDT - Due Diligence Técnico",
    },
    {
      id: "11",
      nombre: "ING - Proyectos de Ingeniería",
    },
    {
      id: "12",
      nombre: "UCE - Unidad de control Externo",
    },
  ];

  if (!gerencias || !responsables || !sociedades || !mandantes) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {/* SI AUN NO CARGAN LOS DATOS */}
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Breadcrumbs separator=">">
        <Typography color="textPrimary">Módulo Contratos</Typography>
        <Link to="/contratos">Contratos</Link>
        <Typography color="textPrimary">Ingresar Contrato</Typography>
      </Breadcrumbs>
      <Container maxWidth="xl" className={classes.main}>
        <Paper
          style={{ marginBottom: 13, paddingBottom: 30, borderRadius: "25px" }}
        >
          {/* CODIGO ACTUAL Y NUEVO */}
          <Grid>
            <Grid item xs={12}>
              {/* CODIGO ACTUAL */}
              <Typography variant="h6" align="center">
                Código Actual
              </Typography>
              <Typography variant="h4" align="center">
                {!isCentroCosto
                  ? `${
                      values.codigo.gerencia
                        ? values.codigo.gerencia
                        : "[Gerencia]"
                    }-${
                      values.codigo.responsable
                        ? values.codigo.responsable
                        : "[Responsable]"
                    }-${
                      values.codigo.correlativo
                        ? values.codigo.correlativo
                        : correlativo
                    }`
                  : `${
                      values.codigo.gerencia
                        ? values.codigo.gerencia
                        : "[Gerencia]"
                    }-${
                      values.codigo.responsable
                        ? values.codigo.correlativo
                        : "[Responsable]"
                    } `}
              </Typography>
              <Typography align="center" className={classes.caption_text_top}>
                Correlativo estimado, puede variar.
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            {/* <Formik ref> */}

            <form onSubmit={handleSubmit}>
              {/* CAMPOS DEL CONTRATO */}
              <Grid
                container
                spacing={1}
                style={{ paddingLeft: 15, paddingRight: 15 }}
              >
                <SeccionView
                  borderColor="#731f1f"
                  nombreSeccion="Información del Contrato"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* GERENCIA */}
                      <Autocomplete
                        name="gerencia_ref"
                        loading={!gerencias}
                        options={gerencias}
                        onChange={(event, value) => {
                          setFieldValue("gerencia_ref", value ? value._id : "");
                          setFieldValue(
                            "codigo.gerencia",
                            value ? value.sigla : ""
                          );
                        }}
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(g) => `${g.sigla} - ${g.nombre}`}
                        renderInput={(params) => (
                          <TextField
                            label="Gerencia"
                            variant="outlined"
                            required
                            error={
                              touched.gerencia_ref &&
                              errors.gerencia_ref &&
                              Boolean(errors.gerencia_ref)
                            }
                            helperText={
                              touched.gerencia_ref && errors.gerencia_ref
                                ? errors.gerencia_ref
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} xl={3}>
                      {/* MANDANTE */}
                      <Autocomplete
                        name="mandante_ref"
                        loading={!mandantes}
                        options={mandantes}
                        onChange={(event, value) =>
                          setFieldValue("mandante_ref", value ? value._id : "")
                        }
                        getOptionLabel={(m) => m.nombre}
                        renderInput={(params) => (
                          <TextField
                            label="Mandante"
                            variant="outlined"
                            required
                            error={
                              touched.mandante_ref &&
                              errors.mandante_ref &&
                              Boolean(errors.mandante_ref)
                            }
                            helperText={
                              touched.mandante_ref && errors.mandante_ref
                                ? errors.mandante_ref
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* NOMBRE CONTRATO */}
                      <TextField
                        name="nombre"
                        label="Nombre"
                        value={values.nombre}
                        variant="outlined"
                        required
                        fullWidth
                        onChange={handleChange}
                        error={
                          touched.nombre &&
                          errors.nombre &&
                          Boolean(errors.nombre)
                        }
                        helperText={
                          touched.nombre && errors.nombre ? errors.nombre : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* SOCIEDAD */}
                      <Autocomplete
                        name="sociedad_ref"
                        loading={!sociedades}
                        options={sociedades}
                        onChange={(event, value) =>
                          setFieldValue("sociedad_ref", value ? value._id : "")
                        }
                        getOptionLabel={(s) => s.nombre}
                        renderInput={(params) => (
                          <TextField
                            label="Sociedad"
                            variant="outlined"
                            required
                            error={
                              touched.sociedad_ref &&
                              errors.sociedad_ref &&
                              Boolean(errors.sociedad_ref)
                            }
                            helperText={
                              touched.sociedad_ref && errors.sociedad_ref
                                ? errors.sociedad_ref
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* SOCIEDAD */}
                      <Autocomplete
                        name="tipo_de_servicio"
                        loading={!tipo_de_servicio}
                        options={tipo_de_servicio}
                        onChange={(event, value) =>
                          setFieldValue(
                            "tipo_de_servicio",
                            value ? value.nombre : ""
                          )
                        }
                        getOptionSelected={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(s) => s.nombre}
                        renderInput={(params) => (
                          <TextField
                            label="Tipo de Servicio"
                            variant="outlined"
                            required
                            error={
                              touched.tipo_de_servicio &&
                              errors.tipo_de_servicio &&
                              Boolean(errors.tipo_de_servicio)
                            }
                            helperText={
                              touched.tipo_de_servicio &&
                              errors.tipo_de_servicio
                                ? errors.tipo_de_servicio
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* RESPONSABLE */}
                      <Autocomplete
                        name="responsable_admin_ref"
                        loading={!responsables}
                        options={responsables}
                        onChange={(event, value) => {
                          setFieldValue(
                            "responsable_ref",
                            value ? value._id : ""
                          );
                          setFieldValue(
                            "codigo.responsable",
                            value ? value.correlativo : ""
                          );
                        }}
                        getOptionLabel={(responsable) =>
                          responsable.persona.nombre_completo +
                          " [" +
                          responsable.correlativo +
                          "]"
                        }
                        renderInput={(params) => (
                          <TextField
                            label="Responsable"
                            variant="outlined"
                            required
                            error={
                              touched.responsable_ref &&
                              errors.responsable_ref &&
                              Boolean(errors.responsable_ref)
                            }
                            helperText={
                              touched.responsable_ref && errors.responsable_ref
                                ? errors.responsable_ref
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* RESPONSABLE */}
                      <Autocomplete
                        name="responsable_admin_ref"
                        loading={!responsables}
                        options={responsables}
                        onChange={(event, value) => {
                          setFieldValue(
                            "responsable_admin_ref",
                            value ? value._id : ""
                          );
                          setFieldValue(
                            "codigo.responsable",
                            value ? value.correlativo : ""
                          );
                        }}
                        getOptionLabel={(responsable) =>
                          responsable.persona.nombre_completo +
                          " [" +
                          responsable.correlativo +
                          "]"
                        }
                        renderInput={(params) => (
                          <TextField
                            label="Responsable Talana"
                            variant="outlined"
                            required
                            error={
                              touched.responsable_ref &&
                              errors.responsable_ref &&
                              Boolean(errors.responsable_ref)
                            }
                            helperText={
                              touched.responsable_ref && errors.responsable_ref
                                ? errors.responsable_ref
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* FECHA DE INICIO */}
                      <DatePicker
                        name="fecha_inicio"
                        value={values.fecha_inicio}
                        label="Fecha de Inicio"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        fullWidth
                        onChange={(date) => setFieldValue("fecha_inicio", date)}
                        error={
                          touched.fecha_inicio &&
                          errors.fecha_inicio &&
                          Boolean(errors.fecha_inicio)
                        }
                        helperText={
                          touched.fecha_inicio && errors.fecha_inicio
                            ? errors.fecha_inicio
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* FECHA ESTIMADA TERMINO */}
                      <DatePicker
                        name="fecha_termino_estimada"
                        value={values.fecha_termino_estimada}
                        label="Fecha Estimada de Término (En blanco para indefinido)"
                        format="DD/MM/YYYY"
                        maxDate={Moment("31/12/2099", "DD/MM/YYYY")}
                        inputVariant="outlined"
                        fullWidth
                        onChange={(date) =>
                          setFieldValue("fecha_termino_estimada", date)
                        }
                        error={
                          touched.fecha_termino_estimada &&
                          errors.fecha_termino_estimada &&
                          Boolean(errors.fecha_termino_estimada)
                        }
                        helperText={
                          touched.fecha_termino_estimada &&
                          errors.fecha_termino_estimada
                            ? errors.fecha_termino_estimada
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* FECHA DE INICIO */}
                      <DatePicker
                        name="fecha_adjudicacion"
                        value={values.fecha_adjudicacion}
                        label="Fecha de Adjudicación"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        fullWidth
                        onChange={(date) =>
                          setFieldValue("fecha_adjudicacion", date)
                        }
                        error={
                          touched.fecha_adjudicacion &&
                          errors.fecha_adjudicacion &&
                          Boolean(errors.fecha_adjudicacion)
                        }
                        helperText={
                          touched.fecha_adjudicacion &&
                          errors.fecha_adjudicacion
                            ? errors.fecha_adjudicacion
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* NOMBRE CONTRATO */}
                      {/* RESPONSABLE */}
                      <Autocomplete
                        name="region"
                        loading={!regiones}
                        options={regiones}
                        onChange={(event, value) => {
                          setFieldValue("region", value ? value.region : "");
                        }}
                        getOptionLabel={(region) => {
                          return region.region;
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Región"
                            variant="outlined"
                            required
                            error={
                              touched.region &&
                              errors.region &&
                              Boolean(errors.region)
                            }
                            helperText={
                              touched.region && errors.region
                                ? errors.region
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* COMUNA */}
                      <Autocomplete
                        name="comuna"
                        loading={!comunas}
                        options={comunas}
                        onChange={(event, value) => {
                          setFieldValue("comuna", value ? value.nombre : "");
                        }}
                        getOptionLabel={(responsable) => {
                          return responsable.nombre;
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Comuna"
                            variant="outlined"
                            required
                            error={
                              touched.comuna &&
                              errors.comuna &&
                              Boolean(errors.comuna)
                            }
                            helperText={
                              touched.comuna && errors.comuna
                                ? errors.comuna
                                : ""
                            }
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* NOMBRE CONTRATO */}
                      <TextField
                        name="direccion"
                        label="Dirección"
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="off"
                        value={values.direccion}
                        onChange={handleChange}
                        error={
                          touched.direccion &&
                          errors.direccion &&
                          Boolean(errors.direccion)
                        }
                        helperText={
                          touched.direccion && errors.direccion
                            ? errors.direccion
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      {/* NOMBRE CONTRATO */}
                      <TextField
                        name="monto_del_contrato"
                        label="Monto del Contrato. Por ejemplo, UF 15.000 "
                        value={values.monto_del_contrato}
                        variant="outlined"
                        autoComplete="off"
                        required
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">UF</InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        error={
                          touched.monto_del_contrato &&
                          errors.monto_del_contrato &&
                          Boolean(errors.monto_del_contrato)
                        }
                        helperText={
                          touched.monto_del_contrato &&
                          errors.monto_del_contrato
                            ? errors.monto_del_contrato
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </SeccionView>
              </Grid>
              <Typography
                align="center"
                className={classes.caption_text_bottom}
              >
                La información proporcionada en la solicitud de contrato puede
                ser editada una vez generado el contrato.
              </Typography>
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  onClick={handle_limpiar}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  Limpiar
                </Button>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Solicitar
                </Button>
              </Box>
            </form>
            {/* </Formik> */}
          </Grid>
        </Paper>
      </Container>
    </Fragment>
  );
  function getMandanteText(value, key) {
    if (value === "") {
      return "";
    } else {
      let e = mandantes.find((e) => {
        return e._id === values[key];
      });

      return `[${e.rut_formateado}] ${e.nombre}`;
    }
  }

  function getBasicFieldText(value, key) {
    if (value === "") {
      return "";
    } else {
      let e = sociedades.find((e) => {
        return e._id === values[key];
      });

      return e.nombre;
    }
  }

  function getResponsableText(value, key) {
    if (value === "") {
      return "";
    } else {
      let e = responsables.find((e) => {
        return e._id === values[key];
      });
      if (e) {
        return (
          e.persona.nombre_completo +
          ` ( ${e.gerencia}${
            e.correlativo === "-1" ? "" : "-" + e.correlativo
          } )`
        );
      } else {
        return "";
      }
    }
  }
}

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  caption_text_top: {
    fontSize: 12,
  },
  caption_text_bottom: {
    fontSize: 12,
    marginTop: theme.spacing(3),
  },
  main: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export default Page;
