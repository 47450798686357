import React ,{ useEffect, useState } from "react";

import { useSelector } from "react-redux";
// Internals
import schema from "../schema.json";
import formDataJson from "../form-data.json";
import { Container, makeStyles, Paper, Avatar, Chip, TextField, ListSubheader } from "@material-ui/core";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";
import ObjectFieldTemplate from "../FormCyd";
import { Autocomplete } from "@material-ui/lab";
import { useUsuarios } from "../../responsables/nuevo/hooksPersonas";
import axios from "axios";
import { agregarItemProceso } from "../requestItem";
import { useHistory } from "react-router-dom";
import HeaderBackTable from "../../../components/HeaderBackTable";

// One could also use the `Theme4` alias
// import { Theme4 as MaterialUITheme } from '@rjsf/material-ui';

// Make modifications to the theme with your own fields and widgets

const Form = withTheme(MaterialUITheme);
const useStyles = makeStyles((theme) => ({
  formControl: {
    // width: "100%"
  },
  root: {
    display: "inline-block",
  },
  // myfield: {
  //   <Grid item xs={12} sm={6}>
  // }
}));

function AdminProcesoEliminacion(props) {
  const [formData, setFormData] = useState(formDataJson);
  const history = useHistory();

  const classes = useStyles();
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  const onSubmit = (value, callback) => {
    console.log(value.formData);

    value.formData.responsables.map((item, index) => {
      let responsable = {
        nombre: item.nombre_abreviado,
        nombre_abreviado: item.nombre_abreviado,
        apellido_materno: item.apellido_materno,
        apellido_paterno: item.apellido_paterno,
        nombre_completo: item.nombre_completo,
        email: item.email ? item.email : item.contacto.email,
        photoUrl: item.photo,
        uid: item.auth_id,
        rut: item.run,
        cargo: item.cargo,
      };
      value.formData.responsables[index] = responsable;
    });
    console.log(value.formData);
    agregarItemProceso(value.formData).then((response) => {
      history.push(`/eliminacion/pasos/`);
    });
    setFormData({});

    //propssetTimeout(() => callback && callback(), 2000); // just an example in real world can be your XHR call
  };

  const onCancel = () => {
    console.log("on reset being called");
  };

  const onFormChanged = ({ formData }) => {
    console.log("onFormChanged: ", formData); // eslint-disable-line no-console
    setFormData(formData);
  };

  const onError = () => {
    console.log("on reset being called");
  };

  function transformErrors(errors) {
    console.log(errors);
    return errors.map((error) => {
      if (error.name === "pattern") {
        error.message = "Only sdf digits are allowed";
      }
      return error;
    });
  }

  const widgets = {
    customTextField: TextFieldCyd,
    TextWidget: TextFieldCyd,
    selectItem: SelectItem,
    MultipleSelector: MultipleSelector,
  };
  return (
    <Container style={{ paddingTop: "50px" }} maxWidth={"xl"}>
      <Paper style={{ padding: 15, borderRadius: "15px" }} elevation={3}>
        <HeaderBackTable titulo={"Ítem de eliminación"}/>
        <Form
          method="post"
          action="submit"
          schema={schema}
          formData={formData}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onChange={onFormChanged}
          onError={onError}
          widgets={widgets}
          showErrorList={false}
          omitExtraData={false}
          transformErrors={transformErrors}
          uiSchema={{
            "ui:ObjectFieldTemplate": ObjectFieldTemplate,
            "ui:submitButtonOptions": {
              submitText: "Guardar",
            },
            fecha: {
              "ui:widget": "date",
            },
            mensaje_notificacion: {
              "ui:widget": TextFieldCyd,
              "ui:options": {
                rows: 5,
                multiline: true,
              },
            },
            responsables: {
              "ui:widget": MultipleSelector,
              "ui:options": {
                labelOption: "nombre_completo",
                backgroundColor: "yellow",
              },

              // "ui:help":
              //   "If you enter anything here then billing_address will become required.",
            },
            grupo: {
              "ui:widget": SelectItem,
              "ui:options": {
                labelOption: "nombre_completo",
              },
            },
          }}
        />
      </Paper>
    </Container>
  );
}
const useStylesSlector = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  fullWidth: {
    width: "100%",
  },
}));

export const MultipleSelector = (props) => {
  console.log(props);
  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState([]);

  const classes = useStylesSlector();
  const [value, setValue] = useState(
    typeof props.value == "undefined" ? null : props.value
  );
  let cancelToken = axios.CancelToken.source();
  const { UsuariosResponsables, loadResponsables } = useUsuarios(
    null,
    cancelToken
  );

  useEffect(() => {
    // console.log(UsuariosResponsables);
    if (typeof UsuariosResponsables === "undefined") {
      setOptionsUsurios([]);
    } else {
      // console.log(UsuariosResponsables.length);
      if (UsuariosResponsables.length === 0) {
        setOptionsUsurios([]);
      } else {
        setOptionsUsurios(UsuariosResponsables);
      }
    }
  }, [UsuariosResponsables]);

  const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  return (
    <Autocomplete
      label={props.label}
      value={value}
      multiple
      id="tags-outlined"
      onChange={(_ev, newValue, reason) => {
        console.log(newValue);
        props.onChange(newValue ?? {});
        setValue(newValue);
        //props.onChange(newValue.map(getOptionName));
      }}
      renderGroup={renderGroup}
      loading={loadResponsables}
      options={optionsUsuarios}
      getOptionLabel={(g) => `${g[options.labelOption]}`}
      filterSelectedOptions
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              style={{ margin: "3px" }}
              label={option.nombre_completo}
              avatar={<Avatar alt="Natacha" src={option.photo} />}
              {...getTagProps({ index })}
            />
          );
        });
        // </div>
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Favorites"
            variant="outlined"
            label={props.label}
          />
        );
      }}
    />
  );
};

export const SelectItem = (props) => {
  const { options } = props;
  const [optionsUsuarios, setOptionsUsurios] = useState(options.enumOptions);
  const [value, setValue] = useState(
    typeof props.value == "undefined" ? null : props.value
  );

  return (
    <Autocomplete
      label={props.label}
      value={value}
      //onChange={(event) => props.onChange(event.target.value)}
      onChange={(_ev, newValue, reason) => {
        props.onChange(newValue.value ?? {});
        setValue(newValue);
        //props.onChange(newValue.map(getOptionName));
      }}
      options={optionsUsuarios}
      getOptionLabel={(g) => `${g.label}`}
      renderInput={(params) => (
        <TextField
          label={props.label}
          variant="outlined"
          // required={props.required}
          {...params}
        />
      )}
    />
  );
};

export const TextFieldCyd = (props) => {
  const { options } = props;
  return (
    <TextField
      label={props.label}
      value={props.value}
      rows={options.rows}
      variant="outlined"
      disabled={props.disabled}
      readOnly={props.readOnly}
      multiline={options.multiline}
      // required={props.required}
      fullWidth
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};
export default AdminProcesoEliminacion;
