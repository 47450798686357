import React, { useEffect } from "react";

import {
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { AvatarGroup } from "@material-ui/lab";
import { actualizarProcesoEliminacionContrato } from "../../requestContrato";
import { useSelector } from "react-redux";

const ItemCierre = (props) => {
  const {
    itemCierre,
    contrato,
    canFinalizarEtapa,
    onChange,
    onClickSendInfo,
  } = props;
  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  const [cerrado, setCerrado] = React.useState(false);
  const [cerradoPor, setCerradoPor] = React.useState(null);

  useEffect(() => {
    if (contrato.proceso_de_finalizacion) {
      let itemDeProceso = contrato.proceso_de_finalizacion.find(
        (item) => item.id_proceso === itemCierre.id
      );
      if (itemDeProceso) {
        setCerrado(itemDeProceso.is_cerrado);
        setCerradoPor(itemDeProceso.cerrado_por);
      } else {
        setCerrado(false);
        setCerradoPor(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrato]);

  useEffect(() => {
    setCerradoPor(itemCierre.cerrado_por);
  }, [itemCierre]);

  const getAvatars = () => {
    let tooltip = "";

    let avatar = itemCierre.responsables.map((item) => {
      tooltip = tooltip + " " + item.nombre;

      //canFinalizarEtapa
      return (
        <Avatar
          key={"avatar-" + item.nombre}
          alt={item.nombre}
          src={item.photoUrl}
        />
      );
    });
    return (
      <Tooltip title={tooltip}>
        <AvatarGroup max={4}>{avatar}</AvatarGroup>
      </Tooltip>
    );
  };

  const getCerradoPor = () => {
    return !cerrado ? (
      <Typography variant="body2" gutterBottom style={{ color: "#000" }}>
        {" -- "}
      </Typography>
    ) : (
      <Grid
        container
        spacing={1}
        direction="row"
        justifycontent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Avatar alt={cerradoPor?.nombre} src={cerradoPor?.photoUrl} />
        </Grid>
        <Grid item>
          <Typography variant="body2" gutterBottom style={{ color: "#000" }}>
            {cerradoPor?.nombre}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{
        paddingTop: 10,
      }}
    >
      <Grid item xs={1}>
        <Typography variant="body2" gutterBottom style={{ color: "#000" }}>
          {itemCierre.area}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" gutterBottom style={{ color: "#000" }}>
          {itemCierre.proceso}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" gutterBottom style={{ color: "#000" }}>
          {itemCierre.detalle}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {getAvatars()}
      </Grid>
      <Grid item xs={1}>
        {cerrado ? (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={
              !canFinalizarEtapa
                ? null
                : async () => {
                    onChange(true);
                    await actualizarProcesoEliminacionContrato(
                      contrato._id,
                      itemCierre.id,
                      usuarioSesion,
                      false
                    );
                    setCerrado(!cerrado);
                    onChange(false);
                  }
            }
          >
            <CheckCircleIcon style={{ color: "#2ecc71" }} />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={
              !canFinalizarEtapa
                ? null
                : async () => {
                    onChange(true);
                    await actualizarProcesoEliminacionContrato(
                      contrato._id,
                      itemCierre.id,
                      usuarioSesion,
                      true
                    );
                    setCerrado(!cerrado);
                    onChange(false);
                  }
            }
          >
            <RadioButtonUncheckedIcon style={{ color: "#000" }} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={2}>
        {getCerradoPor()}
      </Grid>

      <Grid
        item
        xs={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          { !cerrado && itemCierre.can_notificar ? (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                onClickSendInfo(itemCierre);
              }}
            >
              <MailIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ItemCierre.propTypes = {};

export default ItemCierre;
