import React, { useEffect, useState } from "react";
import Page from "./page";
import {
  actualizarContrato,
  obtenerGerencias,
  obtenerResponsables,
  obtenerSociedades,
  obtenerMandantes,
  obtenerContratoForm,
} from "../requestContrato";
import { useHistory, useParams } from "react-router-dom";
import { date, object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, CircularProgress, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  obtenerComunas,
  obtenerRegiones,
} from "../../solicitud/requestSolicitud";
import Detalle from "./Detalle";

function Index(props) {
  const [Contrato, SetContrato] = useState();
  const [Gerencias, SetGerencias] = useState();
  const [Responsables, SetResponsables] = useState();
  const [Sociedades, SetSociedades] = useState();
  const [Mandantes, SetMandantes] = useState();
  const [cambioDeCorrelatico, setCambioDeCorrelatico] = useState(true);

  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);

  const { id: contratoID } = useParams();
  const history = useHistory();
  const notistack = useSnackbar();

  const { usuarioSesion } = useSelector((state) => ({
    usuarioSesion: state.usuarioSesion,
  }));

  

  /**
   * Valores iniciales del formulario.
   */
  const initials = {
    _id: Contrato ? Contrato._id : "",
    nombre: Contrato ? Contrato.nombre : "",
    codigo: Contrato ? Contrato.codigo : "",
    codigo_nuevo: {
      gerencia: Contrato ? Contrato.codigo.split("-")[0] : "",
      responsable: Contrato ? Contrato.codigo.split("-")[1] : "",
      correlativo: Contrato ? Contrato.codigo.split("-")[2] : "",
    },
    estado: Contrato ? Contrato.estado : "",
    is_eliminado: Contrato ? Contrato.is_eliminado : false,
    fecha_inicio:
      Contrato && Contrato.fecha_inicio ? Contrato.fecha_inicio : null,
    fecha_termino_estimada:
      Contrato && Contrato.fecha_termino_estimada
        ? Contrato.fecha_termino_estimada
        : null,
    aplicaEncuesta: Contrato ? Contrato.aplicaEncuesta : true,
    fecha_termino_real:
      Contrato && Contrato.fecha_termino_real
        ? Contrato.fecha_termino_real
        : null,
    responsable_ref:
      Contrato && Contrato.responsable_ref ? Contrato.responsable_ref._id : "",
    responsable_admin_ref:
      Contrato && Contrato.responsable_admin_ref
        ? Contrato.responsable_admin_ref
        : "",
    gerencia_ref:
      Contrato && Contrato.gerencia_ref ? Contrato.gerencia_ref._id : "",
    mandante_ref:
      Contrato && Contrato.mandante_ref ? Contrato.mandante_ref._id : "",
    sociedad_ref:
      Contrato && Contrato.sociedad_ref ? Contrato.sociedad_ref._id : "",
    fecha_adjudicacion:
      Contrato && Contrato.fecha_adjudicacion
        ? Contrato.fecha_adjudicacion
        : null,
    aprobador_1: Contrato?.aprobadores_factura
      ? Contrato.aprobadores_factura.aprobadores[0]
      : null,
    aprobador_2: Contrato?.aprobadores_factura
      ? Contrato.aprobadores_factura.aprobadores[1]
      : null,
    aprobador_3: Contrato?.aprobadores_factura
      ? Contrato.aprobadores_factura.aprobadores[2]
      : null,
  };

  /**
   * Esquema de validación para el contrato.
   */
  const validation = object().shape({
    nombre: string()
      .min(3, "El nombre debe tener al menos ${min} caracteres.")
      .max(70, "El nombre debe tener a lo más ${max} caracteres.")
      .nullable()
      .required("El nombre es requerido."),
    codigo: string()
      .min(3, "El código debe tener al menos ${min} caracteres.")
      .required("El código es requerido.")
      .nullable(),
    estado: string().required("El estado es requerido."),
    fecha_inicio: date().nullable().optional(),
    fecha_termino_estimada: date()
      .nullable()
      .required("La fecha estima de término es requerida."),
    fecha_termino_real: date().nullable().optional(),
    // .required("La fecha de término real es requerida."),
    responsable_ref: string().required("El responsable es requerido."),
    responsable_admin_ref: string().required("El responsable es requerido."),
    gerencia_ref: string().required("La gerencia es requerida.").nullable(),
    mandante_ref: string().required("El mandante es requerido.").nullable(),
    sociedad_ref: string().required("La sociedad es requerida.").nullable(),
    monto_contrato: string().nullable(), //.positive().integer(),
  });

  /**
   * Configuración de Formik Hook.
   */
  const formik = useFormik({
    initialValues: initials,
    validationSchema: validation,
    enableReinitialize: true,
    // onSubmit: (values, helper) => handleActualizar(values),
  });

  const { resetForm } = formik;

  useEffect(() => {
    Promise.all([
      obtenerContratoForm(contratoID),
      obtenerGerencias(),
      obtenerResponsables(),
      obtenerSociedades(),
      obtenerMandantes(),
      obtenerComunas(),
      obtenerRegiones(),
    ])
      .then((responses) => {
        const contrato = responses[0];
        SetContrato(contrato);
        SetGerencias(responses[1]);
        SetResponsables(responses[2]);
        SetSociedades(responses[3]);
        SetMandantes(responses[4]);
        setComunas(responses[5]);
        setRegiones(responses[6]);

        resetForm({
          values: {
            id: contrato ? contrato._id : "",
            nombre: contrato ? contrato.nombre : "",
            codigo: contrato ? contrato.codigo : "",
            codigo_nuevo: {
              gerencia: contrato ? contrato.codigo.split("-")[0] : "",
              responsable: contrato ? contrato.codigo.split("-")[1] : "",
              correlativo: contrato ? contrato.codigo.split("-")[2] : "",
            },
            estado: contrato ? contrato.estado : "",
            fecha_inicio:
              contrato && contrato.fecha_inicio ? contrato.fecha_inicio : null,
            aplicaEncuesta: Contrato ? Contrato?.aplicaEncuesta ? Contrato?.aplicaEncuesta : true : true,
            fecha_termino_estimada:
              contrato && contrato.fecha_termino_estimada
                ? contrato.fecha_termino_estimada
                : null,
            fecha_termino_real:
              contrato && contrato.fecha_termino_real
                ? contrato.fecha_termino_real
                : null,
            gerencia_ref:
              contrato && contrato.gerencia_ref
                ? contrato.gerencia_ref._id
                : "",
            mandante_ref:
              contrato && contrato.mandante_ref
                ? contrato.mandante_ref._id
                : "",
            sociedad_ref:
              contrato && contrato.sociedad_ref
                ? contrato.sociedad_ref._id
                : "",
            fecha_adjudicacion:
              contrato && contrato.fecha_adjudicacion
                ? contrato.fecha_adjudicacion
                : null,
            aprobador_1: contrato?.aprobadores_factura
              ? contrato.aprobadores_factura.aprobadores[0]
              : null,
            aprobador_2: contrato?.aprobadores_factura
              ? contrato.aprobadores_factura.aprobadores[1]
              : null,
            aprobador_3: contrato?.aprobadores_factura
              ? contrato.aprobadores_factura.aprobadores[2]
              : null,
            responsable_ref:
              Contrato && Contrato.responsable_ref
                ? Contrato.responsable_ref._id
                : "",
            aplicaEncuesta: Contrato ? Contrato.aplicaEncuesta : true,
            responsable_admin_ref:
              Contrato && Contrato.responsable_admin_ref
                ? Contrato.responsable_admin_ref
                : "",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        SetContrato({});
        SetGerencias([]);
        SetResponsables([]);
        SetSociedades([]);
        SetMandantes([]);
      });
  }, []);

 



  /**
   * Handler para salir del formulario.
   */
  const handleSalir = () => {
    history.push("/contratos");
  };

  const handleCambioDeCorrelatico = (valor) => {
    setCambioDeCorrelatico(valor.target.checked);
  };

  return (
    <>
      {Contrato ?
        <Detalle
          gerencias={Gerencias}
          responsables={Responsables}
          usuario={usuarioSesion}
          sociedades={Sociedades}
          mandantes={Mandantes}
          handle_salir={handleSalir}
          formik={formik}
          contrato={Contrato}
          cambioDeCorrelatico={cambioDeCorrelatico}
          onChangeCambioDeCorrelativo={handleCambioDeCorrelatico}
          comunas={comunas}
          regiones={regiones}
          validation={validation}
          onFormChanged={(data) => {
          

          }}
        /> : <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>}

      {/* <Page
        gerencias={Gerencias}
        responsables={Responsables}
        usuario={usuarioSesion}
        sociedades={Sociedades}
        mandantes={Mandantes}
        handle_salir={handleSalir}
        formik={formik}
        contrato={Contrato}
        cambioDeCorrelatico={cambioDeCorrelatico}
        onChangeCambioDeCorrelativo={handleCambioDeCorrelatico}
        comunas={comunas}
        regiones={regiones}
      /> */}
    </>
  );
}

export default Index;
