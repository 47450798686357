import React from 'react';
import { WidgetProps, utils } from '@rjsf/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const { asNumber, guessType } = utils;

const nums = new Set(['number', 'integer']);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema, value) => {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === '') {
    return undefined;
  } else if (type === 'array' && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === 'boolean') {
    return value === 'true';
  } else if (type === 'number') {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every((x) => guessType(x) === 'number')) {
      return asNumber(value);
    } else if (schema.enum.every((x) => guessType(x) === 'boolean')) {
      return value === 'true';
    }
  }

  return value;
};

const SelectWidgetCyd = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly=true,
  value,
  multiple,
  onChange,
  onBlur,
  onFocus,

}) => {

  const { enumOptions, enumDisabled } = options;

  const emptyValue = multiple ? [] : '';

  const _onChange = ({ target: { value } }) => onChange(processValue(schema, value));
  const _onBlur = ({ target: { value } }) => onBlur(id, processValue(schema, value));
  const _onFocus = ({ target: { value } }) => onFocus(id, processValue(schema, value));


  return (
    <FormControl variant="outlined" >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        value={typeof value === 'undefined' ? emptyValue : value}
        label={label || schema.title}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}

      >
        {enumOptions.map(({ value, label }, i) => {
          const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
          return (
            <MenuItem key={i} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}

      </Select>
    </FormControl>
  )


};

export default SelectWidgetCyd;