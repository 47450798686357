import React from "react";
import { Grid, Typography } from "@material-ui/core";

const HeaderCierre = (props) => {
  return (
    <Grid
      container
      direction="row"
      justifycontent="flex-start"
      alignItems="center"
      spacing={1}
      style={{
        background: "#731f1f",
        borderRadius: "25px 25px 0 0",
        paddingTop: 10,
      }}
    >
      <Grid item xs={1} style={{ marginLeft: 8 }}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Áreas
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Proceso
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Detalle
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Responsable(s) Áreas
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Estado
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Cerrado por
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1" gutterBottom style={{ color: "#fff" }}>
          Notificar al Responsable
        </Typography>
      </Grid>
    </Grid>
  );
};

//HeaderCierre.propTypes = {};
//db.createUser({user: "root",pwd: "pass123",roles: [ { role: "userAdminAnyDatabase", db: "admin" }, "readWriteAnyDatabase" ]})
export default HeaderCierre;
