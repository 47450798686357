import React, { Fragment } from "react";
import {
	Breadcrumbs,
	Paper,
	Grid,
	TextField,
	Typography,
	makeStyles,
	Box,
	Button,
	CircularProgress,

} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import Moment from "moment";
import {
	ACTUALIZAR_CONTRATO,
	ACTUALIZAR_CONTRATO_BASICO,
} from "../../../constants/permissions";
import SeccionView from "./components/SeccionView";


function Page(props) {
	const {
		gerencias,
		responsables,
		sociedades,
		mandantes,
		handle_salir,
		isCentroCosto,
		formik,
		usuario,
		correlativo,
		comunas,
		regiones,
	} = props;

	const classes = useStyle();
	const {
		values,
		handleChange,
		setFieldValue,
		submitForm,
		handleSubmit,
		errors,
		touched,
	} = formik;

	const permisos = Array.from(usuario.permisos);
	const permisoActualizarBasico = permisos.find(
		(p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
	);
	const permisoActualizar = permisos.find(
		(p) => p.nombre === ACTUALIZAR_CONTRATO
	);

	if (!gerencias || !responsables || !sociedades || !mandantes) {
		//Si aún no cargan los datos.
		return (
			<Box
				display="flex"
				justifycontent="center"
				alignItems="center"
				height="100%"
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Contratos</Typography>
				<Link to="/lista/solicitud_nuevo_contrato">Solicitud de Contrato</Link>
				<Typography color="textPrimary">{`Ingresar Contrato ${values.codigo}`}</Typography>
			</Breadcrumbs>
			<Paper className={classes.card}>
				<form onSubmit={handleSubmit}>
					{/* CODIGO ACTUAL Y NUEVO */}
					<Grid container spacing={3}>
						<Grid item xs={12}>
							{/* CODIGO ACTUAL */}
							<Typography variant="h6" align="center">
								Código Actual
							</Typography>
							<Typography variant="h4" align="center">
								{CodigoFormateado(values, correlativo, isCentroCosto)}
							</Typography>
							<Typography align="center" className={classes.caption_text_top}>
								Correlativo estimado, puede variar.
							</Typography>
						</Grid>
					</Grid>
					{/* CAMPOS DEL CONTRATO */}
					<SeccionView
						borderColor="#731f1f"
						nombreSeccion="Información del Contrato"
					>
						<Grid container spacing={3}>
							<Grid item xs={6} xl={3}>
								{/* CORRELATIVO CONTRATO */}
								<TextField
									name="codigo.correlativo"
									label="Correlativo"
									value={values.codigo_ref?.correlativo}
									variant="outlined"
									fullWidth
									onChange={handleChange}
									error={
										touched.codigo &&
										touched.codigo.correlativo &&
										errors.codigo &&
										errors.codigo.correlativo &&
										Boolean(errors.codigo.correlativo)
									}
									helperText={
										touched.codigo &&
											touched.codigo.correlativo &&
											errors.codigo &&
											errors.codigo.correlativo
											? errors.codigo.correlativo
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* GERENCIA */}
								<Autocomplete
									name="gerencia_ref"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico || !permisoActualizar
												? true
												: false
									}
									value={Array.from(gerencias).find(
										(g) => g._id === values.gerencia_ref
									)}
									loading={!gerencias}
									options={gerencias}
									onChange={(event, value) => {
										setFieldValue("gerencia_ref", value ? value._id : "");
										setFieldValue("codigo.gerencia", value ? value.sigla : "");
									}}
									getOptionLabel={(g) => `${g.sigla} - ${g.nombre}`}
									renderInput={(params) => (
										<TextField
											label="Gerencia"
											variant="outlined"
											required
											error={
												touched.gerencia_ref &&
												errors.gerencia_ref &&
												Boolean(errors.gerencia_ref)
											}
											helperText={
												touched.gerencia_ref && errors.gerencia_ref
													? errors.gerencia_ref
													: ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* MANDANTE */}
								<Autocomplete
									name="mandante_ref"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico
												? true
												: false
									}
									value={Array.from(mandantes).find(
										(m) => m._id === values.mandante_ref
									)}
									loading={!mandantes}
									options={mandantes}
									onChange={(event, value) =>
										setFieldValue("mandante_ref", value ? value._id : "")
									}
									getOptionLabel={(m) => m.nombre}
									renderInput={(params) => (
										<TextField
											label="Mandante"
											variant="outlined"
											required
											error={
												touched.mandante_ref &&
												errors.mandante_ref &&
												Boolean(errors.mandante_ref)
											}
											helperText={
												touched.mandante_ref && errors.mandante_ref
													? errors.mandante_ref
													: ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* NOMBRE CONTRATO */}
								<TextField
									name="nombre"
									label="Nombre"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico
												? true
												: false
									}
									value={values.nombre}
									variant="outlined"
									fullWidth
									onChange={handleChange}
									required
									error={
										touched.nombre && errors.nombre && Boolean(errors.nombre)
									}
									helperText={
										touched.nombre && errors.nombre ? errors.nombre : ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* SOCIEDAD */}
								<Autocomplete
									name="sociedad_ref"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico
												? true
												: false
									}
									value={Array.from(sociedades).find(
										(s) => s._id === values.sociedad_ref
									)}
									loading={!sociedades}
									options={sociedades}
									onChange={(event, value) =>
										setFieldValue("sociedad_ref", value ? value._id : "")
									}
									getOptionLabel={(s) => s.nombre}
									renderInput={(params) => (
										<TextField
											label="Sociedad"
											variant="outlined"
											required
											error={
												touched.sociedad_ref &&
												errors.sociedad_ref &&
												Boolean(errors.sociedad_ref)
											}
											helperText={
												touched.sociedad_ref && errors.sociedad_ref
													? errors.sociedad_ref
													: ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* RESPONSABLE */}
								<Autocomplete
									name="responsable_ref"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico
												? true
												: false
									}
									value={Array.from(responsables).find(
										(r) => r._id === values.responsable_ref
									)}
									loading={!responsables}
									options={responsables}
									onChange={(event, value) => {
										setFieldValue("responsable_ref", value ? value._id : "");
										setFieldValue(
											"codigo.responsable",
											value ? value.correlativo : ""
										);
									}}
									getOptionLabel={(responsable) =>
										responsable.persona.nombre_completo
									}
									renderInput={(params) => (
										<TextField
											label="Responsable"
											variant="outlined"
											required
											error={
												touched.responsable_ref &&
												errors.responsable_ref &&
												Boolean(errors.responsable_ref)
											}
											helperText={
												touched.responsable_ref && errors.responsable_ref
													? errors.responsable_ref
													: ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* RESPONSABLE */}
								<Autocomplete
									name="responsable_admin_ref"
									value={Array.from(responsables).find(
										(r) => r._id === values.responsable_admin_ref
									)}
									loading={!responsables}
									options={responsables}
									onChange={(event, value) => {
										setFieldValue(
											"responsable_admin_ref",
											value ? value._id : ""
										);
									}}
									getOptionLabel={(responsable) =>
										responsable.persona.nombre_completo
									}
									renderInput={(params) => (
										<TextField
											label="Responsable Talana"
											variant="outlined"
											required
											error={
												touched.responsable_admin_ref &&
												errors.responsable_admin_ref &&
												Boolean(errors.responsable_admin_ref)
											}
											helperText={
												touched.responsable_admin_ref &&
													errors.responsable_admin_ref
													? errors.responsable_admin_ref
													: ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* FECHA DE INICIO */}
								<DatePicker
									name="fecha_inicio"
									label="Fecha de Inicio"
									value={values.fecha_inicio}
									format="DD/MM/YYYY"
									inputVariant="outlined"
									fullWidth
									onChange={(date) => setFieldValue("fecha_inicio", date)}
									error={
										touched.fecha_inicio &&
										errors.fecha_inicio &&
										Boolean(errors.fecha_inicio)
									}
									helperText={
										touched.fecha_inicio && errors.fecha_inicio
											? errors.fecha_inicio
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* FECHA ESTIMADA TERMINO */}
								<DatePicker
									name="fecha_termino_estimada"
									label="Fecha Estimada de Término"
									value={values.fecha_termino_estimada}
									format="DD/MM/YYYY"
									maxDate={Moment("31/12/2099", "DD/MM/YYYY")}
									inputVariant="outlined"
									fullWidth
									required
									onChange={(date) =>
										setFieldValue("fecha_termino_estimada", date)
									}
									error={
										touched.fecha_termino_estimada &&
										errors.fecha_termino_estimada &&
										Boolean(errors.fecha_termino_estimada)
									}
									helperText={
										touched.fecha_termino_estimada &&
											errors.fecha_termino_estimada
											? errors.fecha_termino_estimada
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* FECHA DE INICIO */}
								<DatePicker
									name="fecha_adjudicacion"
									value={values.fecha_adjudicacion}
									label="Fecha de Adjudicación"
									format="DD/MM/YYYY"
									inputVariant="outlined"
									fullWidth
									onChange={(date) => setFieldValue("fecha_adjudicacion", date)}
									error={
										touched.fecha_adjudicacion &&
										errors.fecha_adjudicacion &&
										Boolean(errors.fecha_adjudicacion)
									}
									helperText={
										touched.fecha_adjudicacion && errors.fecha_adjudicacion
											? errors.fecha_adjudicacion
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* FECHA TERMINO REAL */}
								<DatePicker
									name="fecha_termino_real"
									label="Fecha Término Real"
									value={values.fecha_termino_real}
									format="DD/MM/YYYY"
									inputVariant="outlined"
									fullWidth
									onChange={(date) => setFieldValue("fecha_termino_real", date)}
									error={
										touched.fecha_termino_real &&
										errors.fecha_termino_real &&
										Boolean(errors.fecha_termino_real)
									}
									helperText={
										touched.fecha_termino_real && errors.fecha_termino_real
											? errors.fecha_termino_real
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* ESTADO */}
								<Autocomplete
									name="estado"
									disabled={
										permisoActualizar
											? false
											: permisoActualizarBasico
												? true
												: false
									}
									value={values.estado}
									options={["Activo", "Terminado"]}
									onChange={(event, value) =>
										setFieldValue("estado", value ? value : "")
									}
									renderInput={(params) => (
										<TextField
											label="Estado"
											variant="outlined"
											required
											error={
												touched.estado &&
												errors.estado &&
												Boolean(errors.estado)
											}
											helperText={
												touched.estado && errors.estado ? errors.estado : ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={6} xl={3}>
								{/* NOMBRE CONTRATO */}
								{/* RESPONSABLE */}
								<Autocomplete
									name="region"
									loading={typeof regiones === "undefined"}
									options={regiones}
									value={typeof regiones !== "undefined" ? Array.from(regiones).find((g) => {

										console.log();
										if (values.region) {
											console.log(g.region);
											return g.region === values.region ? values.region : g.region.replace(/ *Región | from.*/ig, '') === values.region ? values.region : ""

										} else {
											return ""
										}

									}) : ""}
									onChange={(event, value) => {
										setFieldValue("region", value ? value.nombre : "");
									}}
									getOptionLabel={(region) => {
										return region.region;
									}}
									renderInput={(params) => (
										<TextField
											label="Región"
											variant="outlined"
											required
											error={
												touched.region &&
												errors.region &&
												Boolean(errors.region)
											}
											helperText={
												touched.region && errors.region ? errors.region : ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* NOMBRE CONTRATO */}
								<Autocomplete
									name="comuna"
									loading={!comunas}
									options={comunas}
									onChange={(event, value) => {
										setFieldValue("comuna", value ? value.nombre : "");
									}}
									getOptionLabel={(responsable) => {
										return responsable.nombre;
									}}
									value={Array.from(comunas).find((g) => {
										return g.nombre === values.comuna ? values.comuna : "";
									})}
									renderInput={(params) => (
										<TextField
											label="Comuna"
											variant="outlined"
											required
											error={
												touched.comuna &&
												errors.comuna &&
												Boolean(errors.comuna)
											}
											helperText={
												touched.comuna && errors.comuna ? errors.comuna : ""
											}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* NOMBRE CONTRATO */}
								<TextField
									name="direccion"
									label="Dirección"
									variant="outlined"
									required
									fullWidth
									value={values.direccion}
									onChange={handleChange}
									error={
										touched.direccion &&
										errors.direccion &&
										Boolean(errors.direccion)
									}
									helperText={
										touched.direccion && errors.direccion
											? errors.direccion
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* NOMBRE CONTRATO */}
								<TextField
									name="monto_del_contrato"
									label="Monto del Contrato. Por ejemplo, UF 15.000 "
									value={values.monto_del_contrato}
									variant="outlined"
									required
									fullWidth
									onChange={handleChange}
									error={
										touched.monto_del_contrato &&
										errors.monto_del_contrato &&
										Boolean(errors.monto_del_contrato)
									}
									helperText={
										touched.monto_del_contrato && errors.monto_del_contrato
											? errors.monto_del_contrato
											: ""
									}
								/>
							</Grid>

							<Grid item xs={6} xl={3}>
								{/* FECHA ACTUALIZACION */}
								<DatePicker
									label="Fecha Actualización"
									value={values.fecha_actualizacion}
									format="DD/MM/YYYY"
									inputVariant="outlined"
									readOnly
									disabled
									fullWidth
								/>
							</Grid>
							<Grid item xs={6} xl={3}>
								{/* FECHA CREACION */}
								<DatePicker
									label="Fecha Ingreso"
									value={values.fecha_creacion}
									format="DD/MM/YYYY"
									inputVariant="outlined"
									readOnly
									disabled
									fullWidth
								/>
							</Grid>
						</Grid>
					</SeccionView>
					<Box display="flex" flexDirection="row-reverse">
						<Button
							onClick={handle_salir}
							variant="outlined"
							color="primary"
							className={classes.button}
						>
							Salir
						</Button>
						<Button
							onClick={submitForm}
							variant="contained"
							color="primary"
							className={classes.button}
						>
							Ingresar Contrato
						</Button>
					</Box>
				</form>
			</Paper>
		</Fragment>
	);
}

/**
 * Método encargado de formatear el código del contrato.
 * @param {*} datos Datos del contrato.
 * @param {*} correlativo_default Correlativo predeterminado.
 * @param {*} is_centro_costo TRUE: Es Centro de Costos | FALSE: No es Centro de Costos.
 * @returns Código formateado.
 */
function CodigoFormateado(datos, correlativo_default, is_centro_costo) {
	//Datos de la gerencia.
	let gerencia = datos.codigo.gerencia !== "" ? datos.codigo.gerencia : "[Gerencia]";
	//Datos del correlativo.
	let correlativo = datos.codigo.correlativo && datos.codigo.correlativo.length > 0 ? datos.codigo.correlativo : correlativo_default;
	//Datos del responsable, si aplica.
	let responsable = datos.codigo.responsable !== "" ? datos.codigo.responsable : "[Responsable]";

	if (is_centro_costo) {
		//Si es de tipo Centro de Costos.
		return `${gerencia}-${correlativo}`;
	} else {
		//Si no es tipo Centro de Costos.
		return `${gerencia}-${responsable}-${correlativo}`;
	}
}

const useStyle = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(1),
	},
}));

export default Page;