

import Axios from "axios";

const BASE_URL = process.env.REACT_APP_DB_URL;
/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 */
export async function ObtenerPerfil(perfilID) {
  try {
    let url = `${BASE_URL}/perfiles/${perfilID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerPerfiles() {
  try {
    let url = `${BASE_URL}/perfiles`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarPerfil(perfil) {
  try {
    let url = `${BASE_URL}/perfiles`;
    let response = await Axios.post(url, perfil);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarPerfil(perfil) {
  try {
    let url = `${BASE_URL}/perfiles/${perfil._id}`;
    let response = await Axios.put(url, perfil);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarPerfil(perfil) {
  try {
    let url = `${BASE_URL}/perfiles/${perfil._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
