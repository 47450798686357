import Moment from "moment";

/**
 * FORMATO DE FECHA VACIO.
 */
export const FECHA_FORMATO_VACIO = "--/--/--";

/**
 * Método encargado de formatear el nombre y apellidos de un postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre completo formateado.
 */
export function FormatearNombre(persona) {
	if (!persona) {
		return "";
	}
	let nombre = `${String(persona.nombre).charAt(0).toUpperCase()}${String(persona.nombre).split(" ")[0].slice(1)}`;
	let apaterno = `${String(persona.apellido_paterno).charAt(0).toUpperCase()}${String(persona.apellido_paterno).slice(1)}`;
	let amaterno = `${String(persona.apellido_materno).charAt(0).toUpperCase()}${String(persona.apellido_materno).slice(1)}`;
	return `${nombre} ${apaterno} ${amaterno}`;
}

/**
 * Método encargado de formatear una fecha.
 * @param {*} fecha Fecha que será formateada.
 * @param {*} formato Formato opcional. Valor predeterminado "DD/MM/YYYY".
 * @returns Fecha formateada.
 */
export function FormatearFecha(fecha, formato = "DD/MM/YYYY") {
	if (!fecha) {
		return FECHA_FORMATO_VACIO;
	}
	let fechaFormateada = Moment(fecha).format(formato);
	return fechaFormateada;
}

/**
 * Método encargado de obtener el rango desde hasta de un dato.
 * @param {*} data Dato con fecha de inicio y término.
 * @returns Rango de fecha desde.
 */
export function RangoFechas(data) {
	let fechaInicio = Moment(data.fecha_inicio);
	let fechaTermino = Moment(data.fecha_termino);
	if (fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")} Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (!fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (fechaInicio.isValid() && !fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")}`;
	} else {
		return `Desde ${FECHA_FORMATO_VACIO} Hasta ${FECHA_FORMATO_VACIO}`;
	}
}

/**
 * Método encargado de aplicar TitleCase a un texto.
 * @param {*} text Texto que será cambiado.
 * @returns Texto con formato TitleCase.
 */
export function ToTitleCase(text) {
	if (!text) {
		return null;
	}
	let texto = String(text).trim().replace(/  +/g, ' ');
	let result = texto.split(" ").map(m => `${m[0].toUpperCase()}${m.slice(1)}`).join(" ");
	return result;
}

/**
 * Método encargado de calcular la cantidad de años desde una fecha.
 * @param {*} fecha Fecha desde cuando realizar el cálculo.
 * @param {*} decimales Cantidad de decimales opcional. Valor predeterminado es 0.
 * @returns Cantidad de años.
 */
export function AñosDesde(fecha, decimales = 0) {
	let months = Moment().diff(Moment(fecha), "months") / 12;
	let years = Number(months).toFixed(decimales);
	return years;
}

/**
 * Método encargado de formatear el tamaño de un archivo.
 * @param {*} bytes Tamaño del archivo en bytes.
 * @returns Tamaño formateado.
 */
export function BytesToSize(bytes) {
	const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"];
	const unit = Math.floor(Math.log(bytes) / Math.log(1024));
	return new Intl.NumberFormat("es", { style: "unit", unit: units[unit], maximumFractionDigits: 1 }).format(bytes / 1024 ** unit);
}

/**
 * Método encargado de formatear el nombre de un CV.
 * @param {*} name Nombre del CV.
 * @returns Nombre del CV formateado.
 */
export function CurriculumNameFormat(name) {
	let nombre = String(name).split(/_/)
		.slice(0, 2)
		.join("")
		.split(/(?=[A-Z])/)
		.join(" ");
	return nombre;
}
