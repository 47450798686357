import React from "react";
import Page from "./page";
import { agregarSociedad } from "../requestSociedad";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const history = useHistory();
	const notistack = useSnackbar();

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		codigo: "",
		rut: "",
		nombre: "",
		giro: "",
		contacto: {
			telefono: "",
			telefono_alternativo: "",
			fax: "",
		},
		ubicacion: {
			direccion: "",
			comuna: "",
			region: "",
		},
	}

	/**
	 * Esquema de validación para la sociedad.
	 */
	const validation = object().shape({
		codigo: string()
			.min(3, `El código debe tener al menos ${3} caracteres.`)
			.max(10, `El código debe tener a lo más ${10} caracteres.`)
			.required("El código es requerido."),
		rut: string()
			.matches(/^(\d{1,2})\.?(\d{3})\.?(\d{3})-[[\dkK]$/, "El formato del RUT no es válido.")
			.required("El RUT es requerido."),
		nombre: string()
			.min(3, `El nombre debe tener al menos ${3} caracteres.`)
			.max(50, `El nombre debe tener a lo más ${50} caracteres.`)
			.required(),
		giro: string()
			.min(3, `El giro debe tener al menos ${3} caracteres.`)
			.max(50, `El giro debe tener a lo más ${50} caracteres.`)
			.required("El giro es requerido."),
		contacto: object()
			.shape({
				telefono: string()
					.min(6, `El teléfono debe tener al menos ${6} números.`)
					.max(12, `El teléfono debe tener a lo más ${12} números.`)
					.optional(),
				telefono_alternativo: string()
					.min(6, `El teléfono alternativo debe tener al menos ${6} números.`)
					.max(12, `El teléfono alternativo debe tener a lo más ${12} números.`)
					.optional(),
				fax: string()
					.min(6, `El fax debe tener al menos ${6} números.`)
					.max(20, `El fax debe tener a lo más ${20} números.`)
					.optional(),
			})
			.optional(),
		ubicacion: object()
			.shape({
				direccion: string()
					.min(3, "La dirección debe tener al menos 3 caracteres.")
					.max(60, "La dirección debe tener a lo más 60 caracteres.")
					.optional(),
				comuna: string()
					.min(3, "La comuna debe tener al menos 3 caracteres.")
					.max(50, "La comuna debe tener a lo más 50 caracteres.")
					.optional(),
				region: string()
					.min(3, "La región debe tener al menos 3 caracteres.")
					.max(50, "La región debe tener a lo más 50 caracteres.")
					.optional(),
			})
			.optional(),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		onSubmit: (values, helper) => handleAgregar(values),
	});

	/**
	 * Handler para agregar sociedad.
	 */
	const handleAgregar = (values) => {
		agregarSociedad(values)
			.then(response => {
				console.log("Sociedad agregada exitosamente", response);
				let mensajeExito = notistack.enqueueSnackbar("Sociedad agregada exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar agregar la sociedad.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar agregar la sociedad.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/sociedades");
			});
	}

	/**
	 * Handler para limpiar el formulario.
	 */
	const handleLimpiar = () => {
		formik.resetForm();
	}

	return (
		<Page
			handle_limpiar={handleLimpiar}
			formik={formik}
		/>
	);
}

export default Index;