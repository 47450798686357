import React, { useEffect } from "react";
import Page from "./page";
import {
  agregarResponsable,

} from "../requestResponsable";
import { useHistory } from "react-router-dom";
import { number, object } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";


function Index(props) {
//   const [UsuariosResponsables, SetUsuariosResponsables] = useState();

  const history = useHistory();
  const notistack = useSnackbar();
  

  

  useEffect(() => {
	// console.log(UsuariosResponsables);
    // obtenerUsuariosResponsables()
    //   .then((usuariosResponsables) =>
    //     SetUsuariosResponsables(usuariosResponsables)
    //   )
    //   .catch((error) => {
    //     console.error(error);
    //     SetUsuariosResponsables([]);
    //   });
  }, []);

  /**
   * Valores iniciales del formulario.
   */
  const initials = {
    persona: null,
    correlativo: "",
  };

  /**
   * Esquema de validación para el responsable.
   */
  const validation = object().shape({
    persona: object().nullable().required("El usuario es requerido."),
    correlativo: number()
      .integer("El correlativo debe ser un número entero.")
      .positive("El correlativo debe ser un número positivo.")
      .min(-1, "El correlativo debe ser 0 o un número mayor.")
      .max(99, "El correlativo debe ser a lo más 99 o un número menor.")
      .required("El correlativo es requerido."),
  });

  /**
   * Configuración de Formik Hook.
   */
  const formik = useFormik({
    initialValues: initials,
    validationSchema: validation,
    onSubmit: (values, helper) => handleAgregar(values),
  });

  /**
   * Handler para agregar responsable.
   */
  const handleAgregar = (values) => {
    values.gerencia = values.persona.gerencia_ref.sigla;
    agregarResponsable(values)
      .then((response) => {
        console.log("responsable agregado exitosamente", response);
        let mensajeExito = notistack.enqueueSnackbar(
          "Responsable agregado exitosamente.",
          {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .catch((error) => {
        console.error("Error al intentar agregar el responsable.", error);
        let mensajeError = notistack.enqueueSnackbar(
          "Error al intentar agregar el responsable.",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            action: (
              <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}>
                <Close />
              </IconButton>
            ),
          }
        );
      })
      .finally(() => {
        history.push("/responsables");
      });
  };

  /**
   * Handler para limpiar el formulario.
   */
  const handleLimpiar = () => {
    formik.resetForm();
  };

  return (
    <Page
    //   usuarios_responsables={UsuariosResponsables}
      handle_limpiar={handleLimpiar}
      formik={formik}
      
    />
  );
}

export default Index;
