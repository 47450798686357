import React, { useEffect, useCallback } from "react";
import { realTime } from "../../../services/firebase";
import {
  obtenerSolicitudContrato,
} from "../../../services/database/solicitud";
import {
  tienePermisosParaVerTodosContrato,
  tienePermisoParaVerContratosEncargado,
  tienePermisosParaVerContratosDeSuGerencia,
  getUserAPI,
  tienePermisosParaVerCentroCosto,
  tienePermisosParaVerSolicitudes,
} from "../../../services/database/usuario";

export const useContratosVigentesRealTime = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [contratos, setContratos] = React.useState([]);

  useEffect(() => {
    try {
      realTime
        .ref("contrato")
        .orderByChild("centro_costo")
        .equalTo(false)
        .once("value", async (snapshot) => {
          const contratosValue = snapshot.val();
          if (contratosValue) {
            let contratosList = Object.keys(contratosValue).map((key) => {
              return {
                ...contratosValue[key],
                id: key,
              };
            });
            let contratoFiltrado = contratosList.filter((value) => {
              if (value.estado === "activo") {
                return value;
              }
            });

            setContratos(contratoFiltrado);
            setLoading(false);
          } else {
            this.setState({ mandantes: null, loading: false });
          }
        });
    } catch (error) {
      setError(error);
    }
  }, []);

  return {
    error,
    loading,
    contratos,
  };
};

export const useSolicitudContratosVigentes = (
  usuario,
  verTodoCyd = false,
  verCentroCosto = false
) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [contratos, setContratos] = React.useState([]);

  function fetchData() {
    console.log("fetchData");
    if (tienePermisosParaVerSolicitudes(usuario)) {
      obtenerSolicitudContrato(tienePermisosParaVerCentroCosto(usuario)).then(
        async (result) => {
          if (result) {
            let filtrado = result.map((item) => {
              let nombre_display =
                item.responsable_ref?.nombre +
                " " +
                item?.responsable_ref?.apellido_paterno +
                " " +
                item?.responsable?.apellido_materno;
              if (item.responsable_ref) {
                item.responsable_ref["nombre_display"] = nombre_display;
              }
              return item;
            });
            if (tienePermisosParaVerTodosContrato(usuario)) {
              console.log(result);
              let sortContratos = filtrado.sort((a, b) => {
                return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
              });
              setContratos(sortContratos);
            } else if (tienePermisosParaVerContratosDeSuGerencia(usuario)) {
              if (!verTodoCyd) {
                let sortContratos = filtrado.sort((a, b) => {
                  return a.is_eliminado === b.is_eliminado
                    ? 0
                    : b.is_eliminado
                    ? -1
                    : 1;
                });
                setContratos(sortContratos);
              } else {
                let filtroUsuario = [];
                if (usuario?.email) {
                  let result = await getUserAPI(usuario.uid);
                  console.log(result.contratos_ref);
                  let gerenciaPersona =
                    result.contratos_ref[0].codigo.split("-")[0];
                  filtroUsuario = filtrado.filter((contrato) => {
                    return gerenciaPersona === contrato.gerencia_ref.sigla;
                  });
                  let sortContratos = filtroUsuario.sort((a, b) => {
                    return a.is_eliminado === b.is_eliminado
                      ? 0
                      : b.is_eliminado
                      ? -1
                      : 1;
                  });
                  setContratos(sortContratos);
                }
              }
            } else if (tienePermisoParaVerContratosEncargado(usuario)) {
              console.log(filtrado);
              let filtroUsuario = [];
              if (usuario.uid) {
                filtroUsuario = filtrado.filter((contrato) => {
                  return (
                    contrato.responsable_ref.persona.usuario_id === usuario.uid
                  );
                });
              }
              let sortContratos = filtroUsuario.sort((a, b) => {
                return a.is_eliminado === b.is_eliminado
                  ? 0
                  : b.is_eliminado
                  ? -1
                  : 1;
              });
              setContratos(sortContratos);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setContratos([]);
      setLoading(false);
    }
  }
  const refetch = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return [
    {
      error,
      loading,
      contratos,
    },
    refetch,
  ];
};

export const useContrato = (id) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [contrato, setContrato] = React.useState(null);

  useEffect(() => {
    obtenerSolicitudContrato(id)
      .then((result) => {
        setContrato(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
      });
  }, [id]);

  return {
    error,
    loading,
    contrato,
  };
};

