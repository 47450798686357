import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerResponsables, agregarGerencia } from "../requestGerencia";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Responsables, SetResponsables] = useState();

	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerResponsables()
			.then(responsables => SetResponsables(responsables))
			.catch(error => {
				console.error(error);
				SetResponsables([]);
			});
	}, []);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		nombre: "",
		sigla: "",
		responsable_ref: "",
	}

	/**
	 * Esquema de validación para la gerencia.
	 */
	const validation = object().shape({
		nombre: string()
			.min(3, `El nombre debe tener al menos 3 caracteres.`)
			.max(70, `El nombre debe tener a lo más 70 caracteres.`)
			.required("El nombre es requerido."),
		sigla: string()
			.min(3, `La sigla debe tener al menos 3 caracteres.`)
			.max(15, `La sigla debe tener a lo más 15 caracteres.`)
			.required("La sigla es requerida."),
		responsable_ref: string()
			.required("El responsable es requerido."),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		onSubmit: (values, helper) => handleAgregar(values),
	});

	/**
	 * Handler para agregar gerencia.
	 */
	const handleAgregar = (values) => {
		agregarGerencia(values)
			.then(response => {
				console.log("Gerencia agregada exitosamente", response);
				let mensajeExito = notistack.enqueueSnackbar("Gerencia agregada exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar agregar la gerencia.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar agregar la gerencia.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/gerencias");
			});
	}

	/**
	 * Handler para limpiar el formulario.
	 */
	const handleLimpiar = () => {
		formik.resetForm();
	}

	return (
		<Page
			responsables={Responsables}
			handle_limpiar={handleLimpiar}
			formik={formik}
		/>
	);
}

export default Index;