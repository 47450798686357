import Axios from "axios";
import comunasJson from "./comunas.json";

const BASE_URL = process.env.REACT_APP_DB_URL;
const DEBUG = false

/**
 * Método encargado de obtener todos los contratos.
 */
export async function obtenerContratos() {
  try {
    let url = `${BASE_URL}/contratos?is_centro_costo=${false}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function obtenerSolicitudContratosByID(contratoID) {
  try {

    let url = `${BASE_URL}/solicitud-nuevo-contrato/${contratoID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}



/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function obtenerContrato(contratoID) {
  try {
    let url = `${BASE_URL}/contratos/${contratoID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un contrato.
 * @param {*} contrato Datos del contrato.
 */
export async function agregarSolicitudContrato(contrato) {
  try {
    let url = `${BASE_URL}/solicitud-nuevo-contrato?sendMail=true&debug=${DEBUG}`;
    let response = await Axios.post(url, contrato);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un contrato.
 * @param {*} contrato Datos actualizados del contrato.
 */
export async function actualizarSolicitudContrato(contrato) {
  try {
    let url = `${BASE_URL}/solicitud-nuevo-contrato/${contrato._id}`;
    let response = await Axios.put(url, contrato);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 */
export async function eliminarSolicitudDeContrato(contratoID) {
  try {
    let contrato = { is_eliminado: true };
    let url = `${BASE_URL}/solicitud-nuevo-contrato/${contratoID._id}`;
    contratoID.is_eliminado = true;
    let response = await Axios.put(url, contrato);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

////////////////////////////////////////////////////////////////////////

/**
 * Método encargado de obtener las gerencias.
 */
export async function obtenerGerencias() {
  try {
    let url = `${BASE_URL}/gerencias?is_eliminado=false`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener las sociedades.
 */
export async function obtenerSociedades() {
  try {
    let url = `${BASE_URL}/sociedades`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener las personas que pueden ser responsables de contratos/proyectos.
 */
export async function obtenerResponsables(query = null) {
  try {
    let url = `${BASE_URL}/responsables`;
    if (query) {
      url += `?${query}`;
    }
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener los mandantes.
 */
export async function obtenerMandantes() {
  try {
    let url = `${BASE_URL}/mandantes`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function obtenerComunas(region) {
  try {

    let comunas = [];
    comunasJson.map(item => {
      let comuna = item.comunas.map(com => {
        return {
          nombre: com,
        }

      })
      comunas = [...comuna, ...comunas]
    });
    
    return comunas;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function obtenerRegiones() {
  try {

    return comunasJson;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
