import {
  AppBar, Button, Dialog, Divider, Grid, List,
  ListItem,
  ListItemText, Toolbar
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { mixed, object } from "yup";
import { uploadFileToCloudStorage } from "../../../services/fileUpload";
import { BytesToSize } from "../../../services/formatUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#dcdde1",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function FileDropUpload(props) {
  const { open, uploadFileHandle, handleClose, contrato } = props;
  const [openUploadFile, setOpenUploadFile] = React.useState(open);

  const classes = useStyles();
  const theme = useTheme();
  const notistack = useSnackbar();

  const [nombreFile, setNombreFile] = useState(null);
  const [LoadingStatus, SetLoadingStatus] = useState(0);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    accept: ["application/pdf"],
    multiple: true,
  });
  const formik = useFormik({
    initialValues: {
      archivo: null,
    },
    validationSchema: object().shape({
      archivo: mixed().nullable().optional(),
    }),
    onSubmit: async function (values, helper) {
  
      notistack.enqueueSnackbar("Subiendo los documentos...", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
      let countFinished = 0;

      //Archivos que tienen tamaño.
      let files = Array.from(acceptedFiles).filter((file) => file.size > 0);

      let promises = files.map((file, index) =>
        uploadFileToCloudStorage(file, contrato._id, uuidv4())
      );

      //Por cada promsesa completada de subir archivo, se aumenta la cantidad.
      promises.forEach((promise) =>
        promise.then(() => {
          countFinished += 99 / promises.length;
          SetLoadingStatus(countFinished);
        })
      );

      //Archivos subidos a la nube.
      let uploadedFiles = await Promise.all(promises);
      

      notistack.enqueueSnackbar("Documentos subidos exitosamente.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
        action: (key) => (
          <IconButton onClick={() => notistack.closeSnackbar()}>
            <Close />
          </IconButton>
        ),
      });
      handleClose();
    },
  });

  useEffect(() => {
  
    formik.setFieldValue("nombre", acceptedFiles[0]);

    setNombreFile(acceptedFiles);
  }, [acceptedFiles]);


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Subir Archivo
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              formik.submitForm();
            }}
          >
            Subir
          </Button>
        </Toolbar>
      </AppBar>
      <List>
        {acceptedFiles.map((file, index) => {
          return (
            <ListItem>
              <ListItemText
                primary={`${file.path}`}
                secondary={`${BytesToSize(file.size)}`}
              />
            </ListItem>
          );
        })}

        <Divider />
      </List>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card
            style={{
              width: 600,
              height: 300,
              borderStyle: "dashed",
              borderRadius: 25,
            }}
          >
            <CardContent className={classes.content} {...getRootProps()}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 250 }}
                >
                  <input {...getInputProps()} />
                  <Grid item>
                    <CloudUploadIcon size={2} />
                  </Grid>
                  <Typography component="body1" variant="body1">
                    {"Subir Archivo"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Dialog>
  );
}
