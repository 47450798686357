import React from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";

export default function ViewAprobador(props) {
  const { aprobador } = props;
  return (
    <Grid container>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Avatar
              alt="Remy Sharp"
              src={aprobador ? aprobador.photoUrl : ""}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography>{aprobador ? aprobador.nombre : ""}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {aprobador ? aprobador.cargo : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
